import logo_hw_src from 'images/logos/hw-logo.svg?width=200&height=98&format=webp';
export const logo_hw = {
	src: logo_hw_src, id: 'logo_hw', width: '200', height: '98'
};
import logo_hw_outlined_src from 'images/logos/hw-logo-outline.svg?width=200&height=98&format=webp';
export const logo_hw_outlined = {
	src: logo_hw_outlined_src, id: 'logo_hw_outlined', width: '200', height: '98'
};
import logo_hw_mono_src from 'images/logos/hw-logo-mono.svg?width=200&height=98&format=webp';
export const logo_hw_mono = {
	src: logo_hw_mono_src, id: 'logo_hw_mono', width: '200', height: '98'
};
import logo_hw_retail_src from 'images/logos/hw-logo-retail.svg?width=200&height=98&format=webp';
export const logo_hw_retail = {
	src: logo_hw_retail_src, id: 'logo_hw_retail', width: '200', height: '98'
};
import logo_hw_retail_outlined_src from 'images/logos/hw-logo-retail-outline.svg?width=200&height=98&format=webp';
export const logo_hw_retail_outlined = {
	src: logo_hw_retail_outlined_src, id: 'logo_hw_retail_outlined', width: '200', height: '98'
};
import logo_hw_retail_mono_src from 'images/logos/hw-logo-retail-mono.svg?width=200&height=98&format=webp';
export const logo_hw_retail_mono = {
	src: logo_hw_retail_mono_src, id: 'logo_hw_retail_mono', width: '200', height: '98'
};
import logo_hw_cz_src from 'images/logos/hw-cz-logo.svg?width=228&height=98&format=webp';
export const logo_hw_cz = {
	src: logo_hw_cz_src, id: 'logo_hw_cz', width: '228', height: '98'
};
import logo_hw_cz_mono_src from 'images/logos/hw-cz-logo-mono.svg?width=228&height=98&format=webp';
export const logo_hw_cz_mono = {
	src: logo_hw_cz_mono_src, id: 'logo_hw_cz_mono', width: '228', height: '98'
};
import logo_hw_cz_outlined_src from 'images/logos/hw-cz-logo-outlined.svg?width=228&height=98&format=webp';
export const logo_hw_cz_outlined = {
	src: logo_hw_cz_outlined_src, id: 'logo_hw_cz_outlined', width: '228', height: '98'
};
import logo_HvH_Mitglied_weidauer_src from 'images/logos/logo_HvH-Mitglied-weidauer.png?width=660&height=300&format=webp';
export const logo_HvH_Mitglied_weidauer = {
	src: logo_HvH_Mitglied_weidauer_src, id: 'logo_HvH_Mitglied_weidauer', width: '660', height: '300'
};
import logo_HvH_Mitglied_weidauer_mono_src from 'images/logos/logo_HvH-Mitglied-weidauer-mono.png?width=660&height=300&format=webp';
export const logo_HvH_Mitglied_weidauer_mono = {
	src: logo_HvH_Mitglied_weidauer_mono_src, id: 'logo_HvH_Mitglied_weidauer_mono', width: '660', height: '300'
};
import logo_fhh_src from 'images/logos/fhh-logo.svg?width=110&height=70&format=webp';
export const logo_fhh = {
	src: logo_fhh_src, id: 'logo_fhh', width: '110', height: '70'
};
import logo_fhh_mono_src from 'images/logos/fhh-logo-mono.svg?width=110&height=70&format=webp';
export const logo_fhh_mono = {
	src: logo_fhh_mono_src, id: 'logo_fhh_mono', width: '110', height: '70'
};
import logo_holzfabrik_src from 'images/logos/hf-logo.svg?width=110&height=90&format=webp';
export const logo_holzfabrik = {
	src: logo_holzfabrik_src, id: 'logo_holzfabrik', width: '110', height: '90'
};
import logo_holzfabrik_mono_src from 'images/logos/hf-logo-mono.svg?width=110&height=90&format=webp';
export const logo_holzfabrik_mono = {
	src: logo_holzfabrik_mono_src, id: 'logo_holzfabrik_mono', width: '110', height: '90'
};
import logo_tib_src from 'images/logos/tib-logo.svg?width=150&height=90&format=webp';
export const logo_tib = {
	src: logo_tib_src, id: 'logo_tib', width: '150', height: '90'
};
import logo_tib_mono_src from 'images/logos/tib-logo-mono.svg?width=150&height=90&format=webp';
export const logo_tib_mono = {
	src: logo_tib_mono_src, id: 'logo_tib_mono', width: '150', height: '90'
};
import icon_tib_src from 'images/logos/tib-icon.svg?width=150&height=126&format=webp';
export const icon_tib = {
	src: icon_tib_src, id: 'icon_tib', width: '150', height: '126'
};
import logo_sale_retail_src from 'images/logos/logo-sale-retail.svg?width=200&height=200&format=webp';
export const logo_sale_retail = {
	src: logo_sale_retail_src, id: 'logo_sale_retail', width: '200', height: '200'
};
import logo_sale_wholesale_src from 'images/logos/logo-sale-wholesale.svg?width=200&height=200&format=webp';
export const logo_sale_wholesale = {
	src: logo_sale_wholesale_src, id: 'logo_sale_wholesale', width: '200', height: '200'
};
import logo_sale_cz_src from 'images/logos/logo-sale-cz.svg?width=200&height=200&format=webp';
export const logo_sale_cz = {
	src: logo_sale_cz_src, id: 'logo_sale_cz', width: '200', height: '200'
};
import logo_discontinued_src from 'images/logos/logo-discontinued.svg?width=200&height=200&format=webp';
export const logo_discontinued = {
	src: logo_discontinued_src, id: 'logo_discontinued', width: '200', height: '200'
};
import logo_pickup_src from 'images/logos/logo-abholung.svg?width=200&height=200&format=webp';
export const logo_pickup = {
	src: logo_pickup_src, id: 'logo_pickup', width: '200', height: '200'
};
import logo_stock_src from 'images/logos/hw-lagerware.svg?width=200&height=200&format=webp';
export const logo_stock = {
	src: logo_stock_src, id: 'logo_stock', width: '200', height: '200'
};
import logo_hq_src from 'images/logos/hq-logo.svg?width=200&height=200&format=webp';
export const logo_hq = {
	src: logo_hq_src, id: 'logo_hq', width: '200', height: '200'
};
import logo_weber_circle_src from 'images/logos/weber-logo-circle.svg?width=200&height=200&format=webp';
export const logo_weber_circle = {
	src: logo_weber_circle_src, id: 'logo_weber_circle', width: '200', height: '200'
};
import logo_holzbau_fassade_src from 'images/logos/logo-holzbau-fassade.png?width=638&height=292&format=webp';
export const logo_holzbau_fassade = {
	src: logo_holzbau_fassade_src, id: 'logo_holzbau_fassade', width: '638', height: '292'
};
import logo_objekt_funktionstueren_src from 'images/logos/logo-objekt-funktionstueren.png?width=638&height=292&format=webp';
export const logo_objekt_funktionstueren = {
	src: logo_objekt_funktionstueren_src, id: 'logo_objekt_funktionstueren', width: '638', height: '292'
};
import logo_wertholz_leimholzplatte_src from 'images/logos/logo-wertholz-leimholzplatte.png?width=638&height=292&format=webp';
export const logo_wertholz_leimholzplatte = {
	src: logo_wertholz_leimholzplatte_src, id: 'logo_wertholz_leimholzplatte', width: '638', height: '292'
};
import logo_anniversary_src from 'images/logos/100-jahre-hw.svg?width=200&height=173&format=webp';
export const logo_anniversary = {
	src: logo_anniversary_src, id: 'logo_anniversary', width: '200', height: '173'
};
import retail_image_src from 'images/pages/home/retail-image.jpg?width=480&height=252&format=webp';
export const retail_image = {
	src: retail_image_src, id: 'retail_image', width: '480', height: '252'
};
import wholesale_image_src from 'images/pages/home/wholesale-image.jpg?width=480&height=252&format=webp';
export const wholesale_image = {
	src: wholesale_image_src, id: 'wholesale_image', width: '480', height: '252'
};
import location_lauter_src from 'images/cards/location/holzgroshandel-lauter.jpg?width=360&height=240&format=webp';
export const location_lauter = {
	src: location_lauter_src, id: 'location_lauter', width: '360', height: '240'
};
import location_lauter2_src from 'images/cards/location/ausstellung-lauter.jpg?width=360&height=240&format=webp';
export const location_lauter2 = {
	src: location_lauter2_src, id: 'location_lauter2', width: '360', height: '240'
};
import location_lauter4_src from 'images/cards/location/holzfabrik-erzgebirge.jpg?width=360&height=240&format=webp';
export const location_lauter4 = {
	src: location_lauter4_src, id: 'location_lauter4', width: '360', height: '240'
};
import location_chemnitz_src from 'images/cards/location/holzhandel-chemnitz.jpg?width=360&height=240&format=webp';
export const location_chemnitz = {
	src: location_chemnitz_src, id: 'location_chemnitz', width: '360', height: '240'
};
import giftcard_src from 'images/misc/HS_Weidauer_geschenk-gutschein.jpg?width=330&height=330&format=webp';
export const giftcard = {
	src: giftcard_src, id: 'giftcard', width: '330', height: '330'
};
import default1_banner_xs_src from 'images/banners/banner1.jpg?width=600&height=200&format=webp';
import default1_banner_sm_src from 'images/banners/banner1.jpg?width=960&height=220&format=webp';
import default1_banner_md_src from 'images/banners/banner1.jpg?width=1280&height=220&format=webp';
import default1_banner_lg_src from 'images/banners/banner1.jpg?width=1440&height=250&format=webp';
export const default1_banner = {
	xs: {src: default1_banner_xs_src, id: 'default1_banner_xs', width: '600', height: '200'},
	sm: {src: default1_banner_sm_src, id: 'default1_banner_sm', width: '960', height: '220'},
	md: {src: default1_banner_md_src, id: 'default1_banner_md', width: '1280', height: '220'},
	lg: {src: default1_banner_lg_src, id: 'default1_banner_lg', width: '1440', height: '250'},
};
import default2_banner_xs_src from 'images/banners/banner2.jpg?width=600&height=200&format=webp';
import default2_banner_sm_src from 'images/banners/banner2.jpg?width=960&height=220&format=webp';
import default2_banner_md_src from 'images/banners/banner2.jpg?width=1280&height=220&format=webp';
import default2_banner_lg_src from 'images/banners/banner2.jpg?width=1440&height=250&format=webp';
export const default2_banner = {
	xs: {src: default2_banner_xs_src, id: 'default2_banner_xs', width: '600', height: '200'},
	sm: {src: default2_banner_sm_src, id: 'default2_banner_sm', width: '960', height: '220'},
	md: {src: default2_banner_md_src, id: 'default2_banner_md', width: '1280', height: '220'},
	lg: {src: default2_banner_lg_src, id: 'default2_banner_lg', width: '1440', height: '250'},
};
import default3_banner_xs_src from 'images/banners/banner3.jpg?width=600&height=200&position=bottom&format=webp';
import default3_banner_sm_src from 'images/banners/banner3.jpg?width=960&height=220&position=bottom&format=webp';
import default3_banner_md_src from 'images/banners/banner3.jpg?width=1280&height=220&position=bottom&format=webp';
import default3_banner_lg_src from 'images/banners/banner3.jpg?width=1440&height=250&position=bottom&format=webp';
export const default3_banner = {
	xs: {src: default3_banner_xs_src, id: 'default3_banner_xs', width: '600', height: '200'},
	sm: {src: default3_banner_sm_src, id: 'default3_banner_sm', width: '960', height: '220'},
	md: {src: default3_banner_md_src, id: 'default3_banner_md', width: '1280', height: '220'},
	lg: {src: default3_banner_lg_src, id: 'default3_banner_lg', width: '1440', height: '250'},
};
import wood1_banner_xs_src from 'images/banners/woodtexture1.jpg?width=600&height=200&format=webp';
import wood1_banner_sm_src from 'images/banners/woodtexture1.jpg?width=960&height=220&format=webp';
import wood1_banner_md_src from 'images/banners/woodtexture1.jpg?width=1280&height=220&format=webp';
import wood1_banner_lg_src from 'images/banners/woodtexture1.jpg?width=1440&height=250&format=webp';
export const wood1_banner = {
	xs: {src: wood1_banner_xs_src, id: 'wood1_banner_xs', width: '600', height: '200'},
	sm: {src: wood1_banner_sm_src, id: 'wood1_banner_sm', width: '960', height: '220'},
	md: {src: wood1_banner_md_src, id: 'wood1_banner_md', width: '1280', height: '220'},
	lg: {src: wood1_banner_lg_src, id: 'wood1_banner_lg', width: '1440', height: '250'},
};
import wood2_banner_xs_src from 'images/banners/woodtexture2.jpg?width=600&height=200&format=webp';
import wood2_banner_sm_src from 'images/banners/woodtexture2.jpg?width=960&height=220&format=webp';
import wood2_banner_md_src from 'images/banners/woodtexture2.jpg?width=1280&height=220&format=webp';
import wood2_banner_lg_src from 'images/banners/woodtexture2.jpg?width=1440&height=250&format=webp';
export const wood2_banner = {
	xs: {src: wood2_banner_xs_src, id: 'wood2_banner_xs', width: '600', height: '200'},
	sm: {src: wood2_banner_sm_src, id: 'wood2_banner_sm', width: '960', height: '220'},
	md: {src: wood2_banner_md_src, id: 'wood2_banner_md', width: '1280', height: '220'},
	lg: {src: wood2_banner_lg_src, id: 'wood2_banner_lg', width: '1440', height: '250'},
};
import wood3_banner_xs_src from 'images/banners/woodtexture3.jpg?width=600&height=200&format=webp';
import wood3_banner_sm_src from 'images/banners/woodtexture3.jpg?width=960&height=220&format=webp';
import wood3_banner_md_src from 'images/banners/woodtexture3.jpg?width=1280&height=220&format=webp';
import wood3_banner_lg_src from 'images/banners/woodtexture3.jpg?width=1440&height=250&format=webp';
export const wood3_banner = {
	xs: {src: wood3_banner_xs_src, id: 'wood3_banner_xs', width: '600', height: '200'},
	sm: {src: wood3_banner_sm_src, id: 'wood3_banner_sm', width: '960', height: '220'},
	md: {src: wood3_banner_md_src, id: 'wood3_banner_md', width: '1280', height: '220'},
	lg: {src: wood3_banner_lg_src, id: 'wood3_banner_lg', width: '1440', height: '250'},
};
import wood4_banner_xs_src from 'images/banners/woodtexture4.jpg?width=600&height=200&format=webp';
import wood4_banner_sm_src from 'images/banners/woodtexture4.jpg?width=960&height=220&format=webp';
import wood4_banner_md_src from 'images/banners/woodtexture4.jpg?width=1280&height=220&format=webp';
import wood4_banner_lg_src from 'images/banners/woodtexture4.jpg?width=1440&height=250&format=webp';
export const wood4_banner = {
	xs: {src: wood4_banner_xs_src, id: 'wood4_banner_xs', width: '600', height: '200'},
	sm: {src: wood4_banner_sm_src, id: 'wood4_banner_sm', width: '960', height: '220'},
	md: {src: wood4_banner_md_src, id: 'wood4_banner_md', width: '1280', height: '220'},
	lg: {src: wood4_banner_lg_src, id: 'wood4_banner_lg', width: '1440', height: '250'},
};
import wood5_banner_xs_src from 'images/banners/woodtexture5.jpg?width=600&height=200&format=webp';
import wood5_banner_sm_src from 'images/banners/woodtexture5.jpg?width=960&height=220&format=webp';
import wood5_banner_md_src from 'images/banners/woodtexture5.jpg?width=1280&height=220&format=webp';
import wood5_banner_lg_src from 'images/banners/woodtexture5.jpg?width=1440&height=250&format=webp';
export const wood5_banner = {
	xs: {src: wood5_banner_xs_src, id: 'wood5_banner_xs', width: '600', height: '200'},
	sm: {src: wood5_banner_sm_src, id: 'wood5_banner_sm', width: '960', height: '220'},
	md: {src: wood5_banner_md_src, id: 'wood5_banner_md', width: '1280', height: '220'},
	lg: {src: wood5_banner_lg_src, id: 'wood5_banner_lg', width: '1440', height: '250'},
};
import wood6_banner_xs_src from 'images/banners/woodtexture6.jpg?width=600&height=200&format=webp';
import wood6_banner_sm_src from 'images/banners/woodtexture6.jpg?width=960&height=220&format=webp';
import wood6_banner_md_src from 'images/banners/woodtexture6.jpg?width=1280&height=220&format=webp';
import wood6_banner_lg_src from 'images/banners/woodtexture6.jpg?width=1440&height=250&format=webp';
export const wood6_banner = {
	xs: {src: wood6_banner_xs_src, id: 'wood6_banner_xs', width: '600', height: '200'},
	sm: {src: wood6_banner_sm_src, id: 'wood6_banner_sm', width: '960', height: '220'},
	md: {src: wood6_banner_md_src, id: 'wood6_banner_md', width: '1280', height: '220'},
	lg: {src: wood6_banner_lg_src, id: 'wood6_banner_lg', width: '1440', height: '250'},
};
import forest1_banner_xs_src from 'images/banners/forest1.jpg?width=600&height=200&format=webp';
import forest1_banner_sm_src from 'images/banners/forest1.jpg?width=960&height=220&format=webp';
import forest1_banner_md_src from 'images/banners/forest1.jpg?width=1280&height=220&format=webp';
import forest1_banner_lg_src from 'images/banners/forest1.jpg?width=1440&height=250&format=webp';
export const forest1_banner = {
	xs: {src: forest1_banner_xs_src, id: 'forest1_banner_xs', width: '600', height: '200'},
	sm: {src: forest1_banner_sm_src, id: 'forest1_banner_sm', width: '960', height: '220'},
	md: {src: forest1_banner_md_src, id: 'forest1_banner_md', width: '1280', height: '220'},
	lg: {src: forest1_banner_lg_src, id: 'forest1_banner_lg', width: '1440', height: '250'},
};
import czech_home_banner_xs_src from 'images/banners/ausstellung-chemnitz.jpg?width=600&height=220&format=webp';
import czech_home_banner_sm_src from 'images/banners/ausstellung-chemnitz.jpg?width=960&height=220&format=webp';
import czech_home_banner_md_src from 'images/banners/ausstellung-chemnitz.jpg?width=1280&height=220&format=webp';
import czech_home_banner_lg_src from 'images/banners/ausstellung-chemnitz.jpg?width=1440&height=250&format=webp';
export const czech_home_banner = {
	xs: {src: czech_home_banner_xs_src, id: 'czech_home_banner_xs', width: '600', height: '220'},
	sm: {src: czech_home_banner_sm_src, id: 'czech_home_banner_sm', width: '960', height: '220'},
	md: {src: czech_home_banner_md_src, id: 'czech_home_banner_md', width: '1280', height: '220'},
	lg: {src: czech_home_banner_lg_src, id: 'czech_home_banner_lg', width: '1440', height: '250'},
};
import exhibition2_banner_xs_src from 'images/banners/exhibition/austellung-lauter.xs.png?width=600&height=180&format=webp';
import exhibition2_banner_sm_src from 'images/banners/exhibition/austellung-lauter.sm.png?width=960&height=220&format=webp';
import exhibition2_banner_md_src from 'images/banners/exhibition/austellung-lauter.md.png?width=1280&height=220&format=webp';
import exhibition2_banner_lg_src from 'images/banners/exhibition/austellung-lauter.lg.png?width=1440&height=250&format=webp';
export const exhibition2_banner = {
	xs: {src: exhibition2_banner_xs_src, id: 'exhibition2_banner_xs', width: '600', height: '180'},
	sm: {src: exhibition2_banner_sm_src, id: 'exhibition2_banner_sm', width: '960', height: '220'},
	md: {src: exhibition2_banner_md_src, id: 'exhibition2_banner_md', width: '1280', height: '220'},
	lg: {src: exhibition2_banner_lg_src, id: 'exhibition2_banner_lg', width: '1440', height: '250'},
};
import grill_academy_banner_xs_src from 'images/banners/grillkurse/grillkurse.xs.jpg?width=600&height=180&format=webp';
import grill_academy_banner_sm_src from 'images/banners/grillkurse/grillkurse.sm.jpg?width=960&height=220&format=webp';
import grill_academy_banner_md_src from 'images/banners/grillkurse/grillkurse.md.jpg?width=1280&height=220&format=webp';
import grill_academy_banner_lg_src from 'images/banners/grillkurse/grillkurse.lg.jpg?width=1440&height=250&format=webp';
export const grill_academy_banner = {
	xs: {src: grill_academy_banner_xs_src, id: 'grill_academy_banner_xs', width: '600', height: '180'},
	sm: {src: grill_academy_banner_sm_src, id: 'grill_academy_banner_sm', width: '960', height: '220'},
	md: {src: grill_academy_banner_md_src, id: 'grill_academy_banner_md', width: '1280', height: '220'},
	lg: {src: grill_academy_banner_lg_src, id: 'grill_academy_banner_lg', width: '1440', height: '250'},
};
import treeplanting1_banner_xs_src from 'images/banners/baumpflanzaktion/baumpflanzaktion1.xs.jpg?width=600&height=180&format=webp';
import treeplanting1_banner_sm_src from 'images/banners/baumpflanzaktion/baumpflanzaktion1.sm.jpg?width=960&height=220&format=webp';
import treeplanting1_banner_md_src from 'images/banners/baumpflanzaktion/baumpflanzaktion1.md.jpg?width=1280&height=220&format=webp';
import treeplanting1_banner_lg_src from 'images/banners/baumpflanzaktion/baumpflanzaktion1.lg.jpg?width=1440&height=250&format=webp';
export const treeplanting1_banner = {
	xs: {src: treeplanting1_banner_xs_src, id: 'treeplanting1_banner_xs', width: '600', height: '180'},
	sm: {src: treeplanting1_banner_sm_src, id: 'treeplanting1_banner_sm', width: '960', height: '220'},
	md: {src: treeplanting1_banner_md_src, id: 'treeplanting1_banner_md', width: '1280', height: '220'},
	lg: {src: treeplanting1_banner_lg_src, id: 'treeplanting1_banner_lg', width: '1440', height: '250'},
};
import treeplanting2_banner_xs_src from 'images/banners/baumpflanzaktion/baumpflanzaktion2.jpg?width=600&height=180&format=webp';
import treeplanting2_banner_sm_src from 'images/banners/baumpflanzaktion/baumpflanzaktion2.jpg?width=960&height=220&format=webp';
import treeplanting2_banner_md_src from 'images/banners/baumpflanzaktion/baumpflanzaktion2.jpg?width=1280&height=220&format=webp';
import treeplanting2_banner_lg_src from 'images/banners/baumpflanzaktion/baumpflanzaktion2.jpg?width=1440&height=250&format=webp';
export const treeplanting2_banner = {
	xs: {src: treeplanting2_banner_xs_src, id: 'treeplanting2_banner_xs', width: '600', height: '180'},
	sm: {src: treeplanting2_banner_sm_src, id: 'treeplanting2_banner_sm', width: '960', height: '220'},
	md: {src: treeplanting2_banner_md_src, id: 'treeplanting2_banner_md', width: '1280', height: '220'},
	lg: {src: treeplanting2_banner_lg_src, id: 'treeplanting2_banner_lg', width: '1440', height: '250'},
};
import history1_banner_xs_src from 'images/banners/history/history-lauter.xs.jpg?width=600&height=180&format=webp';
import history1_banner_sm_src from 'images/banners/history/history-lauter.sm.jpg?width=960&height=220&format=webp';
import history1_banner_md_src from 'images/banners/history/history-lauter.md.jpg?width=1280&height=220&format=webp';
import history1_banner_lg_src from 'images/banners/history/history-lauter.lg.jpg?width=1440&height=250&format=webp';
export const history1_banner = {
	xs: {src: history1_banner_xs_src, id: 'history1_banner_xs', width: '600', height: '180'},
	sm: {src: history1_banner_sm_src, id: 'history1_banner_sm', width: '960', height: '220'},
	md: {src: history1_banner_md_src, id: 'history1_banner_md', width: '1280', height: '220'},
	lg: {src: history1_banner_lg_src, id: 'history1_banner_lg', width: '1440', height: '250'},
};
import history2_banner_xs_src from 'images/banners/history/history-chemnitz.xs.jpg?width=600&height=180&format=webp';
import history2_banner_sm_src from 'images/banners/history/history-chemnitz.sm.jpg?width=960&height=220&format=webp';
import history2_banner_md_src from 'images/banners/history/history-chemnitz.md.jpg?width=1280&height=220&format=webp';
import history2_banner_lg_src from 'images/banners/history/history-chemnitz.lg.jpg?width=1440&height=250&format=webp';
export const history2_banner = {
	xs: {src: history2_banner_xs_src, id: 'history2_banner_xs', width: '600', height: '180'},
	sm: {src: history2_banner_sm_src, id: 'history2_banner_sm', width: '960', height: '220'},
	md: {src: history2_banner_md_src, id: 'history2_banner_md', width: '1280', height: '220'},
	lg: {src: history2_banner_lg_src, id: 'history2_banner_lg', width: '1440', height: '250'},
};
import anniversary_banner_xs_src from 'images/banners/anniversary/banner_100_jahre_hw.xs.png?width=600&height=180&format=webp';
import anniversary_banner_sm_src from 'images/banners/anniversary/banner_100_jahre_hw.sm.png?width=960&height=220&format=webp';
import anniversary_banner_md_src from 'images/banners/anniversary/banner_100_jahre_hw.md.png?width=1280&height=220&format=webp';
import anniversary_banner_lg_src from 'images/banners/anniversary/banner_100_jahre_hw.lg.png?width=1440&height=250&format=webp';
export const anniversary_banner = {
	xs: {src: anniversary_banner_xs_src, id: 'anniversary_banner_xs', width: '600', height: '180'},
	sm: {src: anniversary_banner_sm_src, id: 'anniversary_banner_sm', width: '960', height: '220'},
	md: {src: anniversary_banner_md_src, id: 'anniversary_banner_md', width: '1280', height: '220'},
	lg: {src: anniversary_banner_lg_src, id: 'anniversary_banner_lg', width: '1440', height: '250'},
};
import factory_home_banner_xs_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=600&height=220&format=webp';
import factory_home_banner_sm_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=960&height=240&format=webp';
import factory_home_banner_md_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=1280&height=220&format=webp';
import factory_home_banner_lg_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=1440&height=250&format=webp';
export const factory_home_banner = {
	xs: {src: factory_home_banner_xs_src, id: 'factory_home_banner_xs', width: '600', height: '220'},
	sm: {src: factory_home_banner_sm_src, id: 'factory_home_banner_sm', width: '960', height: '240'},
	md: {src: factory_home_banner_md_src, id: 'factory_home_banner_md', width: '1280', height: '220'},
	lg: {src: factory_home_banner_lg_src, id: 'factory_home_banner_lg', width: '1440', height: '250'},
};
import assortment1_retail_card_xs_src from 'images/cards/assortment/hwc-holzbau-fassade.jpg?width=284&height=189&format=webp';
import assortment1_retail_card_sm_src from 'images/cards/assortment/hwc-holzbau-fassade.jpg?width=480&height=320&format=webp';
export const assortment1_retail_card = {
	xs: {src: assortment1_retail_card_xs_src, id: 'assortment1_retail_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_retail_card_sm_src, id: 'assortment1_retail_card_sm', width: '480', height: '320'},
};
import assortment1_wholesale_card_xs_src from 'images/cards/assortment/bauen-mit-holz.jpg?width=284&height=189&format=webp';
import assortment1_wholesale_card_sm_src from 'images/cards/assortment/bauen-mit-holz.jpg?width=480&height=320&format=webp';
export const assortment1_wholesale_card = {
	xs: {src: assortment1_wholesale_card_xs_src, id: 'assortment1_wholesale_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_wholesale_card_sm_src, id: 'assortment1_wholesale_card_sm', width: '480', height: '320'},
};
import assortment2_card_xs_src from 'images/cards/assortment/tischlerware.jpg?width=284&height=189&format=webp';
import assortment2_card_sm_src from 'images/cards/assortment/tischlerware.jpg?width=480&height=320&format=webp';
export const assortment2_card = {
	xs: {src: assortment2_card_xs_src, id: 'assortment2_card_xs', width: '284', height: '189'},
	sm: {src: assortment2_card_sm_src, id: 'assortment2_card_sm', width: '480', height: '320'},
};
import assortment3_retail_card_xs_src from 'images/cards/assortment/hwc-platten.jpg?width=284&height=189&format=webp';
import assortment3_retail_card_sm_src from 'images/cards/assortment/hwc-platten.jpg?width=480&height=320&format=webp';
export const assortment3_retail_card = {
	xs: {src: assortment3_retail_card_xs_src, id: 'assortment3_retail_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_retail_card_sm_src, id: 'assortment3_retail_card_sm', width: '480', height: '320'},
};
import assortment3_wholesale_card_xs_src from 'images/cards/assortment/platten.jpg?width=284&height=189&format=webp';
import assortment3_wholesale_card_sm_src from 'images/cards/assortment/platten.jpg?width=480&height=320&format=webp';
export const assortment3_wholesale_card = {
	xs: {src: assortment3_wholesale_card_xs_src, id: 'assortment3_wholesale_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_wholesale_card_sm_src, id: 'assortment3_wholesale_card_sm', width: '480', height: '320'},
};
import assortment4_retail_card_xs_src from 'images/cards/assortment/hwc-boden-wand-decke.jpg?width=284&height=189&format=webp';
import assortment4_retail_card_sm_src from 'images/cards/assortment/hwc-boden-wand-decke.jpg?width=480&height=320&format=webp';
export const assortment4_retail_card = {
	xs: {src: assortment4_retail_card_xs_src, id: 'assortment4_retail_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_retail_card_sm_src, id: 'assortment4_retail_card_sm', width: '480', height: '320'},
};
import assortment4_wholesale_card_xs_src from 'images/cards/assortment/boden-wand-decke.jpg?width=284&height=189&format=webp';
import assortment4_wholesale_card_sm_src from 'images/cards/assortment/boden-wand-decke.jpg?width=480&height=320&format=webp';
export const assortment4_wholesale_card = {
	xs: {src: assortment4_wholesale_card_xs_src, id: 'assortment4_wholesale_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_wholesale_card_sm_src, id: 'assortment4_wholesale_card_sm', width: '480', height: '320'},
};
import assortment5_retail_card_xs_src from 'images/cards/assortment/hwc-terrasse-garten.jpg?width=284&height=189&format=webp';
import assortment5_retail_card_sm_src from 'images/cards/assortment/hwc-terrasse-garten.jpg?width=480&height=320&format=webp';
export const assortment5_retail_card = {
	xs: {src: assortment5_retail_card_xs_src, id: 'assortment5_retail_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_retail_card_sm_src, id: 'assortment5_retail_card_sm', width: '480', height: '320'},
};
import assortment5_wholesale_card_xs_src from 'images/cards/assortment/garten-terrasse.jpg?width=284&height=189&format=webp';
import assortment5_wholesale_card_sm_src from 'images/cards/assortment/garten-terrasse.jpg?width=480&height=320&format=webp';
export const assortment5_wholesale_card = {
	xs: {src: assortment5_wholesale_card_xs_src, id: 'assortment5_wholesale_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_wholesale_card_sm_src, id: 'assortment5_wholesale_card_sm', width: '480', height: '320'},
};
import assortment6_retail_card_xs_src from 'images/cards/assortment/hwc-tueren-neu-erleben.jpg?width=284&height=189&format=webp';
import assortment6_retail_card_sm_src from 'images/cards/assortment/hwc-tueren-neu-erleben.jpg?width=480&height=320&format=webp';
export const assortment6_retail_card = {
	xs: {src: assortment6_retail_card_xs_src, id: 'assortment6_retail_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_retail_card_sm_src, id: 'assortment6_retail_card_sm', width: '480', height: '320'},
};
import assortment6_wholesale_card_xs_src from 'images/cards/assortment/türen-bauelemente.jpg?width=284&height=189&format=webp';
import assortment6_wholesale_card_sm_src from 'images/cards/assortment/türen-bauelemente.jpg?width=480&height=320&format=webp';
export const assortment6_wholesale_card = {
	xs: {src: assortment6_wholesale_card_xs_src, id: 'assortment6_wholesale_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_wholesale_card_sm_src, id: 'assortment6_wholesale_card_sm', width: '480', height: '320'},
};
import assortment7_card_xs_src from 'images/cards/assortment/sauna-wellness.jpg?width=284&height=189&format=webp';
import assortment7_card_sm_src from 'images/cards/assortment/sauna-wellness.jpg?width=480&height=320&format=webp';
export const assortment7_card = {
	xs: {src: assortment7_card_xs_src, id: 'assortment7_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_card_sm_src, id: 'assortment7_card_sm', width: '480', height: '320'},
};
import assortment8_card_xs_src from 'images/cards/assortment/grillen-freizeit.jpg?width=284&height=189&format=webp';
import assortment8_card_sm_src from 'images/cards/assortment/grillen-freizeit.jpg?width=480&height=320&format=webp';
export const assortment8_card = {
	xs: {src: assortment8_card_xs_src, id: 'assortment8_card_xs', width: '284', height: '189'},
	sm: {src: assortment8_card_sm_src, id: 'assortment8_card_sm', width: '480', height: '320'},
};
import assortment10_card_xs_src from 'images/cards/assortment/holzfabrik-erzgebirge.jpg?width=284&height=189&format=webp';
import assortment10_card_sm_src from 'images/cards/assortment/holzfabrik-erzgebirge.jpg?width=480&height=320&format=webp';
export const assortment10_card = {
	xs: {src: assortment10_card_xs_src, id: 'assortment10_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_card_sm_src, id: 'assortment10_card_sm', width: '480', height: '320'},
};
import assortment11_card_xs_src from 'images/cards/assortment/antik-altholz.jpg?width=284&height=189&format=webp';
import assortment11_card_sm_src from 'images/cards/assortment/antik-altholz.jpg?width=480&height=320&format=webp';
export const assortment11_card = {
	xs: {src: assortment11_card_xs_src, id: 'assortment11_card_xs', width: '284', height: '189'},
	sm: {src: assortment11_card_sm_src, id: 'assortment11_card_sm', width: '480', height: '320'},
};
import assortment9_card_xs_src from 'images/cards/assortment/technischer-industriebedarf.jpg?width=284&height=189&format=webp';
import assortment9_card_sm_src from 'images/cards/assortment/technischer-industriebedarf.jpg?width=480&height=320&format=webp';
export const assortment9_card = {
	xs: {src: assortment9_card_xs_src, id: 'assortment9_card_xs', width: '284', height: '189'},
	sm: {src: assortment9_card_sm_src, id: 'assortment9_card_sm', width: '480', height: '320'},
};
import assortment1_1_card_xs_src from 'images/cards/assortment/bauholz.jpg?width=284&height=189&format=webp';
import assortment1_1_card_sm_src from 'images/cards/assortment/bauholz.jpg?width=480&height=320&format=webp';
export const assortment1_1_card = {
	xs: {src: assortment1_1_card_xs_src, id: 'assortment1_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_card_sm_src, id: 'assortment1_1_card_sm', width: '480', height: '320'},
};
import assortment1_2_card_xs_src from 'images/cards/assortment/hobelware.jpg?width=284&height=189&format=webp';
import assortment1_2_card_sm_src from 'images/cards/assortment/hobelware.jpg?width=480&height=320&format=webp';
export const assortment1_2_card = {
	xs: {src: assortment1_2_card_xs_src, id: 'assortment1_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_card_sm_src, id: 'assortment1_2_card_sm', width: '480', height: '320'},
};
import assortment1_3_card_xs_src from 'images/cards/assortment/fassade.jpg?width=284&height=189&format=webp';
import assortment1_3_card_sm_src from 'images/cards/assortment/fassade.jpg?width=480&height=320&format=webp';
export const assortment1_3_card = {
	xs: {src: assortment1_3_card_xs_src, id: 'assortment1_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_3_card_sm_src, id: 'assortment1_3_card_sm', width: '480', height: '320'},
};
import assortment1_4_card_xs_src from 'images/cards/assortment/dämmstoffe.jpg?width=284&height=189&format=webp';
import assortment1_4_card_sm_src from 'images/cards/assortment/dämmstoffe.jpg?width=480&height=320&format=webp';
export const assortment1_4_card = {
	xs: {src: assortment1_4_card_xs_src, id: 'assortment1_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_4_card_sm_src, id: 'assortment1_4_card_sm', width: '480', height: '320'},
};
import assortment1_5_card_xs_src from 'images/cards/assortment/dachausbau.jpg?width=284&height=189&format=webp';
import assortment1_5_card_sm_src from 'images/cards/assortment/dachausbau.jpg?width=480&height=320&format=webp';
export const assortment1_5_card = {
	xs: {src: assortment1_5_card_xs_src, id: 'assortment1_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_5_card_sm_src, id: 'assortment1_5_card_sm', width: '480', height: '320'},
};
import assortment1_1_1_card_xs_src from 'images/cards/assortment/kantholz.jpg?width=284&height=189&format=webp';
import assortment1_1_1_card_sm_src from 'images/cards/assortment/kantholz.jpg?width=480&height=320&format=webp';
export const assortment1_1_1_card = {
	xs: {src: assortment1_1_1_card_xs_src, id: 'assortment1_1_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_1_card_sm_src, id: 'assortment1_1_1_card_sm', width: '480', height: '320'},
};
import assortment1_1_2_card_xs_src from 'images/cards/assortment/latten.jpg?width=284&height=189&format=webp';
import assortment1_1_2_card_sm_src from 'images/cards/assortment/latten.jpg?width=480&height=320&format=webp';
export const assortment1_1_2_card = {
	xs: {src: assortment1_1_2_card_xs_src, id: 'assortment1_1_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_2_card_sm_src, id: 'assortment1_1_2_card_sm', width: '480', height: '320'},
};
import assortment1_1_3_card_xs_src from 'images/cards/assortment/schalung.jpg?width=284&height=189&format=webp';
import assortment1_1_3_card_sm_src from 'images/cards/assortment/schalung.jpg?width=480&height=320&format=webp';
export const assortment1_1_3_card = {
	xs: {src: assortment1_1_3_card_xs_src, id: 'assortment1_1_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_3_card_sm_src, id: 'assortment1_1_3_card_sm', width: '480', height: '320'},
};
import assortment1_1_4_card_xs_src from 'images/cards/assortment/bohlen.jpg?width=284&height=189&format=webp';
import assortment1_1_4_card_sm_src from 'images/cards/assortment/bohlen.jpg?width=480&height=320&format=webp';
export const assortment1_1_4_card = {
	xs: {src: assortment1_1_4_card_xs_src, id: 'assortment1_1_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_4_card_sm_src, id: 'assortment1_1_4_card_sm', width: '480', height: '320'},
};
import assortment1_1_5_card_xs_src from 'images/cards/assortment/keilbohlen.jpg?width=284&height=189&format=webp';
import assortment1_1_5_card_sm_src from 'images/cards/assortment/keilbohlen.jpg?width=480&height=320&format=webp';
export const assortment1_1_5_card = {
	xs: {src: assortment1_1_5_card_xs_src, id: 'assortment1_1_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_5_card_sm_src, id: 'assortment1_1_5_card_sm', width: '480', height: '320'},
};
import assortment1_1_6_card_xs_src from 'images/cards/assortment/kreuzholz.jpg?width=284&height=189&format=webp';
import assortment1_1_6_card_sm_src from 'images/cards/assortment/kreuzholz.jpg?width=480&height=320&format=webp';
export const assortment1_1_6_card = {
	xs: {src: assortment1_1_6_card_xs_src, id: 'assortment1_1_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_6_card_sm_src, id: 'assortment1_1_6_card_sm', width: '480', height: '320'},
};
import assortment1_1_7_card_xs_src from 'images/cards/assortment/halbholz.jpg?width=284&height=189&format=webp';
import assortment1_1_7_card_sm_src from 'images/cards/assortment/halbholz.jpg?width=480&height=320&format=webp';
export const assortment1_1_7_card = {
	xs: {src: assortment1_1_7_card_xs_src, id: 'assortment1_1_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_7_card_sm_src, id: 'assortment1_1_7_card_sm', width: '480', height: '320'},
};
import assortment1_1_8_card_xs_src from 'images/cards/assortment/konstruktionsvollholz.jpg?width=284&height=189&format=webp';
import assortment1_1_8_card_sm_src from 'images/cards/assortment/konstruktionsvollholz.jpg?width=480&height=320&format=webp';
export const assortment1_1_8_card = {
	xs: {src: assortment1_1_8_card_xs_src, id: 'assortment1_1_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_8_card_sm_src, id: 'assortment1_1_8_card_sm', width: '480', height: '320'},
};
import assortment1_1_9_card_xs_src from 'images/cards/assortment/brettschichtholz.jpg?width=284&height=189&format=webp';
import assortment1_1_9_card_sm_src from 'images/cards/assortment/brettschichtholz.jpg?width=480&height=320&format=webp';
export const assortment1_1_9_card = {
	xs: {src: assortment1_1_9_card_xs_src, id: 'assortment1_1_9_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_1_9_card_sm_src, id: 'assortment1_1_9_card_sm', width: '480', height: '320'},
};
import assortment1_2_1_card_xs_src from 'images/cards/assortment/glattkantbretter.jpg?width=284&height=189&format=webp';
import assortment1_2_1_card_sm_src from 'images/cards/assortment/glattkantbretter.jpg?width=480&height=320&format=webp';
export const assortment1_2_1_card = {
	xs: {src: assortment1_2_1_card_xs_src, id: 'assortment1_2_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_1_card_sm_src, id: 'assortment1_2_1_card_sm', width: '480', height: '320'},
};
import assortment1_2_2_card_xs_src from 'images/cards/assortment/rauhspund.jpg?width=284&height=189&format=webp';
import assortment1_2_2_card_sm_src from 'images/cards/assortment/rauhspund.jpg?width=480&height=320&format=webp';
export const assortment1_2_2_card = {
	xs: {src: assortment1_2_2_card_xs_src, id: 'assortment1_2_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_2_card_sm_src, id: 'assortment1_2_2_card_sm', width: '480', height: '320'},
};
import assortment1_2_3_card_xs_src from 'images/cards/assortment/fasebretter.jpg?width=284&height=189&format=webp';
import assortment1_2_3_card_sm_src from 'images/cards/assortment/fasebretter.jpg?width=480&height=320&format=webp';
export const assortment1_2_3_card = {
	xs: {src: assortment1_2_3_card_xs_src, id: 'assortment1_2_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_3_card_sm_src, id: 'assortment1_2_3_card_sm', width: '480', height: '320'},
};
import assortment1_2_4_card_xs_src from 'images/cards/assortment/stülpschalung.jpg?width=284&height=189&format=webp';
import assortment1_2_4_card_sm_src from 'images/cards/assortment/stülpschalung.jpg?width=480&height=320&format=webp';
export const assortment1_2_4_card = {
	xs: {src: assortment1_2_4_card_xs_src, id: 'assortment1_2_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_4_card_sm_src, id: 'assortment1_2_4_card_sm', width: '480', height: '320'},
};
import assortment1_2_5_card_xs_src from 'images/cards/assortment/softline.jpg?width=284&height=189&format=webp';
import assortment1_2_5_card_sm_src from 'images/cards/assortment/softline.jpg?width=480&height=320&format=webp';
export const assortment1_2_5_card = {
	xs: {src: assortment1_2_5_card_xs_src, id: 'assortment1_2_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_5_card_sm_src, id: 'assortment1_2_5_card_sm', width: '480', height: '320'},
};
import assortment1_2_6_card_xs_src from 'images/cards/assortment/kombiprofil.jpg?width=284&height=189&format=webp';
import assortment1_2_6_card_sm_src from 'images/cards/assortment/kombiprofil.jpg?width=480&height=320&format=webp';
export const assortment1_2_6_card = {
	xs: {src: assortment1_2_6_card_xs_src, id: 'assortment1_2_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_6_card_sm_src, id: 'assortment1_2_6_card_sm', width: '480', height: '320'},
};
import assortment1_2_7_card_xs_src from 'images/cards/assortment/unterbau-latten.jpg?width=284&height=189&format=webp';
import assortment1_2_7_card_sm_src from 'images/cards/assortment/unterbau-latten.jpg?width=480&height=320&format=webp';
export const assortment1_2_7_card = {
	xs: {src: assortment1_2_7_card_xs_src, id: 'assortment1_2_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_7_card_sm_src, id: 'assortment1_2_7_card_sm', width: '480', height: '320'},
};
import assortment1_2_8_card_xs_src from 'images/cards/assortment/saunaholz.jpg?width=284&height=189&format=webp';
import assortment1_2_8_card_sm_src from 'images/cards/assortment/saunaholz.jpg?width=480&height=320&format=webp';
export const assortment1_2_8_card = {
	xs: {src: assortment1_2_8_card_xs_src, id: 'assortment1_2_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_2_8_card_sm_src, id: 'assortment1_2_8_card_sm', width: '480', height: '320'},
};
import assortment1_3_1_card_xs_src from 'images/cards/assortment/fassade-holz.jpg?width=284&height=189&format=webp';
import assortment1_3_1_card_sm_src from 'images/cards/assortment/fassade-holz.jpg?width=480&height=320&format=webp';
export const assortment1_3_1_card = {
	xs: {src: assortment1_3_1_card_xs_src, id: 'assortment1_3_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_3_1_card_sm_src, id: 'assortment1_3_1_card_sm', width: '480', height: '320'},
};
import assortment1_3_5_card_xs_src from 'images/cards/assortment/faserzement.jpg?width=284&height=189&format=webp';
import assortment1_3_5_card_sm_src from 'images/cards/assortment/faserzement.jpg?width=480&height=320&format=webp';
export const assortment1_3_5_card = {
	xs: {src: assortment1_3_5_card_xs_src, id: 'assortment1_3_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_3_5_card_sm_src, id: 'assortment1_3_5_card_sm', width: '480', height: '320'},
};
import assortment1_3_2_card_xs_src from 'images/cards/assortment/fassade-wpc.jpg?width=284&height=189&format=webp';
import assortment1_3_2_card_sm_src from 'images/cards/assortment/fassade-wpc.jpg?width=480&height=320&format=webp';
export const assortment1_3_2_card = {
	xs: {src: assortment1_3_2_card_xs_src, id: 'assortment1_3_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_3_2_card_sm_src, id: 'assortment1_3_2_card_sm', width: '480', height: '320'},
};
import assortment1_3_3_card_xs_src from 'images/cards/assortment/hpl-kompaktplatten.jpg?width=284&height=189&format=webp';
import assortment1_3_3_card_sm_src from 'images/cards/assortment/hpl-kompaktplatten.jpg?width=480&height=320&format=webp';
export const assortment1_3_3_card = {
	xs: {src: assortment1_3_3_card_xs_src, id: 'assortment1_3_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_3_3_card_sm_src, id: 'assortment1_3_3_card_sm', width: '480', height: '320'},
};
import assortment1_3_4_card_xs_src from 'images/cards/assortment/fassade-zubehör.jpg?width=284&height=189&format=webp';
import assortment1_3_4_card_sm_src from 'images/cards/assortment/fassade-zubehör.jpg?width=480&height=320&format=webp';
export const assortment1_3_4_card = {
	xs: {src: assortment1_3_4_card_xs_src, id: 'assortment1_3_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_3_4_card_sm_src, id: 'assortment1_3_4_card_sm', width: '480', height: '320'},
};
import assortment1_4_1_card_xs_src from 'images/cards/assortment/druckfeste-dämmstoffe.jpg?width=284&height=189&format=webp';
import assortment1_4_1_card_sm_src from 'images/cards/assortment/druckfeste-dämmstoffe.jpg?width=480&height=320&format=webp';
export const assortment1_4_1_card = {
	xs: {src: assortment1_4_1_card_xs_src, id: 'assortment1_4_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_4_1_card_sm_src, id: 'assortment1_4_1_card_sm', width: '480', height: '320'},
};
import assortment1_4_2_card_xs_src from 'images/cards/assortment/flexible-dämmstoffe.jpg?width=284&height=189&format=webp';
import assortment1_4_2_card_sm_src from 'images/cards/assortment/flexible-dämmstoffe.jpg?width=480&height=320&format=webp';
export const assortment1_4_2_card = {
	xs: {src: assortment1_4_2_card_xs_src, id: 'assortment1_4_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_4_2_card_sm_src, id: 'assortment1_4_2_card_sm', width: '480', height: '320'},
};
import assortment1_4_3_card_xs_src from 'images/cards/assortment/fassadenbahnen.jpg?width=284&height=189&format=webp';
import assortment1_4_3_card_sm_src from 'images/cards/assortment/fassadenbahnen.jpg?width=480&height=320&format=webp';
export const assortment1_4_3_card = {
	xs: {src: assortment1_4_3_card_xs_src, id: 'assortment1_4_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_4_3_card_sm_src, id: 'assortment1_4_3_card_sm', width: '480', height: '320'},
};
import assortment1_4_4_card_xs_src from 'images/cards/assortment/kleb-dichtstoffe.jpg?width=284&height=189&format=webp';
import assortment1_4_4_card_sm_src from 'images/cards/assortment/kleb-dichtstoffe.jpg?width=480&height=320&format=webp';
export const assortment1_4_4_card = {
	xs: {src: assortment1_4_4_card_xs_src, id: 'assortment1_4_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_4_4_card_sm_src, id: 'assortment1_4_4_card_sm', width: '480', height: '320'},
};
import assortment1_4_5_card_xs_src from 'images/cards/assortment/luftdichtheits-feuchteschutz.jpg?width=284&height=189&format=webp';
import assortment1_4_5_card_sm_src from 'images/cards/assortment/luftdichtheits-feuchteschutz.jpg?width=480&height=320&format=webp';
export const assortment1_4_5_card = {
	xs: {src: assortment1_4_5_card_xs_src, id: 'assortment1_4_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_4_5_card_sm_src, id: 'assortment1_4_5_card_sm', width: '480', height: '320'},
};
import assortment1_4_6_card_xs_src from 'images/cards/assortment/dämmstoffe-zubehör.jpg?width=284&height=189&format=webp';
import assortment1_4_6_card_sm_src from 'images/cards/assortment/dämmstoffe-zubehör.jpg?width=480&height=320&format=webp';
export const assortment1_4_6_card = {
	xs: {src: assortment1_4_6_card_xs_src, id: 'assortment1_4_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment1_4_6_card_sm_src, id: 'assortment1_4_6_card_sm', width: '480', height: '320'},
};
import assortment2_1_card_xs_src from 'images/cards/assortment/nadelholz.jpg?width=284&height=189&format=webp';
import assortment2_1_card_sm_src from 'images/cards/assortment/nadelholz.jpg?width=480&height=320&format=webp';
export const assortment2_1_card = {
	xs: {src: assortment2_1_card_xs_src, id: 'assortment2_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment2_1_card_sm_src, id: 'assortment2_1_card_sm', width: '480', height: '320'},
};
import assortment2_2_card_xs_src from 'images/cards/assortment/laubholz.jpg?width=284&height=189&format=webp';
import assortment2_2_card_sm_src from 'images/cards/assortment/laubholz.jpg?width=480&height=320&format=webp';
export const assortment2_2_card = {
	xs: {src: assortment2_2_card_xs_src, id: 'assortment2_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment2_2_card_sm_src, id: 'assortment2_2_card_sm', width: '480', height: '320'},
};
import assortment2_3_card_xs_src from 'images/cards/assortment/afrikanische-hölzer.jpg?width=284&height=189&format=webp';
import assortment2_3_card_sm_src from 'images/cards/assortment/afrikanische-hölzer.jpg?width=480&height=320&format=webp';
export const assortment2_3_card = {
	xs: {src: assortment2_3_card_xs_src, id: 'assortment2_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment2_3_card_sm_src, id: 'assortment2_3_card_sm', width: '480', height: '320'},
};
import assortment3_1_card_xs_src from 'images/cards/assortment/platten-dekorativ.jpg?width=284&height=189&format=webp';
import assortment3_1_card_sm_src from 'images/cards/assortment/platten-dekorativ.jpg?width=480&height=320&format=webp';
export const assortment3_1_card = {
	xs: {src: assortment3_1_card_xs_src, id: 'assortment3_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_1_card_sm_src, id: 'assortment3_1_card_sm', width: '480', height: '320'},
};
import assortment3_2_card_xs_src from 'images/cards/assortment/platten-konstruktiv.jpg?width=284&height=189&format=webp';
import assortment3_2_card_sm_src from 'images/cards/assortment/platten-konstruktiv.jpg?width=480&height=320&format=webp';
export const assortment3_2_card = {
	xs: {src: assortment3_2_card_xs_src, id: 'assortment3_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_card_sm_src, id: 'assortment3_2_card_sm', width: '480', height: '320'},
};
import assortment3_3_card_xs_src from 'images/cards/assortment/platten-zubehör.jpg?width=284&height=189&format=webp';
import assortment3_3_card_sm_src from 'images/cards/assortment/platten-zubehör.jpg?width=480&height=320&format=webp';
export const assortment3_3_card = {
	xs: {src: assortment3_3_card_xs_src, id: 'assortment3_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_3_card_sm_src, id: 'assortment3_3_card_sm', width: '480', height: '320'},
};
import assortment3_1_1_card_xs_src from 'images/cards/assortment/dekorspanplatten.jpg?width=284&height=189&format=webp';
import assortment3_1_1_card_sm_src from 'images/cards/assortment/dekorspanplatten.jpg?width=480&height=320&format=webp';
export const assortment3_1_1_card = {
	xs: {src: assortment3_1_1_card_xs_src, id: 'assortment3_1_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_1_1_card_sm_src, id: 'assortment3_1_1_card_sm', width: '480', height: '320'},
};
import assortment3_1_2_card_xs_src from 'images/cards/assortment/arbeitsplatten.jpg?width=284&height=189&format=webp';
import assortment3_1_2_card_sm_src from 'images/cards/assortment/arbeitsplatten.jpg?width=480&height=320&format=webp';
export const assortment3_1_2_card = {
	xs: {src: assortment3_1_2_card_xs_src, id: 'assortment3_1_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_1_2_card_sm_src, id: 'assortment3_1_2_card_sm', width: '480', height: '320'},
};
import assortment3_1_3_card_xs_src from 'images/cards/assortment/furnierte-platten.jpg?width=284&height=189&format=webp';
import assortment3_1_3_card_sm_src from 'images/cards/assortment/furnierte-platten.jpg?width=480&height=320&format=webp';
export const assortment3_1_3_card = {
	xs: {src: assortment3_1_3_card_xs_src, id: 'assortment3_1_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_1_3_card_sm_src, id: 'assortment3_1_3_card_sm', width: '480', height: '320'},
};
import assortment3_1_4_card_xs_src from 'images/cards/assortment/tischlerplatten.jpg?width=284&height=189&format=webp';
import assortment3_1_4_card_sm_src from 'images/cards/assortment/tischlerplatten.jpg?width=480&height=320&format=webp';
export const assortment3_1_4_card = {
	xs: {src: assortment3_1_4_card_xs_src, id: 'assortment3_1_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_1_4_card_sm_src, id: 'assortment3_1_4_card_sm', width: '480', height: '320'},
};
import assortment3_1_5_card_xs_src from 'images/cards/assortment/hobbyleimholzplatten.jpg?width=284&height=189&format=webp';
import assortment3_1_5_card_sm_src from 'images/cards/assortment/hobbyleimholzplatten.jpg?width=480&height=320&format=webp';
export const assortment3_1_5_card = {
	xs: {src: assortment3_1_5_card_xs_src, id: 'assortment3_1_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_1_5_card_sm_src, id: 'assortment3_1_5_card_sm', width: '480', height: '320'},
};
import assortment3_2_1_card_xs_src from 'images/cards/assortment/rohspanplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_1_card_sm_src from 'images/cards/assortment/rohspanplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_1_card = {
	xs: {src: assortment3_2_1_card_xs_src, id: 'assortment3_2_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_1_card_sm_src, id: 'assortment3_2_1_card_sm', width: '480', height: '320'},
};
import assortment3_2_2_card_xs_src from 'images/cards/assortment/mdf-hdf-faserplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_2_card_sm_src from 'images/cards/assortment/mdf-hdf-faserplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_2_card = {
	xs: {src: assortment3_2_2_card_xs_src, id: 'assortment3_2_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_2_card_sm_src, id: 'assortment3_2_2_card_sm', width: '480', height: '320'},
};
import assortment3_2_3_card_xs_src from 'images/cards/assortment/osb-platten.jpg?width=284&height=189&format=webp';
import assortment3_2_3_card_sm_src from 'images/cards/assortment/osb-platten.jpg?width=480&height=320&format=webp';
export const assortment3_2_3_card = {
	xs: {src: assortment3_2_3_card_xs_src, id: 'assortment3_2_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_3_card_sm_src, id: 'assortment3_2_3_card_sm', width: '480', height: '320'},
};
import assortment3_2_4_card_xs_src from 'images/cards/assortment/schichtplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_4_card_sm_src from 'images/cards/assortment/schichtplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_4_card = {
	xs: {src: assortment3_2_4_card_xs_src, id: 'assortment3_2_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_4_card_sm_src, id: 'assortment3_2_4_card_sm', width: '480', height: '320'},
};
import assortment3_2_5_card_xs_src from 'images/cards/assortment/leimholzplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_5_card_sm_src from 'images/cards/assortment/leimholzplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_5_card = {
	xs: {src: assortment3_2_5_card_xs_src, id: 'assortment3_2_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_5_card_sm_src, id: 'assortment3_2_5_card_sm', width: '480', height: '320'},
};
import assortment3_2_6_card_xs_src from 'images/cards/assortment/sperrholz-multiplexplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_6_card_sm_src from 'images/cards/assortment/sperrholz-multiplexplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_6_card = {
	xs: {src: assortment3_2_6_card_xs_src, id: 'assortment3_2_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_6_card_sm_src, id: 'assortment3_2_6_card_sm', width: '480', height: '320'},
};
import assortment3_2_7_card_xs_src from 'images/cards/assortment/fahrzeugplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_7_card_sm_src from 'images/cards/assortment/fahrzeugplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_7_card = {
	xs: {src: assortment3_2_7_card_xs_src, id: 'assortment3_2_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_7_card_sm_src, id: 'assortment3_2_7_card_sm', width: '480', height: '320'},
};
import assortment3_2_8_card_xs_src from 'images/cards/assortment/betonschalungsplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_8_card_sm_src from 'images/cards/assortment/betonschalungsplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_8_card = {
	xs: {src: assortment3_2_8_card_xs_src, id: 'assortment3_2_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_8_card_sm_src, id: 'assortment3_2_8_card_sm', width: '480', height: '320'},
};
import assortment3_2_9_card_xs_src from 'images/cards/assortment/verlegeplatten.jpg?width=284&height=189&format=webp';
import assortment3_2_9_card_sm_src from 'images/cards/assortment/verlegeplatten.jpg?width=480&height=320&format=webp';
export const assortment3_2_9_card = {
	xs: {src: assortment3_2_9_card_xs_src, id: 'assortment3_2_9_card_xs', width: '284', height: '189'},
	sm: {src: assortment3_2_9_card_sm_src, id: 'assortment3_2_9_card_sm', width: '480', height: '320'},
};
import assortment4_1_card_xs_src from 'images/cards/assortment/hq-bodenwelt.png?width=284&height=189&format=webp';
import assortment4_1_card_sm_src from 'images/cards/assortment/hq-bodenwelt.png?width=480&height=320&format=webp';
export const assortment4_1_card = {
	xs: {src: assortment4_1_card_xs_src, id: 'assortment4_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_1_card_sm_src, id: 'assortment4_1_card_sm', width: '480', height: '320'},
};
import assortment4_2_card_xs_src from 'images/cards/assortment/laminat.jpg?width=284&height=189&format=webp';
import assortment4_2_card_sm_src from 'images/cards/assortment/laminat.jpg?width=480&height=320&format=webp';
export const assortment4_2_card = {
	xs: {src: assortment4_2_card_xs_src, id: 'assortment4_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_2_card_sm_src, id: 'assortment4_2_card_sm', width: '480', height: '320'},
};
import assortment4_3_card_xs_src from 'images/cards/assortment/vinyl-designboden.jpg?width=284&height=189&format=webp';
import assortment4_3_card_sm_src from 'images/cards/assortment/vinyl-designboden.jpg?width=480&height=320&format=webp';
export const assortment4_3_card = {
	xs: {src: assortment4_3_card_xs_src, id: 'assortment4_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_3_card_sm_src, id: 'assortment4_3_card_sm', width: '480', height: '320'},
};
import assortment4_4_card_xs_src from 'images/cards/assortment/parkett.jpg?width=284&height=189&format=webp';
import assortment4_4_card_sm_src from 'images/cards/assortment/parkett.jpg?width=480&height=320&format=webp';
export const assortment4_4_card = {
	xs: {src: assortment4_4_card_xs_src, id: 'assortment4_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_4_card_sm_src, id: 'assortment4_4_card_sm', width: '480', height: '320'},
};
import assortment4_5_card_xs_src from 'images/cards/assortment/echtholzboden.jpg?width=284&height=189&format=webp';
import assortment4_5_card_sm_src from 'images/cards/assortment/echtholzboden.jpg?width=480&height=320&format=webp';
export const assortment4_5_card = {
	xs: {src: assortment4_5_card_xs_src, id: 'assortment4_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_5_card_sm_src, id: 'assortment4_5_card_sm', width: '480', height: '320'},
};
import assortment4_6_card_xs_src from 'images/cards/assortment/bioboden.jpg?width=284&height=189&format=webp';
import assortment4_6_card_sm_src from 'images/cards/assortment/bioboden.jpg?width=480&height=320&format=webp';
export const assortment4_6_card = {
	xs: {src: assortment4_6_card_xs_src, id: 'assortment4_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_6_card_sm_src, id: 'assortment4_6_card_sm', width: '480', height: '320'},
};
import assortment4_7_card_xs_src from 'images/cards/assortment/korkboden.jpg?width=284&height=189&format=webp';
import assortment4_7_card_sm_src from 'images/cards/assortment/korkboden.jpg?width=480&height=320&format=webp';
export const assortment4_7_card = {
	xs: {src: assortment4_7_card_xs_src, id: 'assortment4_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_7_card_sm_src, id: 'assortment4_7_card_sm', width: '480', height: '320'},
};
import assortment4_8_card_xs_src from 'images/cards/assortment/wand-decke.jpg?width=284&height=189&format=webp';
import assortment4_8_card_sm_src from 'images/cards/assortment/wand-decke.jpg?width=480&height=320&format=webp';
export const assortment4_8_card = {
	xs: {src: assortment4_8_card_xs_src, id: 'assortment4_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_8_card_sm_src, id: 'assortment4_8_card_sm', width: '480', height: '320'},
};
import assortment4_9_card_xs_src from 'images/cards/assortment/treppensanierung.jpg?width=284&height=189&format=webp';
import assortment4_9_card_sm_src from 'images/cards/assortment/treppensanierung.jpg?width=480&height=320&format=webp';
export const assortment4_9_card = {
	xs: {src: assortment4_9_card_xs_src, id: 'assortment4_9_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_9_card_sm_src, id: 'assortment4_9_card_sm', width: '480', height: '320'},
};
import assortment4_10_card_xs_src from 'images/cards/assortment/boden-zubehör.jpg?width=284&height=189&format=webp';
import assortment4_10_card_sm_src from 'images/cards/assortment/boden-zubehör.jpg?width=480&height=320&format=webp';
export const assortment4_10_card = {
	xs: {src: assortment4_10_card_xs_src, id: 'assortment4_10_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_10_card_sm_src, id: 'assortment4_10_card_sm', width: '480', height: '320'},
};
import assortment4_4_1_card_xs_src from 'images/cards/assortment/fertigparkett.jpg?width=284&height=189&format=webp';
import assortment4_4_1_card_sm_src from 'images/cards/assortment/fertigparkett.jpg?width=480&height=320&format=webp';
export const assortment4_4_1_card = {
	xs: {src: assortment4_4_1_card_xs_src, id: 'assortment4_4_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_4_1_card_sm_src, id: 'assortment4_4_1_card_sm', width: '480', height: '320'},
};
import assortment4_4_2_card_xs_src from 'images/cards/assortment/echtholzparkett.jpg?width=284&height=189&format=webp';
import assortment4_4_2_card_sm_src from 'images/cards/assortment/echtholzparkett.jpg?width=480&height=320&format=webp';
export const assortment4_4_2_card = {
	xs: {src: assortment4_4_2_card_xs_src, id: 'assortment4_4_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_4_2_card_sm_src, id: 'assortment4_4_2_card_sm', width: '480', height: '320'},
};
import assortment4_4_3_card_xs_src from 'images/cards/assortment/massivholzdielen.jpg?width=284&height=189&format=webp';
import assortment4_4_3_card_sm_src from 'images/cards/assortment/massivholzdielen.jpg?width=480&height=320&format=webp';
export const assortment4_4_3_card = {
	xs: {src: assortment4_4_3_card_xs_src, id: 'assortment4_4_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_4_3_card_sm_src, id: 'assortment4_4_3_card_sm', width: '480', height: '320'},
};
import assortment4_4_4_card_xs_src from 'images/cards/assortment/hybride-holzböden.jpg?width=284&height=189&format=webp';
import assortment4_4_4_card_sm_src from 'images/cards/assortment/hybride-holzböden.jpg?width=480&height=320&format=webp';
export const assortment4_4_4_card = {
	xs: {src: assortment4_4_4_card_xs_src, id: 'assortment4_4_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_4_4_card_sm_src, id: 'assortment4_4_4_card_sm', width: '480', height: '320'},
};
import assortment4_8_1_card_xs_src from 'images/cards/assortment/dekorpaneele.jpg?width=284&height=189&format=webp';
import assortment4_8_1_card_sm_src from 'images/cards/assortment/dekorpaneele.jpg?width=480&height=320&format=webp';
export const assortment4_8_1_card = {
	xs: {src: assortment4_8_1_card_xs_src, id: 'assortment4_8_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_8_1_card_sm_src, id: 'assortment4_8_1_card_sm', width: '480', height: '320'},
};
import assortment4_8_2_retail_card_xs_src from 'images/cards/assortment/holzpaneele.jpg?width=284&height=189&format=webp';
import assortment4_8_2_retail_card_sm_src from 'images/cards/assortment/holzpaneele.jpg?width=480&height=320&format=webp';
export const assortment4_8_2_retail_card = {
	xs: {src: assortment4_8_2_retail_card_xs_src, id: 'assortment4_8_2_retail_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_8_2_retail_card_sm_src, id: 'assortment4_8_2_retail_card_sm', width: '480', height: '320'},
};
import assortment4_8_2_wholesale_card_xs_src from 'images/cards/assortment/akustikpaneele.jpg?width=284&height=189&format=webp';
import assortment4_8_2_wholesale_card_sm_src from 'images/cards/assortment/akustikpaneele.jpg?width=480&height=320&format=webp';
export const assortment4_8_2_wholesale_card = {
	xs: {src: assortment4_8_2_wholesale_card_xs_src, id: 'assortment4_8_2_wholesale_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_8_2_wholesale_card_sm_src, id: 'assortment4_8_2_wholesale_card_sm', width: '480', height: '320'},
};
import assortment4_8_3_card_xs_src from 'images/cards/assortment/profilbretter.jpg?width=284&height=189&format=webp';
import assortment4_8_3_card_sm_src from 'images/cards/assortment/profilbretter.jpg?width=480&height=320&format=webp';
export const assortment4_8_3_card = {
	xs: {src: assortment4_8_3_card_xs_src, id: 'assortment4_8_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_8_3_card_sm_src, id: 'assortment4_8_3_card_sm', width: '480', height: '320'},
};
import assortment4_8_4_card_xs_src from 'images/cards/assortment/chalet-style.jpg?width=284&height=189&format=webp';
import assortment4_8_4_card_sm_src from 'images/cards/assortment/chalet-style.jpg?width=480&height=320&format=webp';
export const assortment4_8_4_card = {
	xs: {src: assortment4_8_4_card_xs_src, id: 'assortment4_8_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_8_4_card_sm_src, id: 'assortment4_8_4_card_sm', width: '480', height: '320'},
};
import assortment4_8_5_card_xs_src from 'images/cards/assortment/wand-decke-zubehör.jpg?width=284&height=189&format=webp';
import assortment4_8_5_card_sm_src from 'images/cards/assortment/wand-decke-zubehör.jpg?width=480&height=320&format=webp';
export const assortment4_8_5_card = {
	xs: {src: assortment4_8_5_card_xs_src, id: 'assortment4_8_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment4_8_5_card_sm_src, id: 'assortment4_8_5_card_sm', width: '480', height: '320'},
};
import assortment5_1_card_xs_src from 'images/cards/assortment/hq-gartenwelt.png?width=284&height=189&format=webp';
import assortment5_1_card_sm_src from 'images/cards/assortment/hq-gartenwelt.png?width=480&height=320&format=webp';
export const assortment5_1_card = {
	xs: {src: assortment5_1_card_xs_src, id: 'assortment5_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_1_card_sm_src, id: 'assortment5_1_card_sm', width: '480', height: '320'},
};
import assortment5_2_card_xs_src from 'images/cards/assortment/terrasse.jpg?width=284&height=189&format=webp';
import assortment5_2_card_sm_src from 'images/cards/assortment/terrasse.jpg?width=480&height=320&format=webp';
export const assortment5_2_card = {
	xs: {src: assortment5_2_card_xs_src, id: 'assortment5_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_card_sm_src, id: 'assortment5_2_card_sm', width: '480', height: '320'},
};
import assortment5_3_card_xs_src from 'images/cards/assortment/zäune-sichtschutz.jpg?width=284&height=189&format=webp';
import assortment5_3_card_sm_src from 'images/cards/assortment/zäune-sichtschutz.jpg?width=480&height=320&format=webp';
export const assortment5_3_card = {
	xs: {src: assortment5_3_card_xs_src, id: 'assortment5_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_card_sm_src, id: 'assortment5_3_card_sm', width: '480', height: '320'},
};
import assortment5_4_card_xs_src from 'images/cards/assortment/gartenhäuser-carports.jpg?width=284&height=189&format=webp';
import assortment5_4_card_sm_src from 'images/cards/assortment/gartenhäuser-carports.jpg?width=480&height=320&format=webp';
export const assortment5_4_card = {
	xs: {src: assortment5_4_card_xs_src, id: 'assortment5_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_4_card_sm_src, id: 'assortment5_4_card_sm', width: '480', height: '320'},
};
import assortment5_5_card_xs_src from 'images/cards/assortment/gartenmöbel.jpg?width=284&height=189&format=webp';
import assortment5_5_card_sm_src from 'images/cards/assortment/gartenmöbel.jpg?width=480&height=320&format=webp';
export const assortment5_5_card = {
	xs: {src: assortment5_5_card_xs_src, id: 'assortment5_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_card_sm_src, id: 'assortment5_5_card_sm', width: '480', height: '320'},
};
import assortment5_6_card_xs_src from 'images/cards/assortment/gartenspielgeräte.jpg?width=284&height=189&format=webp';
import assortment5_6_card_sm_src from 'images/cards/assortment/gartenspielgeräte.jpg?width=480&height=320&format=webp';
export const assortment5_6_card = {
	xs: {src: assortment5_6_card_xs_src, id: 'assortment5_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_6_card_sm_src, id: 'assortment5_6_card_sm', width: '480', height: '320'},
};
import assortment5_7_card_xs_src from 'images/cards/assortment/holzanstriche-pflege.jpg?width=284&height=189&format=webp';
import assortment5_7_card_sm_src from 'images/cards/assortment/holzanstriche-pflege.jpg?width=480&height=320&format=webp';
export const assortment5_7_card = {
	xs: {src: assortment5_7_card_xs_src, id: 'assortment5_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_card_sm_src, id: 'assortment5_7_card_sm', width: '480', height: '320'},
};
import assortment5_8_card_xs_src from 'images/cards/assortment/balkone-handläufe.jpg?width=284&height=189&format=webp';
import assortment5_8_card_sm_src from 'images/cards/assortment/balkone-handläufe.jpg?width=480&height=320&format=webp';
export const assortment5_8_card = {
	xs: {src: assortment5_8_card_xs_src, id: 'assortment5_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_8_card_sm_src, id: 'assortment5_8_card_sm', width: '480', height: '320'},
};
import assortment5_2_1_card_xs_src from 'images/cards/assortment/terrasse-holz.jpg?width=284&height=189&format=webp';
import assortment5_2_1_card_sm_src from 'images/cards/assortment/terrasse-holz.jpg?width=480&height=320&format=webp';
export const assortment5_2_1_card = {
	xs: {src: assortment5_2_1_card_xs_src, id: 'assortment5_2_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_1_card_sm_src, id: 'assortment5_2_1_card_sm', width: '480', height: '320'},
};
import assortment5_2_2_card_xs_src from 'images/cards/assortment/terrasse-wpc.jpg?width=284&height=189&format=webp';
import assortment5_2_2_card_sm_src from 'images/cards/assortment/terrasse-wpc.jpg?width=480&height=320&format=webp';
export const assortment5_2_2_card = {
	xs: {src: assortment5_2_2_card_xs_src, id: 'assortment5_2_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_2_card_sm_src, id: 'assortment5_2_2_card_sm', width: '480', height: '320'},
};
import assortment5_2_3_card_xs_src from 'images/cards/assortment/terrasse-bpc.jpg?width=284&height=189&format=webp';
import assortment5_2_3_card_sm_src from 'images/cards/assortment/terrasse-bpc.jpg?width=480&height=320&format=webp';
export const assortment5_2_3_card = {
	xs: {src: assortment5_2_3_card_xs_src, id: 'assortment5_2_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_3_card_sm_src, id: 'assortment5_2_3_card_sm', width: '480', height: '320'},
};
import assortment5_2_4_card_xs_src from 'images/cards/assortment/terrasse-hpl.jpg?width=284&height=189&format=webp';
import assortment5_2_4_card_sm_src from 'images/cards/assortment/terrasse-hpl.jpg?width=480&height=320&format=webp';
export const assortment5_2_4_card = {
	xs: {src: assortment5_2_4_card_xs_src, id: 'assortment5_2_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_4_card_sm_src, id: 'assortment5_2_4_card_sm', width: '480', height: '320'},
};
import assortment5_2_5_card_xs_src from 'images/cards/assortment/terrassendielen.jpg?width=284&height=189&format=webp';
import assortment5_2_5_card_sm_src from 'images/cards/assortment/terrassendielen.jpg?width=480&height=320&format=webp';
export const assortment5_2_5_card = {
	xs: {src: assortment5_2_5_card_xs_src, id: 'assortment5_2_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_5_card_sm_src, id: 'assortment5_2_5_card_sm', width: '480', height: '320'},
};
import assortment5_2_6_card_xs_src from 'images/cards/assortment/terrasse-unterkonstruktion.jpg?width=284&height=189&format=webp';
import assortment5_2_6_card_sm_src from 'images/cards/assortment/terrasse-unterkonstruktion.jpg?width=480&height=320&format=webp';
export const assortment5_2_6_card = {
	xs: {src: assortment5_2_6_card_xs_src, id: 'assortment5_2_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_6_card_sm_src, id: 'assortment5_2_6_card_sm', width: '480', height: '320'},
};
import assortment5_2_7_card_xs_src from 'images/cards/assortment/terrassenaufbau.jpg?width=284&height=189&format=webp';
import assortment5_2_7_card_sm_src from 'images/cards/assortment/terrassenaufbau.jpg?width=480&height=320&format=webp';
export const assortment5_2_7_card = {
	xs: {src: assortment5_2_7_card_xs_src, id: 'assortment5_2_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_7_card_sm_src, id: 'assortment5_2_7_card_sm', width: '480', height: '320'},
};
import assortment5_2_8_card_xs_src from 'images/cards/assortment/terrassebefestigung.jpg?width=284&height=189&format=webp';
import assortment5_2_8_card_sm_src from 'images/cards/assortment/terrassebefestigung.jpg?width=480&height=320&format=webp';
export const assortment5_2_8_card = {
	xs: {src: assortment5_2_8_card_xs_src, id: 'assortment5_2_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_8_card_sm_src, id: 'assortment5_2_8_card_sm', width: '480', height: '320'},
};
import assortment5_2_9_card_xs_src from 'images/cards/assortment/terrassenbeleuchtung.jpg?width=284&height=189&format=webp';
import assortment5_2_9_card_sm_src from 'images/cards/assortment/terrassenbeleuchtung.jpg?width=480&height=320&format=webp';
export const assortment5_2_9_card = {
	xs: {src: assortment5_2_9_card_xs_src, id: 'assortment5_2_9_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_9_card_sm_src, id: 'assortment5_2_9_card_sm', width: '480', height: '320'},
};
import assortment5_2_10_card_xs_src from 'images/cards/assortment/terrassenpflege.jpg?width=284&height=189&format=webp';
import assortment5_2_10_card_sm_src from 'images/cards/assortment/terrassenpflege.jpg?width=480&height=320&format=webp';
export const assortment5_2_10_card = {
	xs: {src: assortment5_2_10_card_xs_src, id: 'assortment5_2_10_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_10_card_sm_src, id: 'assortment5_2_10_card_sm', width: '480', height: '320'},
};
import assortment5_2_11_card_xs_src from 'images/cards/assortment/terrassenschraube.jpg?width=284&height=189&format=webp';
import assortment5_2_11_card_sm_src from 'images/cards/assortment/terrassenschraube.jpg?width=480&height=320&format=webp';
export const assortment5_2_11_card = {
	xs: {src: assortment5_2_11_card_xs_src, id: 'assortment5_2_11_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_2_11_card_sm_src, id: 'assortment5_2_11_card_sm', width: '480', height: '320'},
};
import assortment5_3_1_card_xs_src from 'images/cards/assortment/gartenzäune.jpg?width=284&height=189&format=webp';
import assortment5_3_1_card_sm_src from 'images/cards/assortment/gartenzäune.jpg?width=480&height=320&format=webp';
export const assortment5_3_1_card = {
	xs: {src: assortment5_3_1_card_xs_src, id: 'assortment5_3_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_1_card_sm_src, id: 'assortment5_3_1_card_sm', width: '480', height: '320'},
};
import assortment5_3_2_card_xs_src from 'images/cards/assortment/sichtschutz-holz.jpg?width=284&height=189&format=webp';
import assortment5_3_2_card_sm_src from 'images/cards/assortment/sichtschutz-holz.jpg?width=480&height=320&format=webp';
export const assortment5_3_2_card = {
	xs: {src: assortment5_3_2_card_xs_src, id: 'assortment5_3_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_2_card_sm_src, id: 'assortment5_3_2_card_sm', width: '480', height: '320'},
};
import assortment5_3_3_card_xs_src from 'images/cards/assortment/sichtschutz-wpc-bpc.jpg?width=284&height=189&format=webp';
import assortment5_3_3_card_sm_src from 'images/cards/assortment/sichtschutz-wpc-bpc.jpg?width=480&height=320&format=webp';
export const assortment5_3_3_card = {
	xs: {src: assortment5_3_3_card_xs_src, id: 'assortment5_3_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_3_card_sm_src, id: 'assortment5_3_3_card_sm', width: '480', height: '320'},
};
import assortment5_3_4_card_xs_src from 'images/cards/assortment/sichtschutz-hpl.jpg?width=284&height=189&format=webp';
import assortment5_3_4_card_sm_src from 'images/cards/assortment/sichtschutz-hpl.jpg?width=480&height=320&format=webp';
export const assortment5_3_4_card = {
	xs: {src: assortment5_3_4_card_xs_src, id: 'assortment5_3_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_4_card_sm_src, id: 'assortment5_3_4_card_sm', width: '480', height: '320'},
};
import assortment5_3_5_card_xs_src from 'images/cards/assortment/sichtschutz-metall.jpg?width=284&height=189&format=webp';
import assortment5_3_5_card_sm_src from 'images/cards/assortment/sichtschutz-metall.jpg?width=480&height=320&format=webp';
export const assortment5_3_5_card = {
	xs: {src: assortment5_3_5_card_xs_src, id: 'assortment5_3_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_5_card_sm_src, id: 'assortment5_3_5_card_sm', width: '480', height: '320'},
};
import assortment5_3_6_card_xs_src from 'images/cards/assortment/sichtschutz-glas.jpg?width=284&height=189&format=webp';
import assortment5_3_6_card_sm_src from 'images/cards/assortment/sichtschutz-glas.jpg?width=480&height=320&format=webp';
export const assortment5_3_6_card = {
	xs: {src: assortment5_3_6_card_xs_src, id: 'assortment5_3_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_6_card_sm_src, id: 'assortment5_3_6_card_sm', width: '480', height: '320'},
};
import assortment5_3_7_card_xs_src from 'images/cards/assortment/sichtschutz-kunststoff.jpg?width=284&height=189&format=webp';
import assortment5_3_7_card_sm_src from 'images/cards/assortment/sichtschutz-kunststoff.jpg?width=480&height=320&format=webp';
export const assortment5_3_7_card = {
	xs: {src: assortment5_3_7_card_xs_src, id: 'assortment5_3_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_3_7_card_sm_src, id: 'assortment5_3_7_card_sm', width: '480', height: '320'},
};
import assortment5_4_1_card_xs_src from 'images/cards/assortment/gartenhäuser.jpg?width=284&height=189&format=webp';
import assortment5_4_1_card_sm_src from 'images/cards/assortment/gartenhäuser.jpg?width=480&height=320&format=webp';
export const assortment5_4_1_card = {
	xs: {src: assortment5_4_1_card_xs_src, id: 'assortment5_4_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_4_1_card_sm_src, id: 'assortment5_4_1_card_sm', width: '480', height: '320'},
};
import assortment5_4_2_card_xs_src from 'images/cards/assortment/carports.jpg?width=284&height=189&format=webp';
import assortment5_4_2_card_sm_src from 'images/cards/assortment/carports.jpg?width=480&height=320&format=webp';
export const assortment5_4_2_card = {
	xs: {src: assortment5_4_2_card_xs_src, id: 'assortment5_4_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_4_2_card_sm_src, id: 'assortment5_4_2_card_sm', width: '480', height: '320'},
};
import assortment5_5_1_card_xs_src from 'images/cards/assortment/gartentische.jpg?width=284&height=189&format=webp';
import assortment5_5_1_card_sm_src from 'images/cards/assortment/gartentische.jpg?width=480&height=320&format=webp';
export const assortment5_5_1_card = {
	xs: {src: assortment5_5_1_card_xs_src, id: 'assortment5_5_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_1_card_sm_src, id: 'assortment5_5_1_card_sm', width: '480', height: '320'},
};
import assortment5_5_2_card_xs_src from 'images/cards/assortment/gartenliegen.jpg?width=284&height=189&format=webp';
import assortment5_5_2_card_sm_src from 'images/cards/assortment/gartenliegen.jpg?width=480&height=320&format=webp';
export const assortment5_5_2_card = {
	xs: {src: assortment5_5_2_card_xs_src, id: 'assortment5_5_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_2_card_sm_src, id: 'assortment5_5_2_card_sm', width: '480', height: '320'},
};
import assortment5_5_3_card_xs_src from 'images/cards/assortment/gartenstühle.jpg?width=284&height=189&format=webp';
import assortment5_5_3_card_sm_src from 'images/cards/assortment/gartenstühle.jpg?width=480&height=320&format=webp';
export const assortment5_5_3_card = {
	xs: {src: assortment5_5_3_card_xs_src, id: 'assortment5_5_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_3_card_sm_src, id: 'assortment5_5_3_card_sm', width: '480', height: '320'},
};
import assortment5_5_4_card_xs_src from 'images/cards/assortment/gartenbänke.jpg?width=284&height=189&format=webp';
import assortment5_5_4_card_sm_src from 'images/cards/assortment/gartenbänke.jpg?width=480&height=320&format=webp';
export const assortment5_5_4_card = {
	xs: {src: assortment5_5_4_card_xs_src, id: 'assortment5_5_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_4_card_sm_src, id: 'assortment5_5_4_card_sm', width: '480', height: '320'},
};
import assortment5_5_5_card_xs_src from 'images/cards/assortment/sonnenschirme.jpg?width=284&height=189&format=webp';
import assortment5_5_5_card_sm_src from 'images/cards/assortment/sonnenschirme.jpg?width=480&height=320&format=webp';
export const assortment5_5_5_card = {
	xs: {src: assortment5_5_5_card_xs_src, id: 'assortment5_5_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_5_card_sm_src, id: 'assortment5_5_5_card_sm', width: '480', height: '320'},
};
import assortment5_5_6_card_xs_src from 'images/cards/assortment/loungemöbel.jpg?width=284&height=189&format=webp';
import assortment5_5_6_card_sm_src from 'images/cards/assortment/loungemöbel.jpg?width=480&height=320&format=webp';
export const assortment5_5_6_card = {
	xs: {src: assortment5_5_6_card_xs_src, id: 'assortment5_5_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_6_card_sm_src, id: 'assortment5_5_6_card_sm', width: '480', height: '320'},
};
import assortment5_5_7_card_xs_src from 'images/cards/assortment/gartenmöbelsets.jpg?width=284&height=189&format=webp';
import assortment5_5_7_card_sm_src from 'images/cards/assortment/gartenmöbelsets.jpg?width=480&height=320&format=webp';
export const assortment5_5_7_card = {
	xs: {src: assortment5_5_7_card_xs_src, id: 'assortment5_5_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_7_card_sm_src, id: 'assortment5_5_7_card_sm', width: '480', height: '320'},
};
import assortment5_5_8_card_xs_src from 'images/cards/assortment/gartenmöbel-zubehör.jpg?width=284&height=189&format=webp';
import assortment5_5_8_card_sm_src from 'images/cards/assortment/gartenmöbel-zubehör.jpg?width=480&height=320&format=webp';
export const assortment5_5_8_card = {
	xs: {src: assortment5_5_8_card_xs_src, id: 'assortment5_5_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_5_8_card_sm_src, id: 'assortment5_5_8_card_sm', width: '480', height: '320'},
};
import assortment5_6_1_card_xs_src from 'images/cards/assortment/sandkästen.jpg?width=284&height=189&format=webp';
import assortment5_6_1_card_sm_src from 'images/cards/assortment/sandkästen.jpg?width=480&height=320&format=webp';
export const assortment5_6_1_card = {
	xs: {src: assortment5_6_1_card_xs_src, id: 'assortment5_6_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_6_1_card_sm_src, id: 'assortment5_6_1_card_sm', width: '480', height: '320'},
};
import assortment5_6_2_card_xs_src from 'images/cards/assortment/schaukeln.jpg?width=284&height=189&format=webp';
import assortment5_6_2_card_sm_src from 'images/cards/assortment/schaukeln.jpg?width=480&height=320&format=webp';
export const assortment5_6_2_card = {
	xs: {src: assortment5_6_2_card_xs_src, id: 'assortment5_6_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_6_2_card_sm_src, id: 'assortment5_6_2_card_sm', width: '480', height: '320'},
};
import assortment5_6_3_card_xs_src from 'images/cards/assortment/spieltürme.jpg?width=284&height=189&format=webp';
import assortment5_6_3_card_sm_src from 'images/cards/assortment/spieltürme.jpg?width=480&height=320&format=webp';
export const assortment5_6_3_card = {
	xs: {src: assortment5_6_3_card_xs_src, id: 'assortment5_6_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_6_3_card_sm_src, id: 'assortment5_6_3_card_sm', width: '480', height: '320'},
};
import assortment5_6_4_card_xs_src from 'images/cards/assortment/spielhäuser.jpg?width=284&height=189&format=webp';
import assortment5_6_4_card_sm_src from 'images/cards/assortment/spielhäuser.jpg?width=480&height=320&format=webp';
export const assortment5_6_4_card = {
	xs: {src: assortment5_6_4_card_xs_src, id: 'assortment5_6_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_6_4_card_sm_src, id: 'assortment5_6_4_card_sm', width: '480', height: '320'},
};
import assortment5_6_5_card_xs_src from 'images/cards/assortment/spielgeräte-zubehör.jpg?width=284&height=189&format=webp';
import assortment5_6_5_card_sm_src from 'images/cards/assortment/spielgeräte-zubehör.jpg?width=480&height=320&format=webp';
export const assortment5_6_5_card = {
	xs: {src: assortment5_6_5_card_xs_src, id: 'assortment5_6_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_6_5_card_sm_src, id: 'assortment5_6_5_card_sm', width: '480', height: '320'},
};
import assortment5_7_1_card_xs_src from 'images/cards/assortment/grundierung.jpg?width=284&height=189&format=webp';
import assortment5_7_1_card_sm_src from 'images/cards/assortment/grundierung.jpg?width=480&height=320&format=webp';
export const assortment5_7_1_card = {
	xs: {src: assortment5_7_1_card_xs_src, id: 'assortment5_7_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_1_card_sm_src, id: 'assortment5_7_1_card_sm', width: '480', height: '320'},
};
import assortment5_7_2_card_xs_src from 'images/cards/assortment/farben.jpg?width=284&height=189&format=webp';
import assortment5_7_2_card_sm_src from 'images/cards/assortment/farben.jpg?width=480&height=320&format=webp';
export const assortment5_7_2_card = {
	xs: {src: assortment5_7_2_card_xs_src, id: 'assortment5_7_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_2_card_sm_src, id: 'assortment5_7_2_card_sm', width: '480', height: '320'},
};
import assortment5_7_3_card_xs_src from 'images/cards/assortment/lacke.jpg?width=284&height=189&format=webp';
import assortment5_7_3_card_sm_src from 'images/cards/assortment/lacke.jpg?width=480&height=320&format=webp';
export const assortment5_7_3_card = {
	xs: {src: assortment5_7_3_card_xs_src, id: 'assortment5_7_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_3_card_sm_src, id: 'assortment5_7_3_card_sm', width: '480', height: '320'},
};
import assortment5_7_4_card_xs_src from 'images/cards/assortment/lasuren.jpg?width=284&height=189&format=webp';
import assortment5_7_4_card_sm_src from 'images/cards/assortment/lasuren.jpg?width=480&height=320&format=webp';
export const assortment5_7_4_card = {
	xs: {src: assortment5_7_4_card_xs_src, id: 'assortment5_7_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_4_card_sm_src, id: 'assortment5_7_4_card_sm', width: '480', height: '320'},
};
import assortment5_7_5_card_xs_src from 'images/cards/assortment/pflegemittel.jpg?width=284&height=189&format=webp';
import assortment5_7_5_card_sm_src from 'images/cards/assortment/pflegemittel.jpg?width=480&height=320&format=webp';
export const assortment5_7_5_card = {
	xs: {src: assortment5_7_5_card_xs_src, id: 'assortment5_7_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_5_card_sm_src, id: 'assortment5_7_5_card_sm', width: '480', height: '320'},
};
import assortment5_7_6_card_xs_src from 'images/cards/assortment/wachse-öle.jpg?width=284&height=189&format=webp';
import assortment5_7_6_card_sm_src from 'images/cards/assortment/wachse-öle.jpg?width=480&height=320&format=webp';
export const assortment5_7_6_card = {
	xs: {src: assortment5_7_6_card_xs_src, id: 'assortment5_7_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_6_card_sm_src, id: 'assortment5_7_6_card_sm', width: '480', height: '320'},
};
import assortment5_7_7_card_xs_src from 'images/cards/assortment/farben-lacke-zubehör.jpg?width=284&height=189&format=webp';
import assortment5_7_7_card_sm_src from 'images/cards/assortment/farben-lacke-zubehör.jpg?width=480&height=320&format=webp';
export const assortment5_7_7_card = {
	xs: {src: assortment5_7_7_card_xs_src, id: 'assortment5_7_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment5_7_7_card_sm_src, id: 'assortment5_7_7_card_sm', width: '480', height: '320'},
};
import assortment6_1_card_xs_src from 'images/cards/assortment/hq-türenwelt.png?width=284&height=189&format=webp';
import assortment6_1_card_sm_src from 'images/cards/assortment/hq-türenwelt.png?width=480&height=320&format=webp';
export const assortment6_1_card = {
	xs: {src: assortment6_1_card_xs_src, id: 'assortment6_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_1_card_sm_src, id: 'assortment6_1_card_sm', width: '480', height: '320'},
};
import assortment6_2_card_xs_src from 'images/cards/assortment/innentüren.jpg?width=284&height=189&format=webp';
import assortment6_2_card_sm_src from 'images/cards/assortment/innentüren.jpg?width=480&height=320&format=webp';
export const assortment6_2_card = {
	xs: {src: assortment6_2_card_xs_src, id: 'assortment6_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_2_card_sm_src, id: 'assortment6_2_card_sm', width: '480', height: '320'},
};
import assortment6_3_card_xs_src from 'images/cards/assortment/eingangstüren.jpg?width=284&height=189&format=webp';
import assortment6_3_card_sm_src from 'images/cards/assortment/eingangstüren.jpg?width=480&height=320&format=webp';
export const assortment6_3_card = {
	xs: {src: assortment6_3_card_xs_src, id: 'assortment6_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_3_card_sm_src, id: 'assortment6_3_card_sm', width: '480', height: '320'},
};
import assortment6_4_card_xs_src from 'images/cards/assortment/drückergarnituren.jpg?width=284&height=189&format=webp';
import assortment6_4_card_sm_src from 'images/cards/assortment/drückergarnituren.jpg?width=480&height=320&format=webp';
export const assortment6_4_card = {
	xs: {src: assortment6_4_card_xs_src, id: 'assortment6_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_4_card_sm_src, id: 'assortment6_4_card_sm', width: '480', height: '320'},
};
import assortment6_5_card_xs_src from 'images/cards/assortment/türverglasung.jpg?width=284&height=189&format=webp';
import assortment6_5_card_sm_src from 'images/cards/assortment/türverglasung.jpg?width=480&height=320&format=webp';
export const assortment6_5_card = {
	xs: {src: assortment6_5_card_xs_src, id: 'assortment6_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_5_card_sm_src, id: 'assortment6_5_card_sm', width: '480', height: '320'},
};
import assortment6_6_card_xs_src from 'images/cards/assortment/tor-center.jpg?width=284&height=189&format=webp';
import assortment6_6_card_sm_src from 'images/cards/assortment/tor-center.jpg?width=480&height=320&format=webp';
export const assortment6_6_card = {
	xs: {src: assortment6_6_card_xs_src, id: 'assortment6_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_6_card_sm_src, id: 'assortment6_6_card_sm', width: '480', height: '320'},
};
import assortment6_7_card_xs_src from 'images/cards/assortment/fensterbänke.jpg?width=284&height=189&format=webp';
import assortment6_7_card_sm_src from 'images/cards/assortment/fensterbänke.jpg?width=480&height=320&format=webp';
export const assortment6_7_card = {
	xs: {src: assortment6_7_card_xs_src, id: 'assortment6_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_7_card_sm_src, id: 'assortment6_7_card_sm', width: '480', height: '320'},
};
import assortment6_9_card_xs_src from 'images/cards/assortment/handlaufsysteme.jpg?width=284&height=189&format=webp';
import assortment6_9_card_sm_src from 'images/cards/assortment/handlaufsysteme.jpg?width=480&height=320&format=webp';
export const assortment6_9_card = {
	xs: {src: assortment6_9_card_xs_src, id: 'assortment6_9_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_9_card_sm_src, id: 'assortment6_9_card_sm', width: '480', height: '320'},
};
import assortment6_10_card_xs_src from 'images/cards/assortment/tür-zubehör.jpg?width=284&height=189&format=webp';
import assortment6_10_card_sm_src from 'images/cards/assortment/tür-zubehör.jpg?width=480&height=320&format=webp';
export const assortment6_10_card = {
	xs: {src: assortment6_10_card_xs_src, id: 'assortment6_10_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_10_card_sm_src, id: 'assortment6_10_card_sm', width: '480', height: '320'},
};
import assortment6_2_1_card_xs_src from 'images/cards/assortment/zimmertüren.jpg?width=284&height=189&format=webp';
import assortment6_2_1_card_sm_src from 'images/cards/assortment/zimmertüren.jpg?width=480&height=320&format=webp';
export const assortment6_2_1_card = {
	xs: {src: assortment6_2_1_card_xs_src, id: 'assortment6_2_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_2_1_card_sm_src, id: 'assortment6_2_1_card_sm', width: '480', height: '320'},
};
import assortment6_2_2_card_xs_src from 'images/cards/assortment/schiebetüren.jpg?width=284&height=189&format=webp';
import assortment6_2_2_card_sm_src from 'images/cards/assortment/schiebetüren.jpg?width=480&height=320&format=webp';
export const assortment6_2_2_card = {
	xs: {src: assortment6_2_2_card_xs_src, id: 'assortment6_2_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_2_2_card_sm_src, id: 'assortment6_2_2_card_sm', width: '480', height: '320'},
};
import assortment6_2_3_card_xs_src from 'images/cards/assortment/glastüren.jpg?width=284&height=189&format=webp';
import assortment6_2_3_card_sm_src from 'images/cards/assortment/glastüren.jpg?width=480&height=320&format=webp';
export const assortment6_2_3_card = {
	xs: {src: assortment6_2_3_card_xs_src, id: 'assortment6_2_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_2_3_card_sm_src, id: 'assortment6_2_3_card_sm', width: '480', height: '320'},
};
import assortment6_2_4_card_xs_src from 'images/cards/assortment/schallschutztüren.jpg?width=284&height=189&format=webp';
import assortment6_2_4_card_sm_src from 'images/cards/assortment/schallschutztüren.jpg?width=480&height=320&format=webp';
export const assortment6_2_4_card = {
	xs: {src: assortment6_2_4_card_xs_src, id: 'assortment6_2_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_2_4_card_sm_src, id: 'assortment6_2_4_card_sm', width: '480', height: '320'},
};
import assortment6_2_5_card_xs_src from 'images/cards/assortment/stahlblechtüren.jpg?width=284&height=189&format=webp';
import assortment6_2_5_card_sm_src from 'images/cards/assortment/stahlblechtüren.jpg?width=480&height=320&format=webp';
export const assortment6_2_5_card = {
	xs: {src: assortment6_2_5_card_xs_src, id: 'assortment6_2_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_2_5_card_sm_src, id: 'assortment6_2_5_card_sm', width: '480', height: '320'},
};
import assortment6_2_6_card_xs_src from 'images/cards/assortment/türzargen.jpg?width=284&height=189&format=webp';
import assortment6_2_6_card_sm_src from 'images/cards/assortment/türzargen.jpg?width=480&height=320&format=webp';
export const assortment6_2_6_card = {
	xs: {src: assortment6_2_6_card_xs_src, id: 'assortment6_2_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_2_6_card_sm_src, id: 'assortment6_2_6_card_sm', width: '480', height: '320'},
};
import assortment6_3_1_card_xs_src from 'images/cards/assortment/wohnungseingangstüren.jpg?width=284&height=189&format=webp';
import assortment6_3_1_card_sm_src from 'images/cards/assortment/wohnungseingangstüren.jpg?width=480&height=320&format=webp';
export const assortment6_3_1_card = {
	xs: {src: assortment6_3_1_card_xs_src, id: 'assortment6_3_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_3_1_card_sm_src, id: 'assortment6_3_1_card_sm', width: '480', height: '320'},
};
import assortment6_3_2_card_xs_src from 'images/cards/assortment/nebeneingangstüren.jpg?width=284&height=189&format=webp';
import assortment6_3_2_card_sm_src from 'images/cards/assortment/nebeneingangstüren.jpg?width=480&height=320&format=webp';
export const assortment6_3_2_card = {
	xs: {src: assortment6_3_2_card_xs_src, id: 'assortment6_3_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_3_2_card_sm_src, id: 'assortment6_3_2_card_sm', width: '480', height: '320'},
};
import assortment6_4_1_card_xs_src from 'images/cards/assortment/innentürbeschläge.jpg?width=284&height=189&format=webp';
import assortment6_4_1_card_sm_src from 'images/cards/assortment/innentürbeschläge.jpg?width=480&height=320&format=webp';
export const assortment6_4_1_card = {
	xs: {src: assortment6_4_1_card_xs_src, id: 'assortment6_4_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_4_1_card_sm_src, id: 'assortment6_4_1_card_sm', width: '480', height: '320'},
};
import assortment6_4_2_card_xs_src from 'images/cards/assortment/schutzbeschläge.jpg?width=284&height=189&format=webp';
import assortment6_4_2_card_sm_src from 'images/cards/assortment/schutzbeschläge.jpg?width=480&height=320&format=webp';
export const assortment6_4_2_card = {
	xs: {src: assortment6_4_2_card_xs_src, id: 'assortment6_4_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_4_2_card_sm_src, id: 'assortment6_4_2_card_sm', width: '480', height: '320'},
};
import assortment6_4_3_card_xs_src from 'images/cards/assortment/objektbeschläge.jpg?width=284&height=189&format=webp';
import assortment6_4_3_card_sm_src from 'images/cards/assortment/objektbeschläge.jpg?width=480&height=320&format=webp';
export const assortment6_4_3_card = {
	xs: {src: assortment6_4_3_card_xs_src, id: 'assortment6_4_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_4_3_card_sm_src, id: 'assortment6_4_3_card_sm', width: '480', height: '320'},
};
import assortment6_6_1_card_xs_src from 'images/cards/assortment/türverglasung-standard.jpg?width=284&height=189&format=webp';
import assortment6_6_1_card_sm_src from 'images/cards/assortment/türverglasung-standard.jpg?width=480&height=320&format=webp';
export const assortment6_6_1_card = {
	xs: {src: assortment6_6_1_card_xs_src, id: 'assortment6_6_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_6_1_card_sm_src, id: 'assortment6_6_1_card_sm', width: '480', height: '320'},
};
import assortment6_6_2_card_xs_src from 'images/cards/assortment/türverglasung-esg.jpg?width=284&height=189&format=webp';
import assortment6_6_2_card_sm_src from 'images/cards/assortment/türverglasung-esg.jpg?width=480&height=320&format=webp';
export const assortment6_6_2_card = {
	xs: {src: assortment6_6_2_card_xs_src, id: 'assortment6_6_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment6_6_2_card_sm_src, id: 'assortment6_6_2_card_sm', width: '480', height: '320'},
};
import assortment7_1_card_xs_src from 'images/cards/assortment/innensauna.jpg?width=284&height=189&format=webp';
import assortment7_1_card_sm_src from 'images/cards/assortment/innensauna.jpg?width=480&height=320&format=webp';
export const assortment7_1_card = {
	xs: {src: assortment7_1_card_xs_src, id: 'assortment7_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_1_card_sm_src, id: 'assortment7_1_card_sm', width: '480', height: '320'},
};
import assortment7_2_card_xs_src from 'images/cards/assortment/aussensauna.jpg?width=284&height=189&format=webp';
import assortment7_2_card_sm_src from 'images/cards/assortment/aussensauna.jpg?width=480&height=320&format=webp';
export const assortment7_2_card = {
	xs: {src: assortment7_2_card_xs_src, id: 'assortment7_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_2_card_sm_src, id: 'assortment7_2_card_sm', width: '480', height: '320'},
};
import assortment7_3_card_xs_src from 'images/cards/assortment/sauna-zubehör.jpg?width=284&height=189&format=webp';
import assortment7_3_card_sm_src from 'images/cards/assortment/sauna-zubehör.jpg?width=480&height=320&format=webp';
export const assortment7_3_card = {
	xs: {src: assortment7_3_card_xs_src, id: 'assortment7_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_3_card_sm_src, id: 'assortment7_3_card_sm', width: '480', height: '320'},
};
import assortment7_1_1_card_xs_src from 'images/cards/assortment/massivholzsauna.jpg?width=284&height=189&format=webp';
import assortment7_1_1_card_sm_src from 'images/cards/assortment/massivholzsauna.jpg?width=480&height=320&format=webp';
export const assortment7_1_1_card = {
	xs: {src: assortment7_1_1_card_xs_src, id: 'assortment7_1_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_1_1_card_sm_src, id: 'assortment7_1_1_card_sm', width: '480', height: '320'},
};
import assortment7_1_2_card_xs_src from 'images/cards/assortment/systemsauna.jpg?width=284&height=189&format=webp';
import assortment7_1_2_card_sm_src from 'images/cards/assortment/systemsauna.jpg?width=480&height=320&format=webp';
export const assortment7_1_2_card = {
	xs: {src: assortment7_1_2_card_xs_src, id: 'assortment7_1_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_1_2_card_sm_src, id: 'assortment7_1_2_card_sm', width: '480', height: '320'},
};
import assortment7_1_3_card_xs_src from 'images/cards/assortment/saunaöfen.jpg?width=284&height=189&format=webp';
import assortment7_1_3_card_sm_src from 'images/cards/assortment/saunaöfen.jpg?width=480&height=320&format=webp';
export const assortment7_1_3_card = {
	xs: {src: assortment7_1_3_card_xs_src, id: 'assortment7_1_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_1_3_card_sm_src, id: 'assortment7_1_3_card_sm', width: '480', height: '320'},
};
import assortment7_2_1_card_xs_src from 'images/cards/assortment/saunahaus.jpg?width=284&height=189&format=webp';
import assortment7_2_1_card_sm_src from 'images/cards/assortment/saunahaus.jpg?width=480&height=320&format=webp';
export const assortment7_2_1_card = {
	xs: {src: assortment7_2_1_card_xs_src, id: 'assortment7_2_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_2_1_card_sm_src, id: 'assortment7_2_1_card_sm', width: '480', height: '320'},
};
import assortment7_2_2_card_xs_src from 'images/cards/assortment/fasssauna.jpg?width=284&height=189&format=webp';
import assortment7_2_2_card_sm_src from 'images/cards/assortment/fasssauna.jpg?width=480&height=320&format=webp';
export const assortment7_2_2_card = {
	xs: {src: assortment7_2_2_card_xs_src, id: 'assortment7_2_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_2_2_card_sm_src, id: 'assortment7_2_2_card_sm', width: '480', height: '320'},
};
import assortment7_2_3_card_xs_src from 'images/cards/assortment/saunaöfen.jpg?width=284&height=189&format=webp';
import assortment7_2_3_card_sm_src from 'images/cards/assortment/saunaöfen.jpg?width=480&height=320&format=webp';
export const assortment7_2_3_card = {
	xs: {src: assortment7_2_3_card_xs_src, id: 'assortment7_2_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment7_2_3_card_sm_src, id: 'assortment7_2_3_card_sm', width: '480', height: '320'},
};
import assortment8_1_card_xs_src from 'images/cards/assortment/elektrogrills.jpg?width=284&height=189&format=webp';
import assortment8_1_card_sm_src from 'images/cards/assortment/elektrogrills.jpg?width=480&height=320&format=webp';
export const assortment8_1_card = {
	xs: {src: assortment8_1_card_xs_src, id: 'assortment8_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment8_1_card_sm_src, id: 'assortment8_1_card_sm', width: '480', height: '320'},
};
import assortment8_2_card_xs_src from 'images/cards/assortment/gasgrills.jpg?width=284&height=189&format=webp';
import assortment8_2_card_sm_src from 'images/cards/assortment/gasgrills.jpg?width=480&height=320&format=webp';
export const assortment8_2_card = {
	xs: {src: assortment8_2_card_xs_src, id: 'assortment8_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment8_2_card_sm_src, id: 'assortment8_2_card_sm', width: '480', height: '320'},
};
import assortment8_3_card_xs_src from 'images/cards/assortment/holzkohlegrills.jpg?width=284&height=189&format=webp';
import assortment8_3_card_sm_src from 'images/cards/assortment/holzkohlegrills.jpg?width=480&height=320&format=webp';
export const assortment8_3_card = {
	xs: {src: assortment8_3_card_xs_src, id: 'assortment8_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment8_3_card_sm_src, id: 'assortment8_3_card_sm', width: '480', height: '320'},
};
import assortment8_4_card_xs_src from 'images/cards/assortment/pelletgrills.jpg?width=284&height=189&format=webp';
import assortment8_4_card_sm_src from 'images/cards/assortment/pelletgrills.jpg?width=480&height=320&format=webp';
export const assortment8_4_card = {
	xs: {src: assortment8_4_card_xs_src, id: 'assortment8_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment8_4_card_sm_src, id: 'assortment8_4_card_sm', width: '480', height: '320'},
};
import assortment8_5_card_xs_src from 'images/cards/assortment/grillzubehör.jpg?width=284&height=189&format=webp';
import assortment8_5_card_sm_src from 'images/cards/assortment/grillzubehör.jpg?width=480&height=320&format=webp';
export const assortment8_5_card = {
	xs: {src: assortment8_5_card_xs_src, id: 'assortment8_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment8_5_card_sm_src, id: 'assortment8_5_card_sm', width: '480', height: '320'},
};
import assortment10_1_1_card_xs_src from 'images/cards/factory/assortment/tischplatten.jpg?width=284&height=189&format=webp';
import assortment10_1_1_card_sm_src from 'images/cards/factory/assortment/tischplatten.jpg?width=480&height=320&format=webp';
export const assortment10_1_1_card = {
	xs: {src: assortment10_1_1_card_xs_src, id: 'assortment10_1_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_1_card_sm_src, id: 'assortment10_1_1_card_sm', width: '480', height: '320'},
};
import assortment10_1_2_card_xs_src from 'images/cards/factory/assortment/untergestelle.jpg?width=284&height=189&format=webp';
import assortment10_1_2_card_sm_src from 'images/cards/factory/assortment/untergestelle.jpg?width=480&height=320&format=webp';
export const assortment10_1_2_card = {
	xs: {src: assortment10_1_2_card_xs_src, id: 'assortment10_1_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_2_card_sm_src, id: 'assortment10_1_2_card_sm', width: '480', height: '320'},
};
import assortment10_1_3_card_xs_src from 'images/cards/factory/assortment/stühle.jpg?width=284&height=189&format=webp';
import assortment10_1_3_card_sm_src from 'images/cards/factory/assortment/stühle.jpg?width=480&height=320&format=webp';
export const assortment10_1_3_card = {
	xs: {src: assortment10_1_3_card_xs_src, id: 'assortment10_1_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_3_card_sm_src, id: 'assortment10_1_3_card_sm', width: '480', height: '320'},
};
import assortment10_1_4_card_xs_src from 'images/cards/factory/assortment/massivholzmöbel.jpg?width=284&height=189&format=webp';
import assortment10_1_4_card_sm_src from 'images/cards/factory/assortment/massivholzmöbel.jpg?width=480&height=320&format=webp';
export const assortment10_1_4_card = {
	xs: {src: assortment10_1_4_card_xs_src, id: 'assortment10_1_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_4_card_sm_src, id: 'assortment10_1_4_card_sm', width: '480', height: '320'},
};
import assortment10_1_5_card_xs_src from 'images/cards/factory/assortment/betten.jpg?width=284&height=189&format=webp';
import assortment10_1_5_card_sm_src from 'images/cards/factory/assortment/betten.jpg?width=480&height=320&format=webp';
export const assortment10_1_5_card = {
	xs: {src: assortment10_1_5_card_xs_src, id: 'assortment10_1_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_5_card_sm_src, id: 'assortment10_1_5_card_sm', width: '480', height: '320'},
};
import assortment10_1_6_card_xs_src from 'images/cards/factory/assortment/saunen.jpg?width=284&height=189&format=webp';
import assortment10_1_6_card_sm_src from 'images/cards/factory/assortment/saunen.jpg?width=480&height=320&format=webp';
export const assortment10_1_6_card = {
	xs: {src: assortment10_1_6_card_xs_src, id: 'assortment10_1_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_6_card_sm_src, id: 'assortment10_1_6_card_sm', width: '480', height: '320'},
};
import assortment10_1_7_card_xs_src from 'images/cards/factory/assortment/altholz-vintage.jpg?width=284&height=189&format=webp';
import assortment10_1_7_card_sm_src from 'images/cards/factory/assortment/altholz-vintage.jpg?width=480&height=320&format=webp';
export const assortment10_1_7_card = {
	xs: {src: assortment10_1_7_card_xs_src, id: 'assortment10_1_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_7_card_sm_src, id: 'assortment10_1_7_card_sm', width: '480', height: '320'},
};
import assortment10_1_8_card_xs_src from 'images/cards/factory/assortment/deko-kunst.jpg?width=284&height=189&format=webp';
import assortment10_1_8_card_sm_src from 'images/cards/factory/assortment/deko-kunst.jpg?width=480&height=320&format=webp';
export const assortment10_1_8_card = {
	xs: {src: assortment10_1_8_card_xs_src, id: 'assortment10_1_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_1_8_card_sm_src, id: 'assortment10_1_8_card_sm', width: '480', height: '320'},
};
import assortment10_2_1_card_xs_src from 'images/cards/factory/assortment/sitzgruppen.jpg?width=284&height=189&format=webp';
import assortment10_2_1_card_sm_src from 'images/cards/factory/assortment/sitzgruppen.jpg?width=480&height=320&format=webp';
export const assortment10_2_1_card = {
	xs: {src: assortment10_2_1_card_xs_src, id: 'assortment10_2_1_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_1_card_sm_src, id: 'assortment10_2_1_card_sm', width: '480', height: '320'},
};
import assortment10_2_2_card_xs_src from 'images/cards/factory/assortment/bänke.jpg?width=284&height=189&format=webp';
import assortment10_2_2_card_sm_src from 'images/cards/factory/assortment/bänke.jpg?width=480&height=320&format=webp';
export const assortment10_2_2_card = {
	xs: {src: assortment10_2_2_card_xs_src, id: 'assortment10_2_2_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_2_card_sm_src, id: 'assortment10_2_2_card_sm', width: '480', height: '320'},
};
import assortment10_2_3_card_xs_src from 'images/cards/factory/assortment/gartenmöbel.jpg?width=284&height=189&format=webp';
import assortment10_2_3_card_sm_src from 'images/cards/factory/assortment/gartenmöbel.jpg?width=480&height=320&format=webp';
export const assortment10_2_3_card = {
	xs: {src: assortment10_2_3_card_xs_src, id: 'assortment10_2_3_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_3_card_sm_src, id: 'assortment10_2_3_card_sm', width: '480', height: '320'},
};
import assortment10_2_4_card_xs_src from 'images/cards/factory/assortment/schaukeln.jpg?width=284&height=189&format=webp';
import assortment10_2_4_card_sm_src from 'images/cards/factory/assortment/schaukeln.jpg?width=480&height=320&format=webp';
export const assortment10_2_4_card = {
	xs: {src: assortment10_2_4_card_xs_src, id: 'assortment10_2_4_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_4_card_sm_src, id: 'assortment10_2_4_card_sm', width: '480', height: '320'},
};
import assortment10_2_5_card_xs_src from 'images/cards/factory/assortment/kinderspielgeräte.jpg?width=284&height=189&format=webp';
import assortment10_2_5_card_sm_src from 'images/cards/factory/assortment/kinderspielgeräte.jpg?width=480&height=320&format=webp';
export const assortment10_2_5_card = {
	xs: {src: assortment10_2_5_card_xs_src, id: 'assortment10_2_5_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_5_card_sm_src, id: 'assortment10_2_5_card_sm', width: '480', height: '320'},
};
import assortment10_2_6_card_xs_src from 'images/cards/factory/assortment/hasenställe.jpg?width=284&height=189&format=webp';
import assortment10_2_6_card_sm_src from 'images/cards/factory/assortment/hasenställe.jpg?width=480&height=320&format=webp';
export const assortment10_2_6_card = {
	xs: {src: assortment10_2_6_card_xs_src, id: 'assortment10_2_6_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_6_card_sm_src, id: 'assortment10_2_6_card_sm', width: '480', height: '320'},
};
import assortment10_2_7_card_xs_src from 'images/cards/factory/assortment/outdoorküche.jpg?width=284&height=189&format=webp';
import assortment10_2_7_card_sm_src from 'images/cards/factory/assortment/outdoorküche.jpg?width=480&height=320&format=webp';
export const assortment10_2_7_card = {
	xs: {src: assortment10_2_7_card_xs_src, id: 'assortment10_2_7_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_7_card_sm_src, id: 'assortment10_2_7_card_sm', width: '480', height: '320'},
};
import assortment10_2_8_card_xs_src from 'images/cards/factory/assortment/feuer-wärme.jpg?width=284&height=189&format=webp';
import assortment10_2_8_card_sm_src from 'images/cards/factory/assortment/feuer-wärme.jpg?width=480&height=320&format=webp';
export const assortment10_2_8_card = {
	xs: {src: assortment10_2_8_card_xs_src, id: 'assortment10_2_8_card_xs', width: '284', height: '189'},
	sm: {src: assortment10_2_8_card_sm_src, id: 'assortment10_2_8_card_sm', width: '480', height: '320'},
};
import indoor1_card_xs_src from 'images/cards/factory/assortment/tischplatten.jpg?width=284&height=189&format=webp';
import indoor1_card_sm_src from 'images/cards/factory/assortment/tischplatten.jpg?width=480&height=320&format=webp';
export const indoor1_card = {
	xs: {src: indoor1_card_xs_src, id: 'indoor1_card_xs', width: '284', height: '189'},
	sm: {src: indoor1_card_sm_src, id: 'indoor1_card_sm', width: '480', height: '320'},
};
import indoor2_card_xs_src from 'images/cards/factory/assortment/untergestelle.jpg?width=284&height=189&format=webp';
import indoor2_card_sm_src from 'images/cards/factory/assortment/untergestelle.jpg?width=480&height=320&format=webp';
export const indoor2_card = {
	xs: {src: indoor2_card_xs_src, id: 'indoor2_card_xs', width: '284', height: '189'},
	sm: {src: indoor2_card_sm_src, id: 'indoor2_card_sm', width: '480', height: '320'},
};
import indoor3_card_xs_src from 'images/cards/factory/assortment/stühle.jpg?width=284&height=189&format=webp';
import indoor3_card_sm_src from 'images/cards/factory/assortment/stühle.jpg?width=480&height=320&format=webp';
export const indoor3_card = {
	xs: {src: indoor3_card_xs_src, id: 'indoor3_card_xs', width: '284', height: '189'},
	sm: {src: indoor3_card_sm_src, id: 'indoor3_card_sm', width: '480', height: '320'},
};
import indoor4_card_xs_src from 'images/cards/factory/assortment/massivholzmöbel.jpg?width=284&height=189&format=webp';
import indoor4_card_sm_src from 'images/cards/factory/assortment/massivholzmöbel.jpg?width=480&height=320&format=webp';
export const indoor4_card = {
	xs: {src: indoor4_card_xs_src, id: 'indoor4_card_xs', width: '284', height: '189'},
	sm: {src: indoor4_card_sm_src, id: 'indoor4_card_sm', width: '480', height: '320'},
};
import indoor5_card_xs_src from 'images/cards/factory/assortment/betten.jpg?width=284&height=189&format=webp';
import indoor5_card_sm_src from 'images/cards/factory/assortment/betten.jpg?width=480&height=320&format=webp';
export const indoor5_card = {
	xs: {src: indoor5_card_xs_src, id: 'indoor5_card_xs', width: '284', height: '189'},
	sm: {src: indoor5_card_sm_src, id: 'indoor5_card_sm', width: '480', height: '320'},
};
import indoor6_card_xs_src from 'images/cards/factory/assortment/saunen.jpg?width=284&height=189&format=webp';
import indoor6_card_sm_src from 'images/cards/factory/assortment/saunen.jpg?width=480&height=320&format=webp';
export const indoor6_card = {
	xs: {src: indoor6_card_xs_src, id: 'indoor6_card_xs', width: '284', height: '189'},
	sm: {src: indoor6_card_sm_src, id: 'indoor6_card_sm', width: '480', height: '320'},
};
import indoor7_card_xs_src from 'images/cards/factory/assortment/altholz-vintage.jpg?width=284&height=189&format=webp';
import indoor7_card_sm_src from 'images/cards/factory/assortment/altholz-vintage.jpg?width=480&height=320&format=webp';
export const indoor7_card = {
	xs: {src: indoor7_card_xs_src, id: 'indoor7_card_xs', width: '284', height: '189'},
	sm: {src: indoor7_card_sm_src, id: 'indoor7_card_sm', width: '480', height: '320'},
};
import indoor8_card_xs_src from 'images/cards/factory/assortment/deko-kunst.jpg?width=284&height=189&format=webp';
import indoor8_card_sm_src from 'images/cards/factory/assortment/deko-kunst.jpg?width=480&height=320&format=webp';
export const indoor8_card = {
	xs: {src: indoor8_card_xs_src, id: 'indoor8_card_xs', width: '284', height: '189'},
	sm: {src: indoor8_card_sm_src, id: 'indoor8_card_sm', width: '480', height: '320'},
};
import outdoor1_card_xs_src from 'images/cards/factory/assortment/sitzgruppen.jpg?width=284&height=189&format=webp';
import outdoor1_card_sm_src from 'images/cards/factory/assortment/sitzgruppen.jpg?width=480&height=320&format=webp';
export const outdoor1_card = {
	xs: {src: outdoor1_card_xs_src, id: 'outdoor1_card_xs', width: '284', height: '189'},
	sm: {src: outdoor1_card_sm_src, id: 'outdoor1_card_sm', width: '480', height: '320'},
};
import outdoor2_card_xs_src from 'images/cards/factory/assortment/bänke.jpg?width=284&height=189&format=webp';
import outdoor2_card_sm_src from 'images/cards/factory/assortment/bänke.jpg?width=480&height=320&format=webp';
export const outdoor2_card = {
	xs: {src: outdoor2_card_xs_src, id: 'outdoor2_card_xs', width: '284', height: '189'},
	sm: {src: outdoor2_card_sm_src, id: 'outdoor2_card_sm', width: '480', height: '320'},
};
import outdoor3_card_xs_src from 'images/cards/factory/assortment/gartenmöbel.jpg?width=284&height=189&format=webp';
import outdoor3_card_sm_src from 'images/cards/factory/assortment/gartenmöbel.jpg?width=480&height=320&format=webp';
export const outdoor3_card = {
	xs: {src: outdoor3_card_xs_src, id: 'outdoor3_card_xs', width: '284', height: '189'},
	sm: {src: outdoor3_card_sm_src, id: 'outdoor3_card_sm', width: '480', height: '320'},
};
import outdoor4_card_xs_src from 'images/cards/factory/assortment/schaukeln.jpg?width=284&height=189&format=webp';
import outdoor4_card_sm_src from 'images/cards/factory/assortment/schaukeln.jpg?width=480&height=320&format=webp';
export const outdoor4_card = {
	xs: {src: outdoor4_card_xs_src, id: 'outdoor4_card_xs', width: '284', height: '189'},
	sm: {src: outdoor4_card_sm_src, id: 'outdoor4_card_sm', width: '480', height: '320'},
};
import outdoor5_card_xs_src from 'images/cards/factory/assortment/kinderspielgeräte.jpg?width=284&height=189&format=webp';
import outdoor5_card_sm_src from 'images/cards/factory/assortment/kinderspielgeräte.jpg?width=480&height=320&format=webp';
export const outdoor5_card = {
	xs: {src: outdoor5_card_xs_src, id: 'outdoor5_card_xs', width: '284', height: '189'},
	sm: {src: outdoor5_card_sm_src, id: 'outdoor5_card_sm', width: '480', height: '320'},
};
import outdoor6_card_xs_src from 'images/cards/factory/assortment/hasenställe.jpg?width=284&height=189&format=webp';
import outdoor6_card_sm_src from 'images/cards/factory/assortment/hasenställe.jpg?width=480&height=320&format=webp';
export const outdoor6_card = {
	xs: {src: outdoor6_card_xs_src, id: 'outdoor6_card_xs', width: '284', height: '189'},
	sm: {src: outdoor6_card_sm_src, id: 'outdoor6_card_sm', width: '480', height: '320'},
};
import outdoor7_card_xs_src from 'images/cards/factory/assortment/outdoorküche.jpg?width=284&height=189&format=webp';
import outdoor7_card_sm_src from 'images/cards/factory/assortment/outdoorküche.jpg?width=480&height=320&format=webp';
export const outdoor7_card = {
	xs: {src: outdoor7_card_xs_src, id: 'outdoor7_card_xs', width: '284', height: '189'},
	sm: {src: outdoor7_card_sm_src, id: 'outdoor7_card_sm', width: '480', height: '320'},
};
import outdoor8_card_xs_src from 'images/cards/factory/assortment/feuer-wärme.jpg?width=284&height=189&format=webp';
import outdoor8_card_sm_src from 'images/cards/factory/assortment/feuer-wärme.jpg?width=480&height=320&format=webp';
export const outdoor8_card = {
	xs: {src: outdoor8_card_xs_src, id: 'outdoor8_card_xs', width: '284', height: '189'},
	sm: {src: outdoor8_card_sm_src, id: 'outdoor8_card_sm', width: '480', height: '320'},
};
import czech_assortment1_card_xs_src from 'images/cards/czech/assortment_furniture.png?width=480&height=320&format=webp';
import czech_assortment1_card_sm_src from 'images/cards/czech/assortment_furniture.png?width=600&height=400&format=webp';
export const czech_assortment1_card = {
	xs: {src: czech_assortment1_card_xs_src, id: 'czech_assortment1_card_xs', width: '480', height: '320'},
	sm: {src: czech_assortment1_card_sm_src, id: 'czech_assortment1_card_sm', width: '600', height: '400'},
};
import czech_assortment2_card_xs_src from 'images/cards/czech/assortment_floor.png?width=480&height=320&format=webp';
import czech_assortment2_card_sm_src from 'images/cards/czech/assortment_floor.png?width=600&height=400&format=webp';
export const czech_assortment2_card = {
	xs: {src: czech_assortment2_card_xs_src, id: 'czech_assortment2_card_xs', width: '480', height: '320'},
	sm: {src: czech_assortment2_card_sm_src, id: 'czech_assortment2_card_sm', width: '600', height: '400'},
};
import czech_assortment3_card_xs_src from 'images/cards/czech/assortment_doors.png?width=480&height=320&format=webp';
import czech_assortment3_card_sm_src from 'images/cards/czech/assortment_doors.png?width=600&height=400&format=webp';
export const czech_assortment3_card = {
	xs: {src: czech_assortment3_card_xs_src, id: 'czech_assortment3_card_xs', width: '480', height: '320'},
	sm: {src: czech_assortment3_card_sm_src, id: 'czech_assortment3_card_sm', width: '600', height: '400'},
};
import czech_assortment4_card_xs_src from 'images/cards/czech/assortment_bbq.png?width=480&height=320&format=webp';
import czech_assortment4_card_sm_src from 'images/cards/czech/assortment_bbq.png?width=600&height=400&format=webp';
export const czech_assortment4_card = {
	xs: {src: czech_assortment4_card_xs_src, id: 'czech_assortment4_card_xs', width: '480', height: '320'},
	sm: {src: czech_assortment4_card_sm_src, id: 'czech_assortment4_card_sm', width: '600', height: '400'},
};
import czech_assortment5_card_xs_src from 'images/cards/czech/assortment_terrace.png?width=480&height=320&format=webp';
import czech_assortment5_card_sm_src from 'images/cards/czech/assortment_terrace.png?width=600&height=400&format=webp';
export const czech_assortment5_card = {
	xs: {src: czech_assortment5_card_xs_src, id: 'czech_assortment5_card_xs', width: '480', height: '320'},
	sm: {src: czech_assortment5_card_sm_src, id: 'czech_assortment5_card_sm', width: '600', height: '400'},
};
import czech_assortment6_card_xs_src from 'images/cards/czech/assortment_facade.png?width=480&height=320&format=webp';
import czech_assortment6_card_sm_src from 'images/cards/czech/assortment_facade.png?width=600&height=400&format=webp';
export const czech_assortment6_card = {
	xs: {src: czech_assortment6_card_xs_src, id: 'czech_assortment6_card_xs', width: '480', height: '320'},
	sm: {src: czech_assortment6_card_sm_src, id: 'czech_assortment6_card_sm', width: '600', height: '400'},
};
import exhibition1_1_card_xs_src from 'images/cards/exhibition/ausstellung-chemnitz-holzbau.jpg?width=480&height=320&format=webp';
import exhibition1_1_card_sm_src from 'images/cards/exhibition/ausstellung-chemnitz-holzbau.jpg?width=600&height=400&format=webp';
export const exhibition1_1_card = {
	xs: {src: exhibition1_1_card_xs_src, id: 'exhibition1_1_card_xs', width: '480', height: '320'},
	sm: {src: exhibition1_1_card_sm_src, id: 'exhibition1_1_card_sm', width: '600', height: '400'},
};
import exhibition1_2_card_xs_src from 'images/cards/exhibition/ausstellung-chemnitz-türen.jpg?width=480&height=320&format=webp';
import exhibition1_2_card_sm_src from 'images/cards/exhibition/ausstellung-chemnitz-türen.jpg?width=600&height=400&format=webp';
export const exhibition1_2_card = {
	xs: {src: exhibition1_2_card_xs_src, id: 'exhibition1_2_card_xs', width: '480', height: '320'},
	sm: {src: exhibition1_2_card_sm_src, id: 'exhibition1_2_card_sm', width: '600', height: '400'},
};
import exhibition1_3_card_xs_src from 'images/cards/exhibition/ausstellung-chemnitz-boden.jpg?width=480&height=320&format=webp';
import exhibition1_3_card_sm_src from 'images/cards/exhibition/ausstellung-chemnitz-boden.jpg?width=600&height=400&format=webp';
export const exhibition1_3_card = {
	xs: {src: exhibition1_3_card_xs_src, id: 'exhibition1_3_card_xs', width: '480', height: '320'},
	sm: {src: exhibition1_3_card_sm_src, id: 'exhibition1_3_card_sm', width: '600', height: '400'},
};
import exhibition1_4_card_xs_src from 'images/cards/exhibition/ausstellung-chemnitz-terrasse.jpg?width=480&height=320&format=webp';
import exhibition1_4_card_sm_src from 'images/cards/exhibition/ausstellung-chemnitz-terrasse.jpg?width=600&height=400&format=webp';
export const exhibition1_4_card = {
	xs: {src: exhibition1_4_card_xs_src, id: 'exhibition1_4_card_xs', width: '480', height: '320'},
	sm: {src: exhibition1_4_card_sm_src, id: 'exhibition1_4_card_sm', width: '600', height: '400'},
};
import exhibition1_5_card_xs_src from 'images/cards/exhibition/ausstellung-chemnitz-möbel-grillen.jpg?width=480&height=320&format=webp';
import exhibition1_5_card_sm_src from 'images/cards/exhibition/ausstellung-chemnitz-möbel-grillen.jpg?width=600&height=400&format=webp';
export const exhibition1_5_card = {
	xs: {src: exhibition1_5_card_xs_src, id: 'exhibition1_5_card_xs', width: '480', height: '320'},
	sm: {src: exhibition1_5_card_sm_src, id: 'exhibition1_5_card_sm', width: '600', height: '400'},
};
import exhibition1_6_card_xs_src from 'images/cards/exhibition/ausstellung-chemnitz-tische-altholz.jpg?width=480&height=320&format=webp';
import exhibition1_6_card_sm_src from 'images/cards/exhibition/ausstellung-chemnitz-tische-altholz.jpg?width=600&height=400&format=webp';
export const exhibition1_6_card = {
	xs: {src: exhibition1_6_card_xs_src, id: 'exhibition1_6_card_xs', width: '480', height: '320'},
	sm: {src: exhibition1_6_card_sm_src, id: 'exhibition1_6_card_sm', width: '600', height: '400'},
};
import exhibition2_1_card_xs_src from 'images/cards/exhibition/ausstellung-lauter-türen.jpg?width=480&height=320&format=webp';
import exhibition2_1_card_sm_src from 'images/cards/exhibition/ausstellung-lauter-türen.jpg?width=600&height=400&format=webp';
export const exhibition2_1_card = {
	xs: {src: exhibition2_1_card_xs_src, id: 'exhibition2_1_card_xs', width: '480', height: '320'},
	sm: {src: exhibition2_1_card_sm_src, id: 'exhibition2_1_card_sm', width: '600', height: '400'},
};
import exhibition2_2_card_xs_src from 'images/cards/exhibition/ausstellung-lauter-boden.jpg?width=480&height=320&format=webp';
import exhibition2_2_card_sm_src from 'images/cards/exhibition/ausstellung-lauter-boden.jpg?width=600&height=400&format=webp';
export const exhibition2_2_card = {
	xs: {src: exhibition2_2_card_xs_src, id: 'exhibition2_2_card_xs', width: '480', height: '320'},
	sm: {src: exhibition2_2_card_sm_src, id: 'exhibition2_2_card_sm', width: '600', height: '400'},
};
import exhibition2_3_card_xs_src from 'images/cards/exhibition/ausstellung-lauter-terrasse.jpg?width=480&height=320&format=webp';
import exhibition2_3_card_sm_src from 'images/cards/exhibition/ausstellung-lauter-terrasse.jpg?width=600&height=400&format=webp';
export const exhibition2_3_card = {
	xs: {src: exhibition2_3_card_xs_src, id: 'exhibition2_3_card_xs', width: '480', height: '320'},
	sm: {src: exhibition2_3_card_sm_src, id: 'exhibition2_3_card_sm', width: '600', height: '400'},
};
import exhibition2_4_card_xs_src from 'images/cards/exhibition/ausstellung-lauter-fassade.jpg?width=480&height=320&format=webp';
import exhibition2_4_card_sm_src from 'images/cards/exhibition/ausstellung-lauter-fassade.jpg?width=600&height=400&format=webp';
export const exhibition2_4_card = {
	xs: {src: exhibition2_4_card_xs_src, id: 'exhibition2_4_card_xs', width: '480', height: '320'},
	sm: {src: exhibition2_4_card_sm_src, id: 'exhibition2_4_card_sm', width: '600', height: '400'},
};
import exhibition3_1_card_xs_src from 'images/cards/exhibition/ausstellung-holzfabrik-tische.jpg?width=480&height=320&format=webp';
import exhibition3_1_card_sm_src from 'images/cards/exhibition/ausstellung-holzfabrik-tische.jpg?width=600&height=400&format=webp';
export const exhibition3_1_card = {
	xs: {src: exhibition3_1_card_xs_src, id: 'exhibition3_1_card_xs', width: '480', height: '320'},
	sm: {src: exhibition3_1_card_sm_src, id: 'exhibition3_1_card_sm', width: '600', height: '400'},
};
import exhibition3_2_card_xs_src from 'images/cards/exhibition/ausstellung-holzfabrik-deko.jpg?width=480&height=320&format=webp';
import exhibition3_2_card_sm_src from 'images/cards/exhibition/ausstellung-holzfabrik-deko.jpg?width=600&height=400&format=webp';
export const exhibition3_2_card = {
	xs: {src: exhibition3_2_card_xs_src, id: 'exhibition3_2_card_xs', width: '480', height: '320'},
	sm: {src: exhibition3_2_card_sm_src, id: 'exhibition3_2_card_sm', width: '600', height: '400'},
};
import exhibition3_3_card_xs_src from 'images/cards/exhibition/ausstellung-holzfabrik-vintage.jpg?width=480&height=320&format=webp';
import exhibition3_3_card_sm_src from 'images/cards/exhibition/ausstellung-holzfabrik-vintage.jpg?width=600&height=400&format=webp';
export const exhibition3_3_card = {
	xs: {src: exhibition3_3_card_xs_src, id: 'exhibition3_3_card_xs', width: '480', height: '320'},
	sm: {src: exhibition3_3_card_sm_src, id: 'exhibition3_3_card_sm', width: '600', height: '400'},
};
import exhibition3_4_card_xs_src from 'images/cards/exhibition/ausstellung-holzfabrik-altholz.jpg?width=480&height=320&format=webp';
import exhibition3_4_card_sm_src from 'images/cards/exhibition/ausstellung-holzfabrik-altholz.jpg?width=600&height=400&format=webp';
export const exhibition3_4_card = {
	xs: {src: exhibition3_4_card_xs_src, id: 'exhibition3_4_card_xs', width: '480', height: '320'},
	sm: {src: exhibition3_4_card_sm_src, id: 'exhibition3_4_card_sm', width: '600', height: '400'},
};
import exhibition3_5_card_xs_src from 'images/cards/exhibition/ausstellung-holzfabrik-accessoires.jpg?width=480&height=320&format=webp';
import exhibition3_5_card_sm_src from 'images/cards/exhibition/ausstellung-holzfabrik-accessoires.jpg?width=600&height=400&format=webp';
export const exhibition3_5_card = {
	xs: {src: exhibition3_5_card_xs_src, id: 'exhibition3_5_card_xs', width: '480', height: '320'},
	sm: {src: exhibition3_5_card_sm_src, id: 'exhibition3_5_card_sm', width: '600', height: '400'},
};
import exhibition3_6_card_xs_src from 'images/cards/exhibition/ausstellung-holzfabrik-betten.jpg?width=480&height=320&format=webp';
import exhibition3_6_card_sm_src from 'images/cards/exhibition/ausstellung-holzfabrik-betten.jpg?width=600&height=400&format=webp';
export const exhibition3_6_card = {
	xs: {src: exhibition3_6_card_xs_src, id: 'exhibition3_6_card_xs', width: '480', height: '320'},
	sm: {src: exhibition3_6_card_sm_src, id: 'exhibition3_6_card_sm', width: '600', height: '400'},
};
import exhibition4_1_card_xs_src from 'images/cards/exhibition/ausstellung-brethaus-holzbau.jpg?width=480&height=320&format=webp';
import exhibition4_1_card_sm_src from 'images/cards/exhibition/ausstellung-brethaus-holzbau.jpg?width=600&height=400&format=webp';
export const exhibition4_1_card = {
	xs: {src: exhibition4_1_card_xs_src, id: 'exhibition4_1_card_xs', width: '480', height: '320'},
	sm: {src: exhibition4_1_card_sm_src, id: 'exhibition4_1_card_sm', width: '600', height: '400'},
};
import exhibition4_2_card_xs_src from 'images/cards/exhibition/ausstellung-brethaus-platten.jpg?width=480&height=320&format=webp';
import exhibition4_2_card_sm_src from 'images/cards/exhibition/ausstellung-brethaus-platten.jpg?width=600&height=400&format=webp';
export const exhibition4_2_card = {
	xs: {src: exhibition4_2_card_xs_src, id: 'exhibition4_2_card_xs', width: '480', height: '320'},
	sm: {src: exhibition4_2_card_sm_src, id: 'exhibition4_2_card_sm', width: '600', height: '400'},
};
import exhibition4_3_card_xs_src from 'images/cards/exhibition/ausstellung-brethaus-dämmung.jpg?width=480&height=320&format=webp';
import exhibition4_3_card_sm_src from 'images/cards/exhibition/ausstellung-brethaus-dämmung.jpg?width=600&height=400&format=webp';
export const exhibition4_3_card = {
	xs: {src: exhibition4_3_card_xs_src, id: 'exhibition4_3_card_xs', width: '480', height: '320'},
	sm: {src: exhibition4_3_card_sm_src, id: 'exhibition4_3_card_sm', width: '600', height: '400'},
};
import history1924_card_src from 'images/cards/history/img1924.jpg?width=480&height=320&format=webp';
export const history1924_card = {
	src: history1924_card_src, id: 'history1924_card', width: '480', height: '320'
};
import history1991_card_src from 'images/cards/history/img1991.jpg?width=480&height=320&format=webp';
export const history1991_card = {
	src: history1991_card_src, id: 'history1991_card', width: '480', height: '320'
};
import history1994_card_src from 'images/cards/history/img1994.jpg?width=480&height=320&format=webp';
export const history1994_card = {
	src: history1994_card_src, id: 'history1994_card', width: '480', height: '320'
};
import history1996_card_src from 'images/cards/history/img1996.jpg?width=480&height=320&format=webp';
export const history1996_card = {
	src: history1996_card_src, id: 'history1996_card', width: '480', height: '320'
};
import history1998_card_src from 'images/cards/history/img1998.jpg?width=480&height=320&format=webp';
export const history1998_card = {
	src: history1998_card_src, id: 'history1998_card', width: '480', height: '320'
};
import history1999_card_src from 'images/cards/history/img1999.jpg?width=480&height=320&format=webp';
export const history1999_card = {
	src: history1999_card_src, id: 'history1999_card', width: '480', height: '320'
};
import history2001_card_src from 'images/cards/history/img2001.jpg?width=480&height=320&format=webp';
export const history2001_card = {
	src: history2001_card_src, id: 'history2001_card', width: '480', height: '320'
};
import history2002_card_src from 'images/cards/history/img2002.jpg?width=480&height=320&format=webp';
export const history2002_card = {
	src: history2002_card_src, id: 'history2002_card', width: '480', height: '320'
};
import history2005_card_src from 'images/cards/history/img2005.jpg?width=480&height=320&format=webp';
export const history2005_card = {
	src: history2005_card_src, id: 'history2005_card', width: '480', height: '320'
};
import history2005_2_card_src from 'images/cards/history/img2005_2.jpg?width=480&height=320&format=webp';
export const history2005_2_card = {
	src: history2005_2_card_src, id: 'history2005_2_card', width: '480', height: '320'
};
import history2009_card_src from 'images/cards/history/img2009.jpg?width=480&height=320&format=webp';
export const history2009_card = {
	src: history2009_card_src, id: 'history2009_card', width: '480', height: '320'
};
import history2009_2_card_src from 'images/cards/history/img2009_2.jpg?width=480&height=320&format=webp';
export const history2009_2_card = {
	src: history2009_2_card_src, id: 'history2009_2_card', width: '480', height: '320'
};
import history2015_card_src from 'images/cards/history/img2015.jpg?width=480&height=320&format=webp';
export const history2015_card = {
	src: history2015_card_src, id: 'history2015_card', width: '480', height: '320'
};
import history2016_card_src from 'images/cards/history/img2016.jpg?width=480&height=320&format=webp';
export const history2016_card = {
	src: history2016_card_src, id: 'history2016_card', width: '480', height: '320'
};
import history2017_card_src from 'images/cards/history/img2017.jpg?width=480&height=320&format=webp';
export const history2017_card = {
	src: history2017_card_src, id: 'history2017_card', width: '480', height: '320'
};
import history2019_card_src from 'images/cards/history/img2019.jpg?width=480&height=320&format=webp';
export const history2019_card = {
	src: history2019_card_src, id: 'history2019_card', width: '480', height: '320'
};
import history2020_card_src from 'images/cards/history/img2020.jpg?width=480&height=320&format=webp';
export const history2020_card = {
	src: history2020_card_src, id: 'history2020_card', width: '480', height: '320'
};
import history2021_card_src from 'images/cards/history/img2021.jpg?width=480&height=320&format=webp';
export const history2021_card = {
	src: history2021_card_src, id: 'history2021_card', width: '480', height: '320'
};
import benjamin_kunzmann_card_src from 'images/cards/factory/team/benjamin_kunzmann.jpg?width=400&height=364&format=webp';
export const benjamin_kunzmann_card = {
	src: benjamin_kunzmann_card_src, id: 'benjamin_kunzmann_card', width: '400', height: '364'
};
import tobias_kunzmann_card_src from 'images/cards/factory/team/tobias_kunzmann.jpg?width=400&height=364&format=webp';
export const tobias_kunzmann_card = {
	src: tobias_kunzmann_card_src, id: 'tobias_kunzmann_card', width: '400', height: '364'
};
import sebastian_ebert_card_src from 'images/cards/factory/team/sebastian_ebert.jpg?width=400&height=364&format=webp';
export const sebastian_ebert_card = {
	src: sebastian_ebert_card_src, id: 'sebastian_ebert_card', width: '400', height: '364'
};
import bauen_mit_holz_banner_xs_src from 'images/banners/assortment/bauen-mit-holz.jpg?width=600&height=180&format=webp';
import bauen_mit_holz_banner_sm_src from 'images/banners/assortment/bauen-mit-holz.jpg?width=960&height=220&format=webp';
import bauen_mit_holz_banner_md_src from 'images/banners/assortment/bauen-mit-holz.jpg?width=1280&height=220&format=webp';
import bauen_mit_holz_banner_lg_src from 'images/banners/assortment/bauen-mit-holz.jpg?width=1440&height=250&format=webp';
export const bauen_mit_holz_banner = {
	xs: {src: bauen_mit_holz_banner_xs_src, id: 'bauen_mit_holz_banner_xs', width: '600', height: '180'},
	sm: {src: bauen_mit_holz_banner_sm_src, id: 'bauen_mit_holz_banner_sm', width: '960', height: '220'},
	md: {src: bauen_mit_holz_banner_md_src, id: 'bauen_mit_holz_banner_md', width: '1280', height: '220'},
	lg: {src: bauen_mit_holz_banner_lg_src, id: 'bauen_mit_holz_banner_lg', width: '1440', height: '250'},
};
import bauholz_banner_xs_src from 'images/banners/assortment/bauholz.jpg?width=600&height=180&format=webp';
import bauholz_banner_sm_src from 'images/banners/assortment/bauholz.jpg?width=960&height=220&format=webp';
import bauholz_banner_md_src from 'images/banners/assortment/bauholz.jpg?width=1280&height=220&format=webp';
import bauholz_banner_lg_src from 'images/banners/assortment/bauholz.jpg?width=1440&height=250&format=webp';
export const bauholz_banner = {
	xs: {src: bauholz_banner_xs_src, id: 'bauholz_banner_xs', width: '600', height: '180'},
	sm: {src: bauholz_banner_sm_src, id: 'bauholz_banner_sm', width: '960', height: '220'},
	md: {src: bauholz_banner_md_src, id: 'bauholz_banner_md', width: '1280', height: '220'},
	lg: {src: bauholz_banner_lg_src, id: 'bauholz_banner_lg', width: '1440', height: '250'},
};
import hobelware_banner_xs_src from 'images/banners/assortment/hobelware.jpg?width=600&height=180&format=webp';
import hobelware_banner_sm_src from 'images/banners/assortment/hobelware.jpg?width=960&height=220&format=webp';
import hobelware_banner_md_src from 'images/banners/assortment/hobelware.jpg?width=1280&height=220&format=webp';
import hobelware_banner_lg_src from 'images/banners/assortment/hobelware.jpg?width=1440&height=250&format=webp';
export const hobelware_banner = {
	xs: {src: hobelware_banner_xs_src, id: 'hobelware_banner_xs', width: '600', height: '180'},
	sm: {src: hobelware_banner_sm_src, id: 'hobelware_banner_sm', width: '960', height: '220'},
	md: {src: hobelware_banner_md_src, id: 'hobelware_banner_md', width: '1280', height: '220'},
	lg: {src: hobelware_banner_lg_src, id: 'hobelware_banner_lg', width: '1440', height: '250'},
};
import fassade_banner_xs_src from 'images/banners/assortment/fassade.jpg?width=600&height=180&format=webp';
import fassade_banner_sm_src from 'images/banners/assortment/fassade.jpg?width=960&height=220&format=webp';
import fassade_banner_md_src from 'images/banners/assortment/fassade.jpg?width=1280&height=220&format=webp';
import fassade_banner_lg_src from 'images/banners/assortment/fassade.jpg?width=1440&height=250&format=webp';
export const fassade_banner = {
	xs: {src: fassade_banner_xs_src, id: 'fassade_banner_xs', width: '600', height: '180'},
	sm: {src: fassade_banner_sm_src, id: 'fassade_banner_sm', width: '960', height: '220'},
	md: {src: fassade_banner_md_src, id: 'fassade_banner_md', width: '1280', height: '220'},
	lg: {src: fassade_banner_lg_src, id: 'fassade_banner_lg', width: '1440', height: '250'},
};
import dämmstoffe_banner_xs_src from 'images/banners/assortment/dämmstoffe.jpg?width=600&height=180&format=webp';
import dämmstoffe_banner_sm_src from 'images/banners/assortment/dämmstoffe.jpg?width=960&height=220&format=webp';
import dämmstoffe_banner_md_src from 'images/banners/assortment/dämmstoffe.jpg?width=1280&height=220&format=webp';
import dämmstoffe_banner_lg_src from 'images/banners/assortment/dämmstoffe.jpg?width=1440&height=250&format=webp';
export const dämmstoffe_banner = {
	xs: {src: dämmstoffe_banner_xs_src, id: 'dämmstoffe_banner_xs', width: '600', height: '180'},
	sm: {src: dämmstoffe_banner_sm_src, id: 'dämmstoffe_banner_sm', width: '960', height: '220'},
	md: {src: dämmstoffe_banner_md_src, id: 'dämmstoffe_banner_md', width: '1280', height: '220'},
	lg: {src: dämmstoffe_banner_lg_src, id: 'dämmstoffe_banner_lg', width: '1440', height: '250'},
};
import dachausbau_banner_xs_src from 'images/banners/assortment/dachausbau.jpg?width=600&height=180&format=webp';
import dachausbau_banner_sm_src from 'images/banners/assortment/dachausbau.jpg?width=960&height=220&format=webp';
import dachausbau_banner_md_src from 'images/banners/assortment/dachausbau.jpg?width=1280&height=220&format=webp';
import dachausbau_banner_lg_src from 'images/banners/assortment/dachausbau.jpg?width=1440&height=250&format=webp';
export const dachausbau_banner = {
	xs: {src: dachausbau_banner_xs_src, id: 'dachausbau_banner_xs', width: '600', height: '180'},
	sm: {src: dachausbau_banner_sm_src, id: 'dachausbau_banner_sm', width: '960', height: '220'},
	md: {src: dachausbau_banner_md_src, id: 'dachausbau_banner_md', width: '1280', height: '220'},
	lg: {src: dachausbau_banner_lg_src, id: 'dachausbau_banner_lg', width: '1440', height: '250'},
};
import tischlerware_banner_xs_src from 'images/banners/assortment/tischlerware.jpg?width=600&height=180&format=webp';
import tischlerware_banner_sm_src from 'images/banners/assortment/tischlerware.jpg?width=960&height=220&format=webp';
import tischlerware_banner_md_src from 'images/banners/assortment/tischlerware.jpg?width=1280&height=220&format=webp';
import tischlerware_banner_lg_src from 'images/banners/assortment/tischlerware.jpg?width=1440&height=250&format=webp';
export const tischlerware_banner = {
	xs: {src: tischlerware_banner_xs_src, id: 'tischlerware_banner_xs', width: '600', height: '180'},
	sm: {src: tischlerware_banner_sm_src, id: 'tischlerware_banner_sm', width: '960', height: '220'},
	md: {src: tischlerware_banner_md_src, id: 'tischlerware_banner_md', width: '1280', height: '220'},
	lg: {src: tischlerware_banner_lg_src, id: 'tischlerware_banner_lg', width: '1440', height: '250'},
};
import nadelholz_banner_xs_src from 'images/banners/assortment/tischlerware.jpg?width=600&height=180&format=webp';
import nadelholz_banner_sm_src from 'images/banners/assortment/tischlerware.jpg?width=960&height=220&format=webp';
import nadelholz_banner_md_src from 'images/banners/assortment/tischlerware.jpg?width=1280&height=220&format=webp';
import nadelholz_banner_lg_src from 'images/banners/assortment/tischlerware.jpg?width=1440&height=250&format=webp';
export const nadelholz_banner = {
	xs: {src: nadelholz_banner_xs_src, id: 'nadelholz_banner_xs', width: '600', height: '180'},
	sm: {src: nadelholz_banner_sm_src, id: 'nadelholz_banner_sm', width: '960', height: '220'},
	md: {src: nadelholz_banner_md_src, id: 'nadelholz_banner_md', width: '1280', height: '220'},
	lg: {src: nadelholz_banner_lg_src, id: 'nadelholz_banner_lg', width: '1440', height: '250'},
};
import afrikanische_hoelzer_banner_xs_src from 'images/banners/assortment/tischlerware.jpg?width=600&height=180&format=webp';
import afrikanische_hoelzer_banner_sm_src from 'images/banners/assortment/tischlerware.jpg?width=960&height=220&format=webp';
import afrikanische_hoelzer_banner_md_src from 'images/banners/assortment/tischlerware.jpg?width=1280&height=220&format=webp';
import afrikanische_hoelzer_banner_lg_src from 'images/banners/assortment/tischlerware.jpg?width=1440&height=250&format=webp';
export const afrikanische_hoelzer_banner = {
	xs: {src: afrikanische_hoelzer_banner_xs_src, id: 'afrikanische_hoelzer_banner_xs', width: '600', height: '180'},
	sm: {src: afrikanische_hoelzer_banner_sm_src, id: 'afrikanische_hoelzer_banner_sm', width: '960', height: '220'},
	md: {src: afrikanische_hoelzer_banner_md_src, id: 'afrikanische_hoelzer_banner_md', width: '1280', height: '220'},
	lg: {src: afrikanische_hoelzer_banner_lg_src, id: 'afrikanische_hoelzer_banner_lg', width: '1440', height: '250'},
};
import laubholz_banner_xs_src from 'images/banners/assortment/tischlerware.jpg?width=600&height=180&format=webp';
import laubholz_banner_sm_src from 'images/banners/assortment/tischlerware.jpg?width=960&height=220&format=webp';
import laubholz_banner_md_src from 'images/banners/assortment/tischlerware.jpg?width=1280&height=220&format=webp';
import laubholz_banner_lg_src from 'images/banners/assortment/tischlerware.jpg?width=1440&height=250&format=webp';
export const laubholz_banner = {
	xs: {src: laubholz_banner_xs_src, id: 'laubholz_banner_xs', width: '600', height: '180'},
	sm: {src: laubholz_banner_sm_src, id: 'laubholz_banner_sm', width: '960', height: '220'},
	md: {src: laubholz_banner_md_src, id: 'laubholz_banner_md', width: '1280', height: '220'},
	lg: {src: laubholz_banner_lg_src, id: 'laubholz_banner_lg', width: '1440', height: '250'},
};
import platten_holzwerkstoffe_banner_xs_src from 'images/banners/assortment/platten-holzwerkstoffe.jpg?width=600&height=180&format=webp';
import platten_holzwerkstoffe_banner_sm_src from 'images/banners/assortment/platten-holzwerkstoffe.jpg?width=960&height=220&format=webp';
import platten_holzwerkstoffe_banner_md_src from 'images/banners/assortment/platten-holzwerkstoffe.jpg?width=1280&height=220&format=webp';
import platten_holzwerkstoffe_banner_lg_src from 'images/banners/assortment/platten-holzwerkstoffe.jpg?width=1440&height=250&format=webp';
export const platten_holzwerkstoffe_banner = {
	xs: {src: platten_holzwerkstoffe_banner_xs_src, id: 'platten_holzwerkstoffe_banner_xs', width: '600', height: '180'},
	sm: {src: platten_holzwerkstoffe_banner_sm_src, id: 'platten_holzwerkstoffe_banner_sm', width: '960', height: '220'},
	md: {src: platten_holzwerkstoffe_banner_md_src, id: 'platten_holzwerkstoffe_banner_md', width: '1280', height: '220'},
	lg: {src: platten_holzwerkstoffe_banner_lg_src, id: 'platten_holzwerkstoffe_banner_lg', width: '1440', height: '250'},
};
import platten_dekorativ_banner_xs_src from 'images/banners/assortment/platten-dekorativ.jpg?width=600&height=180&format=webp';
import platten_dekorativ_banner_sm_src from 'images/banners/assortment/platten-dekorativ.jpg?width=960&height=220&format=webp';
import platten_dekorativ_banner_md_src from 'images/banners/assortment/platten-dekorativ.jpg?width=1280&height=220&format=webp';
import platten_dekorativ_banner_lg_src from 'images/banners/assortment/platten-dekorativ.jpg?width=1440&height=250&format=webp';
export const platten_dekorativ_banner = {
	xs: {src: platten_dekorativ_banner_xs_src, id: 'platten_dekorativ_banner_xs', width: '600', height: '180'},
	sm: {src: platten_dekorativ_banner_sm_src, id: 'platten_dekorativ_banner_sm', width: '960', height: '220'},
	md: {src: platten_dekorativ_banner_md_src, id: 'platten_dekorativ_banner_md', width: '1280', height: '220'},
	lg: {src: platten_dekorativ_banner_lg_src, id: 'platten_dekorativ_banner_lg', width: '1440', height: '250'},
};
import platten_konstruktiv_banner_xs_src from 'images/banners/assortment/platten-konstruktiv.jpg?width=600&height=180&format=webp';
import platten_konstruktiv_banner_sm_src from 'images/banners/assortment/platten-konstruktiv.jpg?width=960&height=220&format=webp';
import platten_konstruktiv_banner_md_src from 'images/banners/assortment/platten-konstruktiv.jpg?width=1280&height=220&format=webp';
import platten_konstruktiv_banner_lg_src from 'images/banners/assortment/platten-konstruktiv.jpg?width=1440&height=250&format=webp';
export const platten_konstruktiv_banner = {
	xs: {src: platten_konstruktiv_banner_xs_src, id: 'platten_konstruktiv_banner_xs', width: '600', height: '180'},
	sm: {src: platten_konstruktiv_banner_sm_src, id: 'platten_konstruktiv_banner_sm', width: '960', height: '220'},
	md: {src: platten_konstruktiv_banner_md_src, id: 'platten_konstruktiv_banner_md', width: '1280', height: '220'},
	lg: {src: platten_konstruktiv_banner_lg_src, id: 'platten_konstruktiv_banner_lg', width: '1440', height: '250'},
};
import boden_wand_decke_banner_xs_src from 'images/banners/assortment/boden-wand-decke.jpg?width=600&height=180&format=webp';
import boden_wand_decke_banner_sm_src from 'images/banners/assortment/boden-wand-decke.jpg?width=960&height=220&format=webp';
import boden_wand_decke_banner_md_src from 'images/banners/assortment/boden-wand-decke.jpg?width=1280&height=220&format=webp';
import boden_wand_decke_banner_lg_src from 'images/banners/assortment/boden-wand-decke.jpg?width=1440&height=250&format=webp';
export const boden_wand_decke_banner = {
	xs: {src: boden_wand_decke_banner_xs_src, id: 'boden_wand_decke_banner_xs', width: '600', height: '180'},
	sm: {src: boden_wand_decke_banner_sm_src, id: 'boden_wand_decke_banner_sm', width: '960', height: '220'},
	md: {src: boden_wand_decke_banner_md_src, id: 'boden_wand_decke_banner_md', width: '1280', height: '220'},
	lg: {src: boden_wand_decke_banner_lg_src, id: 'boden_wand_decke_banner_lg', width: '1440', height: '250'},
};
import hq_bodenwelt_banner_xs_src from 'images/banners/assortment/hq-bodenwelt.jpg?width=600&height=180&format=webp';
import hq_bodenwelt_banner_sm_src from 'images/banners/assortment/hq-bodenwelt.jpg?width=960&height=220&format=webp';
import hq_bodenwelt_banner_md_src from 'images/banners/assortment/hq-bodenwelt.jpg?width=1280&height=220&format=webp';
import hq_bodenwelt_banner_lg_src from 'images/banners/assortment/hq-bodenwelt.jpg?width=1440&height=250&format=webp';
export const hq_bodenwelt_banner = {
	xs: {src: hq_bodenwelt_banner_xs_src, id: 'hq_bodenwelt_banner_xs', width: '600', height: '180'},
	sm: {src: hq_bodenwelt_banner_sm_src, id: 'hq_bodenwelt_banner_sm', width: '960', height: '220'},
	md: {src: hq_bodenwelt_banner_md_src, id: 'hq_bodenwelt_banner_md', width: '1280', height: '220'},
	lg: {src: hq_bodenwelt_banner_lg_src, id: 'hq_bodenwelt_banner_lg', width: '1440', height: '250'},
};
import laminat_banner_xs_src from 'images/banners/assortment/laminat.jpg?width=600&height=180&format=webp';
import laminat_banner_sm_src from 'images/banners/assortment/laminat.jpg?width=960&height=220&format=webp';
import laminat_banner_md_src from 'images/banners/assortment/laminat.jpg?width=1280&height=220&format=webp';
import laminat_banner_lg_src from 'images/banners/assortment/laminat.jpg?width=1440&height=250&format=webp';
export const laminat_banner = {
	xs: {src: laminat_banner_xs_src, id: 'laminat_banner_xs', width: '600', height: '180'},
	sm: {src: laminat_banner_sm_src, id: 'laminat_banner_sm', width: '960', height: '220'},
	md: {src: laminat_banner_md_src, id: 'laminat_banner_md', width: '1280', height: '220'},
	lg: {src: laminat_banner_lg_src, id: 'laminat_banner_lg', width: '1440', height: '250'},
};
import vinyl_designboden_banner_xs_src from 'images/banners/assortment/vinyl-designboden.jpg?width=600&height=180&format=webp';
import vinyl_designboden_banner_sm_src from 'images/banners/assortment/vinyl-designboden.jpg?width=960&height=220&format=webp';
import vinyl_designboden_banner_md_src from 'images/banners/assortment/vinyl-designboden.jpg?width=1280&height=220&format=webp';
import vinyl_designboden_banner_lg_src from 'images/banners/assortment/vinyl-designboden.jpg?width=1440&height=250&format=webp';
export const vinyl_designboden_banner = {
	xs: {src: vinyl_designboden_banner_xs_src, id: 'vinyl_designboden_banner_xs', width: '600', height: '180'},
	sm: {src: vinyl_designboden_banner_sm_src, id: 'vinyl_designboden_banner_sm', width: '960', height: '220'},
	md: {src: vinyl_designboden_banner_md_src, id: 'vinyl_designboden_banner_md', width: '1280', height: '220'},
	lg: {src: vinyl_designboden_banner_lg_src, id: 'vinyl_designboden_banner_lg', width: '1440', height: '250'},
};
import parkett_banner_xs_src from 'images/banners/assortment/parkett.jpg?width=600&height=180&format=webp';
import parkett_banner_sm_src from 'images/banners/assortment/parkett.jpg?width=960&height=220&format=webp';
import parkett_banner_md_src from 'images/banners/assortment/parkett.jpg?width=1280&height=220&format=webp';
import parkett_banner_lg_src from 'images/banners/assortment/parkett.jpg?width=1440&height=250&format=webp';
export const parkett_banner = {
	xs: {src: parkett_banner_xs_src, id: 'parkett_banner_xs', width: '600', height: '180'},
	sm: {src: parkett_banner_sm_src, id: 'parkett_banner_sm', width: '960', height: '220'},
	md: {src: parkett_banner_md_src, id: 'parkett_banner_md', width: '1280', height: '220'},
	lg: {src: parkett_banner_lg_src, id: 'parkett_banner_lg', width: '1440', height: '250'},
};
import echtholzboden_banner_xs_src from 'images/banners/assortment/echtholzboden.jpg?width=600&height=180&position=bottom&format=webp';
import echtholzboden_banner_sm_src from 'images/banners/assortment/echtholzboden.jpg?width=960&height=220&position=bottom&format=webp';
import echtholzboden_banner_md_src from 'images/banners/assortment/echtholzboden.jpg?width=1280&height=220&position=bottom&format=webp';
import echtholzboden_banner_lg_src from 'images/banners/assortment/echtholzboden.jpg?width=1440&height=250&position=bottom&format=webp';
export const echtholzboden_banner = {
	xs: {src: echtholzboden_banner_xs_src, id: 'echtholzboden_banner_xs', width: '600', height: '180'},
	sm: {src: echtholzboden_banner_sm_src, id: 'echtholzboden_banner_sm', width: '960', height: '220'},
	md: {src: echtholzboden_banner_md_src, id: 'echtholzboden_banner_md', width: '1280', height: '220'},
	lg: {src: echtholzboden_banner_lg_src, id: 'echtholzboden_banner_lg', width: '1440', height: '250'},
};
import bioboden_banner_xs_src from 'images/banners/assortment/bioboden.jpg?width=600&height=180&position=bottom&format=webp';
import bioboden_banner_sm_src from 'images/banners/assortment/bioboden.jpg?width=960&height=220&position=bottom&format=webp';
import bioboden_banner_md_src from 'images/banners/assortment/bioboden.jpg?width=1280&height=220&position=bottom&format=webp';
import bioboden_banner_lg_src from 'images/banners/assortment/bioboden.jpg?width=1440&height=250&position=bottom&format=webp';
export const bioboden_banner = {
	xs: {src: bioboden_banner_xs_src, id: 'bioboden_banner_xs', width: '600', height: '180'},
	sm: {src: bioboden_banner_sm_src, id: 'bioboden_banner_sm', width: '960', height: '220'},
	md: {src: bioboden_banner_md_src, id: 'bioboden_banner_md', width: '1280', height: '220'},
	lg: {src: bioboden_banner_lg_src, id: 'bioboden_banner_lg', width: '1440', height: '250'},
};
import korkboden_banner_xs_src from 'images/banners/assortment/korkboden.jpg?width=600&height=180&format=webp';
import korkboden_banner_sm_src from 'images/banners/assortment/korkboden.jpg?width=960&height=220&format=webp';
import korkboden_banner_md_src from 'images/banners/assortment/korkboden.jpg?width=1280&height=220&format=webp';
import korkboden_banner_lg_src from 'images/banners/assortment/korkboden.jpg?width=1440&height=250&format=webp';
export const korkboden_banner = {
	xs: {src: korkboden_banner_xs_src, id: 'korkboden_banner_xs', width: '600', height: '180'},
	sm: {src: korkboden_banner_sm_src, id: 'korkboden_banner_sm', width: '960', height: '220'},
	md: {src: korkboden_banner_md_src, id: 'korkboden_banner_md', width: '1280', height: '220'},
	lg: {src: korkboden_banner_lg_src, id: 'korkboden_banner_lg', width: '1440', height: '250'},
};
import paneele_retail_banner_xs_src from 'images/banners/assortment/paneele.jpg?width=600&height=180&format=webp';
import paneele_retail_banner_sm_src from 'images/banners/assortment/paneele.jpg?width=960&height=220&format=webp';
import paneele_retail_banner_md_src from 'images/banners/assortment/paneele.jpg?width=1280&height=220&format=webp';
import paneele_retail_banner_lg_src from 'images/banners/assortment/paneele.jpg?width=1440&height=250&format=webp';
export const paneele_retail_banner = {
	xs: {src: paneele_retail_banner_xs_src, id: 'paneele_retail_banner_xs', width: '600', height: '180'},
	sm: {src: paneele_retail_banner_sm_src, id: 'paneele_retail_banner_sm', width: '960', height: '220'},
	md: {src: paneele_retail_banner_md_src, id: 'paneele_retail_banner_md', width: '1280', height: '220'},
	lg: {src: paneele_retail_banner_lg_src, id: 'paneele_retail_banner_lg', width: '1440', height: '250'},
};
import paneele_wholesale_banner_xs_src from 'images/banners/assortment/akustikpaneele.jpg?width=600&height=180&format=webp';
import paneele_wholesale_banner_sm_src from 'images/banners/assortment/akustikpaneele.jpg?width=960&height=220&format=webp';
import paneele_wholesale_banner_md_src from 'images/banners/assortment/akustikpaneele.jpg?width=1280&height=220&format=webp';
import paneele_wholesale_banner_lg_src from 'images/banners/assortment/akustikpaneele.jpg?width=1440&height=250&format=webp';
export const paneele_wholesale_banner = {
	xs: {src: paneele_wholesale_banner_xs_src, id: 'paneele_wholesale_banner_xs', width: '600', height: '180'},
	sm: {src: paneele_wholesale_banner_sm_src, id: 'paneele_wholesale_banner_sm', width: '960', height: '220'},
	md: {src: paneele_wholesale_banner_md_src, id: 'paneele_wholesale_banner_md', width: '1280', height: '220'},
	lg: {src: paneele_wholesale_banner_lg_src, id: 'paneele_wholesale_banner_lg', width: '1440', height: '250'},
};
import treppensanierung_banner_xs_src from 'images/banners/assortment/treppensanierung.jpg?width=600&height=180&format=webp';
import treppensanierung_banner_sm_src from 'images/banners/assortment/treppensanierung.jpg?width=960&height=220&format=webp';
import treppensanierung_banner_md_src from 'images/banners/assortment/treppensanierung.jpg?width=1280&height=220&format=webp';
import treppensanierung_banner_lg_src from 'images/banners/assortment/treppensanierung.jpg?width=1440&height=250&format=webp';
export const treppensanierung_banner = {
	xs: {src: treppensanierung_banner_xs_src, id: 'treppensanierung_banner_xs', width: '600', height: '180'},
	sm: {src: treppensanierung_banner_sm_src, id: 'treppensanierung_banner_sm', width: '960', height: '220'},
	md: {src: treppensanierung_banner_md_src, id: 'treppensanierung_banner_md', width: '1280', height: '220'},
	lg: {src: treppensanierung_banner_lg_src, id: 'treppensanierung_banner_lg', width: '1440', height: '250'},
};
import garten_terrasse_banner_xs_src from 'images/banners/assortment/garten-terrasse.jpg?width=600&height=180&format=webp';
import garten_terrasse_banner_sm_src from 'images/banners/assortment/garten-terrasse.jpg?width=960&height=220&format=webp';
import garten_terrasse_banner_md_src from 'images/banners/assortment/garten-terrasse.jpg?width=1280&height=220&format=webp';
import garten_terrasse_banner_lg_src from 'images/banners/assortment/garten-terrasse.jpg?width=1440&height=250&format=webp';
export const garten_terrasse_banner = {
	xs: {src: garten_terrasse_banner_xs_src, id: 'garten_terrasse_banner_xs', width: '600', height: '180'},
	sm: {src: garten_terrasse_banner_sm_src, id: 'garten_terrasse_banner_sm', width: '960', height: '220'},
	md: {src: garten_terrasse_banner_md_src, id: 'garten_terrasse_banner_md', width: '1280', height: '220'},
	lg: {src: garten_terrasse_banner_lg_src, id: 'garten_terrasse_banner_lg', width: '1440', height: '250'},
};
import hq_gartenwelt_banner_xs_src from 'images/banners/assortment/hq-gartenwelt.jpg?width=600&height=180&format=webp';
import hq_gartenwelt_banner_sm_src from 'images/banners/assortment/hq-gartenwelt.jpg?width=960&height=220&format=webp';
import hq_gartenwelt_banner_md_src from 'images/banners/assortment/hq-gartenwelt.jpg?width=1280&height=220&format=webp';
import hq_gartenwelt_banner_lg_src from 'images/banners/assortment/hq-gartenwelt.jpg?width=1440&height=250&format=webp';
export const hq_gartenwelt_banner = {
	xs: {src: hq_gartenwelt_banner_xs_src, id: 'hq_gartenwelt_banner_xs', width: '600', height: '180'},
	sm: {src: hq_gartenwelt_banner_sm_src, id: 'hq_gartenwelt_banner_sm', width: '960', height: '220'},
	md: {src: hq_gartenwelt_banner_md_src, id: 'hq_gartenwelt_banner_md', width: '1280', height: '220'},
	lg: {src: hq_gartenwelt_banner_lg_src, id: 'hq_gartenwelt_banner_lg', width: '1440', height: '250'},
};
import terrasse_banner_xs_src from 'images/banners/assortment/terrasse.jpg?width=600&height=180&format=webp';
import terrasse_banner_sm_src from 'images/banners/assortment/terrasse.jpg?width=960&height=220&format=webp';
import terrasse_banner_md_src from 'images/banners/assortment/terrasse.jpg?width=1280&height=220&format=webp';
import terrasse_banner_lg_src from 'images/banners/assortment/terrasse.jpg?width=1440&height=250&format=webp';
export const terrasse_banner = {
	xs: {src: terrasse_banner_xs_src, id: 'terrasse_banner_xs', width: '600', height: '180'},
	sm: {src: terrasse_banner_sm_src, id: 'terrasse_banner_sm', width: '960', height: '220'},
	md: {src: terrasse_banner_md_src, id: 'terrasse_banner_md', width: '1280', height: '220'},
	lg: {src: terrasse_banner_lg_src, id: 'terrasse_banner_lg', width: '1440', height: '250'},
};
import zäune_sichtschutz_banner_xs_src from 'images/banners/assortment/zäune-sichtschutz.jpg?width=600&height=180&format=webp';
import zäune_sichtschutz_banner_sm_src from 'images/banners/assortment/zäune-sichtschutz.jpg?width=960&height=220&format=webp';
import zäune_sichtschutz_banner_md_src from 'images/banners/assortment/zäune-sichtschutz.jpg?width=1280&height=220&format=webp';
import zäune_sichtschutz_banner_lg_src from 'images/banners/assortment/zäune-sichtschutz.jpg?width=1440&height=250&format=webp';
export const zäune_sichtschutz_banner = {
	xs: {src: zäune_sichtschutz_banner_xs_src, id: 'zäune_sichtschutz_banner_xs', width: '600', height: '180'},
	sm: {src: zäune_sichtschutz_banner_sm_src, id: 'zäune_sichtschutz_banner_sm', width: '960', height: '220'},
	md: {src: zäune_sichtschutz_banner_md_src, id: 'zäune_sichtschutz_banner_md', width: '1280', height: '220'},
	lg: {src: zäune_sichtschutz_banner_lg_src, id: 'zäune_sichtschutz_banner_lg', width: '1440', height: '250'},
};
import gartenhäuser_carports_banner_xs_src from 'images/banners/assortment/gartenhäuser_carports.jpg?width=600&height=180&format=webp';
import gartenhäuser_carports_banner_sm_src from 'images/banners/assortment/gartenhäuser_carports.jpg?width=960&height=220&format=webp';
import gartenhäuser_carports_banner_md_src from 'images/banners/assortment/gartenhäuser_carports.jpg?width=1280&height=220&format=webp';
import gartenhäuser_carports_banner_lg_src from 'images/banners/assortment/gartenhäuser_carports.jpg?width=1440&height=250&format=webp';
export const gartenhäuser_carports_banner = {
	xs: {src: gartenhäuser_carports_banner_xs_src, id: 'gartenhäuser_carports_banner_xs', width: '600', height: '180'},
	sm: {src: gartenhäuser_carports_banner_sm_src, id: 'gartenhäuser_carports_banner_sm', width: '960', height: '220'},
	md: {src: gartenhäuser_carports_banner_md_src, id: 'gartenhäuser_carports_banner_md', width: '1280', height: '220'},
	lg: {src: gartenhäuser_carports_banner_lg_src, id: 'gartenhäuser_carports_banner_lg', width: '1440', height: '250'},
};
import gartenmöbel_banner_xs_src from 'images/banners/assortment/gartenmöbel.jpg?width=600&height=180&format=webp';
import gartenmöbel_banner_sm_src from 'images/banners/assortment/gartenmöbel.jpg?width=960&height=220&format=webp';
import gartenmöbel_banner_md_src from 'images/banners/assortment/gartenmöbel.jpg?width=1280&height=220&format=webp';
import gartenmöbel_banner_lg_src from 'images/banners/assortment/gartenmöbel.jpg?width=1440&height=250&format=webp';
export const gartenmöbel_banner = {
	xs: {src: gartenmöbel_banner_xs_src, id: 'gartenmöbel_banner_xs', width: '600', height: '180'},
	sm: {src: gartenmöbel_banner_sm_src, id: 'gartenmöbel_banner_sm', width: '960', height: '220'},
	md: {src: gartenmöbel_banner_md_src, id: 'gartenmöbel_banner_md', width: '1280', height: '220'},
	lg: {src: gartenmöbel_banner_lg_src, id: 'gartenmöbel_banner_lg', width: '1440', height: '250'},
};
import kinderwelt_banner_xs_src from 'images/banners/assortment/kinderwelt.jpg?width=600&height=180&format=webp';
import kinderwelt_banner_sm_src from 'images/banners/assortment/kinderwelt.jpg?width=960&height=220&format=webp';
import kinderwelt_banner_md_src from 'images/banners/assortment/kinderwelt.jpg?width=1280&height=220&format=webp';
import kinderwelt_banner_lg_src from 'images/banners/assortment/kinderwelt.jpg?width=1440&height=250&format=webp';
export const kinderwelt_banner = {
	xs: {src: kinderwelt_banner_xs_src, id: 'kinderwelt_banner_xs', width: '600', height: '180'},
	sm: {src: kinderwelt_banner_sm_src, id: 'kinderwelt_banner_sm', width: '960', height: '220'},
	md: {src: kinderwelt_banner_md_src, id: 'kinderwelt_banner_md', width: '1280', height: '220'},
	lg: {src: kinderwelt_banner_lg_src, id: 'kinderwelt_banner_lg', width: '1440', height: '250'},
};
import holzanstriche_pflege_banner_xs_src from 'images/banners/assortment/holzanstriche_pflege.jpg?width=600&height=180&format=webp';
import holzanstriche_pflege_banner_sm_src from 'images/banners/assortment/holzanstriche_pflege.jpg?width=960&height=220&format=webp';
import holzanstriche_pflege_banner_md_src from 'images/banners/assortment/holzanstriche_pflege.jpg?width=1280&height=220&format=webp';
import holzanstriche_pflege_banner_lg_src from 'images/banners/assortment/holzanstriche_pflege.jpg?width=1440&height=250&format=webp';
export const holzanstriche_pflege_banner = {
	xs: {src: holzanstriche_pflege_banner_xs_src, id: 'holzanstriche_pflege_banner_xs', width: '600', height: '180'},
	sm: {src: holzanstriche_pflege_banner_sm_src, id: 'holzanstriche_pflege_banner_sm', width: '960', height: '220'},
	md: {src: holzanstriche_pflege_banner_md_src, id: 'holzanstriche_pflege_banner_md', width: '1280', height: '220'},
	lg: {src: holzanstriche_pflege_banner_lg_src, id: 'holzanstriche_pflege_banner_lg', width: '1440', height: '250'},
};
import balkone_handläufe_banner_xs_src from 'images/banners/assortment/balkone_handläufe.jpg?width=600&height=180&format=webp';
import balkone_handläufe_banner_sm_src from 'images/banners/assortment/balkone_handläufe.jpg?width=960&height=220&format=webp';
import balkone_handläufe_banner_md_src from 'images/banners/assortment/balkone_handläufe.jpg?width=1280&height=220&format=webp';
import balkone_handläufe_banner_lg_src from 'images/banners/assortment/balkone_handläufe.jpg?width=1440&height=250&format=webp';
export const balkone_handläufe_banner = {
	xs: {src: balkone_handläufe_banner_xs_src, id: 'balkone_handläufe_banner_xs', width: '600', height: '180'},
	sm: {src: balkone_handläufe_banner_sm_src, id: 'balkone_handläufe_banner_sm', width: '960', height: '220'},
	md: {src: balkone_handläufe_banner_md_src, id: 'balkone_handläufe_banner_md', width: '1280', height: '220'},
	lg: {src: balkone_handläufe_banner_lg_src, id: 'balkone_handläufe_banner_lg', width: '1440', height: '250'},
};
import türen_bauelemente_banner_xs_src from 'images/banners/assortment/türen-bauelemente.jpg?width=600&height=180&format=webp';
import türen_bauelemente_banner_sm_src from 'images/banners/assortment/türen-bauelemente.jpg?width=960&height=220&format=webp';
import türen_bauelemente_banner_md_src from 'images/banners/assortment/türen-bauelemente.jpg?width=1280&height=220&format=webp';
import türen_bauelemente_banner_lg_src from 'images/banners/assortment/türen-bauelemente.jpg?width=1440&height=250&format=webp';
export const türen_bauelemente_banner = {
	xs: {src: türen_bauelemente_banner_xs_src, id: 'türen_bauelemente_banner_xs', width: '600', height: '180'},
	sm: {src: türen_bauelemente_banner_sm_src, id: 'türen_bauelemente_banner_sm', width: '960', height: '220'},
	md: {src: türen_bauelemente_banner_md_src, id: 'türen_bauelemente_banner_md', width: '1280', height: '220'},
	lg: {src: türen_bauelemente_banner_lg_src, id: 'türen_bauelemente_banner_lg', width: '1440', height: '250'},
};
import hq_tuerenwelt_banner_xs_src from 'images/banners/assortment/hq-türenwelt.jpg?width=600&height=180&format=webp';
import hq_tuerenwelt_banner_sm_src from 'images/banners/assortment/hq-türenwelt.jpg?width=960&height=220&format=webp';
import hq_tuerenwelt_banner_md_src from 'images/banners/assortment/hq-türenwelt.jpg?width=1280&height=220&format=webp';
import hq_tuerenwelt_banner_lg_src from 'images/banners/assortment/hq-türenwelt.jpg?width=1440&height=250&format=webp';
export const hq_tuerenwelt_banner = {
	xs: {src: hq_tuerenwelt_banner_xs_src, id: 'hq_tuerenwelt_banner_xs', width: '600', height: '180'},
	sm: {src: hq_tuerenwelt_banner_sm_src, id: 'hq_tuerenwelt_banner_sm', width: '960', height: '220'},
	md: {src: hq_tuerenwelt_banner_md_src, id: 'hq_tuerenwelt_banner_md', width: '1280', height: '220'},
	lg: {src: hq_tuerenwelt_banner_lg_src, id: 'hq_tuerenwelt_banner_lg', width: '1440', height: '250'},
};
import innentüren_banner_xs_src from 'images/banners/assortment/innentüren.jpg?width=600&height=180&format=webp';
import innentüren_banner_sm_src from 'images/banners/assortment/innentüren.jpg?width=960&height=220&format=webp';
import innentüren_banner_md_src from 'images/banners/assortment/innentüren.jpg?width=1280&height=220&format=webp';
import innentüren_banner_lg_src from 'images/banners/assortment/innentüren.jpg?width=1440&height=250&format=webp';
export const innentüren_banner = {
	xs: {src: innentüren_banner_xs_src, id: 'innentüren_banner_xs', width: '600', height: '180'},
	sm: {src: innentüren_banner_sm_src, id: 'innentüren_banner_sm', width: '960', height: '220'},
	md: {src: innentüren_banner_md_src, id: 'innentüren_banner_md', width: '1280', height: '220'},
	lg: {src: innentüren_banner_lg_src, id: 'innentüren_banner_lg', width: '1440', height: '250'},
};
import eingangstüren_banner_xs_src from 'images/banners/assortment/eingangstüren.jpg?width=600&height=180&format=webp';
import eingangstüren_banner_sm_src from 'images/banners/assortment/eingangstüren.jpg?width=960&height=220&format=webp';
import eingangstüren_banner_md_src from 'images/banners/assortment/eingangstüren.jpg?width=1280&height=220&format=webp';
import eingangstüren_banner_lg_src from 'images/banners/assortment/eingangstüren.jpg?width=1440&height=250&format=webp';
export const eingangstüren_banner = {
	xs: {src: eingangstüren_banner_xs_src, id: 'eingangstüren_banner_xs', width: '600', height: '180'},
	sm: {src: eingangstüren_banner_sm_src, id: 'eingangstüren_banner_sm', width: '960', height: '220'},
	md: {src: eingangstüren_banner_md_src, id: 'eingangstüren_banner_md', width: '1280', height: '220'},
	lg: {src: eingangstüren_banner_lg_src, id: 'eingangstüren_banner_lg', width: '1440', height: '250'},
};
import drückergarnituren_banner_xs_src from 'images/banners/assortment/drückergarnituren.jpg?width=600&height=180&format=webp';
import drückergarnituren_banner_sm_src from 'images/banners/assortment/drückergarnituren.jpg?width=960&height=220&format=webp';
import drückergarnituren_banner_md_src from 'images/banners/assortment/drückergarnituren.jpg?width=1280&height=220&format=webp';
import drückergarnituren_banner_lg_src from 'images/banners/assortment/drückergarnituren.jpg?width=1440&height=250&format=webp';
export const drückergarnituren_banner = {
	xs: {src: drückergarnituren_banner_xs_src, id: 'drückergarnituren_banner_xs', width: '600', height: '180'},
	sm: {src: drückergarnituren_banner_sm_src, id: 'drückergarnituren_banner_sm', width: '960', height: '220'},
	md: {src: drückergarnituren_banner_md_src, id: 'drückergarnituren_banner_md', width: '1280', height: '220'},
	lg: {src: drückergarnituren_banner_lg_src, id: 'drückergarnituren_banner_lg', width: '1440', height: '250'},
};
import türverglasung_banner_xs_src from 'images/banners/assortment/türverglasung.jpg?width=600&height=180&format=webp';
import türverglasung_banner_sm_src from 'images/banners/assortment/türverglasung.jpg?width=960&height=220&format=webp';
import türverglasung_banner_md_src from 'images/banners/assortment/türverglasung.jpg?width=1280&height=220&format=webp';
import türverglasung_banner_lg_src from 'images/banners/assortment/türverglasung.jpg?width=1440&height=250&format=webp';
export const türverglasung_banner = {
	xs: {src: türverglasung_banner_xs_src, id: 'türverglasung_banner_xs', width: '600', height: '180'},
	sm: {src: türverglasung_banner_sm_src, id: 'türverglasung_banner_sm', width: '960', height: '220'},
	md: {src: türverglasung_banner_md_src, id: 'türverglasung_banner_md', width: '1280', height: '220'},
	lg: {src: türverglasung_banner_lg_src, id: 'türverglasung_banner_lg', width: '1440', height: '250'},
};
import tor_center_banner_xs_src from 'images/banners/assortment/tor-center.jpg?width=600&height=180&format=webp';
import tor_center_banner_sm_src from 'images/banners/assortment/tor-center.jpg?width=960&height=220&format=webp';
import tor_center_banner_md_src from 'images/banners/assortment/tor-center.jpg?width=1280&height=220&format=webp';
import tor_center_banner_lg_src from 'images/banners/assortment/tor-center.jpg?width=1440&height=250&format=webp';
export const tor_center_banner = {
	xs: {src: tor_center_banner_xs_src, id: 'tor_center_banner_xs', width: '600', height: '180'},
	sm: {src: tor_center_banner_sm_src, id: 'tor_center_banner_sm', width: '960', height: '220'},
	md: {src: tor_center_banner_md_src, id: 'tor_center_banner_md', width: '1280', height: '220'},
	lg: {src: tor_center_banner_lg_src, id: 'tor_center_banner_lg', width: '1440', height: '250'},
};
import sauna_wellness_banner_xs_src from 'images/banners/assortment/sauna-wellness.jpg?width=600&height=180&position=bottom&format=webp';
import sauna_wellness_banner_sm_src from 'images/banners/assortment/sauna-wellness.jpg?width=960&height=220&position=bottom&format=webp';
import sauna_wellness_banner_md_src from 'images/banners/assortment/sauna-wellness.jpg?width=1280&height=220&position=bottom&format=webp';
import sauna_wellness_banner_lg_src from 'images/banners/assortment/sauna-wellness.jpg?width=1440&height=250&position=bottom&format=webp';
export const sauna_wellness_banner = {
	xs: {src: sauna_wellness_banner_xs_src, id: 'sauna_wellness_banner_xs', width: '600', height: '180'},
	sm: {src: sauna_wellness_banner_sm_src, id: 'sauna_wellness_banner_sm', width: '960', height: '220'},
	md: {src: sauna_wellness_banner_md_src, id: 'sauna_wellness_banner_md', width: '1280', height: '220'},
	lg: {src: sauna_wellness_banner_lg_src, id: 'sauna_wellness_banner_lg', width: '1440', height: '250'},
};
import innensauna_banner_xs_src from 'images/banners/assortment/innensauna.jpg?width=600&height=180&format=webp';
import innensauna_banner_sm_src from 'images/banners/assortment/innensauna.jpg?width=960&height=220&format=webp';
import innensauna_banner_md_src from 'images/banners/assortment/innensauna.jpg?width=1280&height=220&format=webp';
import innensauna_banner_lg_src from 'images/banners/assortment/innensauna.jpg?width=1440&height=250&format=webp';
export const innensauna_banner = {
	xs: {src: innensauna_banner_xs_src, id: 'innensauna_banner_xs', width: '600', height: '180'},
	sm: {src: innensauna_banner_sm_src, id: 'innensauna_banner_sm', width: '960', height: '220'},
	md: {src: innensauna_banner_md_src, id: 'innensauna_banner_md', width: '1280', height: '220'},
	lg: {src: innensauna_banner_lg_src, id: 'innensauna_banner_lg', width: '1440', height: '250'},
};
import aussensauna_banner_xs_src from 'images/banners/assortment/aussensauna.jpg?width=600&height=180&format=webp';
import aussensauna_banner_sm_src from 'images/banners/assortment/aussensauna.jpg?width=960&height=220&format=webp';
import aussensauna_banner_md_src from 'images/banners/assortment/aussensauna.jpg?width=1280&height=220&format=webp';
import aussensauna_banner_lg_src from 'images/banners/assortment/aussensauna.jpg?width=1440&height=250&format=webp';
export const aussensauna_banner = {
	xs: {src: aussensauna_banner_xs_src, id: 'aussensauna_banner_xs', width: '600', height: '180'},
	sm: {src: aussensauna_banner_sm_src, id: 'aussensauna_banner_sm', width: '960', height: '220'},
	md: {src: aussensauna_banner_md_src, id: 'aussensauna_banner_md', width: '1280', height: '220'},
	lg: {src: aussensauna_banner_lg_src, id: 'aussensauna_banner_lg', width: '1440', height: '250'},
};
import grillen_freizeit_banner_xs_src from 'images/banners/assortment/grillen-freizeit.jpg?width=600&height=180&format=webp';
import grillen_freizeit_banner_sm_src from 'images/banners/assortment/grillen-freizeit.jpg?width=960&height=220&format=webp';
import grillen_freizeit_banner_md_src from 'images/banners/assortment/grillen-freizeit.jpg?width=1280&height=220&format=webp';
import grillen_freizeit_banner_lg_src from 'images/banners/assortment/grillen-freizeit.jpg?width=1440&height=250&format=webp';
export const grillen_freizeit_banner = {
	xs: {src: grillen_freizeit_banner_xs_src, id: 'grillen_freizeit_banner_xs', width: '600', height: '180'},
	sm: {src: grillen_freizeit_banner_sm_src, id: 'grillen_freizeit_banner_sm', width: '960', height: '220'},
	md: {src: grillen_freizeit_banner_md_src, id: 'grillen_freizeit_banner_md', width: '1280', height: '220'},
	lg: {src: grillen_freizeit_banner_lg_src, id: 'grillen_freizeit_banner_lg', width: '1440', height: '250'},
};
import technischer_industriebedarf_banner_xs_src from 'images/banners/assortment/technischer-industriebedarf.jpg?width=600&height=180&format=webp';
import technischer_industriebedarf_banner_sm_src from 'images/banners/assortment/technischer-industriebedarf.jpg?width=960&height=220&format=webp';
import technischer_industriebedarf_banner_md_src from 'images/banners/assortment/technischer-industriebedarf.jpg?width=1280&height=220&format=webp';
import technischer_industriebedarf_banner_lg_src from 'images/banners/assortment/technischer-industriebedarf.jpg?width=1440&height=250&format=webp';
export const technischer_industriebedarf_banner = {
	xs: {src: technischer_industriebedarf_banner_xs_src, id: 'technischer_industriebedarf_banner_xs', width: '600', height: '180'},
	sm: {src: technischer_industriebedarf_banner_sm_src, id: 'technischer_industriebedarf_banner_sm', width: '960', height: '220'},
	md: {src: technischer_industriebedarf_banner_md_src, id: 'technischer_industriebedarf_banner_md', width: '1280', height: '220'},
	lg: {src: technischer_industriebedarf_banner_lg_src, id: 'technischer_industriebedarf_banner_lg', width: '1440', height: '250'},
};
import antik_altholz_banner_xs_src from 'images/banners/assortment/antik-altholz.jpg?width=600&height=180&format=webp';
import antik_altholz_banner_sm_src from 'images/banners/assortment/antik-altholz.jpg?width=960&height=220&format=webp';
import antik_altholz_banner_md_src from 'images/banners/assortment/antik-altholz.jpg?width=1280&height=220&format=webp';
import antik_altholz_banner_lg_src from 'images/banners/assortment/antik-altholz.jpg?width=1440&height=250&format=webp';
export const antik_altholz_banner = {
	xs: {src: antik_altholz_banner_xs_src, id: 'antik_altholz_banner_xs', width: '600', height: '180'},
	sm: {src: antik_altholz_banner_sm_src, id: 'antik_altholz_banner_sm', width: '960', height: '220'},
	md: {src: antik_altholz_banner_md_src, id: 'antik_altholz_banner_md', width: '1280', height: '220'},
	lg: {src: antik_altholz_banner_lg_src, id: 'antik_altholz_banner_lg', width: '1440', height: '250'},
};
import exhibition1_banner_xs_src from 'images/banners/ausstellung-chemnitz.jpg?width=600&height=180&format=webp';
import exhibition1_banner_sm_src from 'images/banners/ausstellung-chemnitz.jpg?width=960&height=220&format=webp';
import exhibition1_banner_md_src from 'images/banners/ausstellung-chemnitz.jpg?width=1280&height=220&format=webp';
import exhibition1_banner_lg_src from 'images/banners/ausstellung-chemnitz.jpg?width=1440&height=250&format=webp';
export const exhibition1_banner = {
	xs: {src: exhibition1_banner_xs_src, id: 'exhibition1_banner_xs', width: '600', height: '180'},
	sm: {src: exhibition1_banner_sm_src, id: 'exhibition1_banner_sm', width: '960', height: '220'},
	md: {src: exhibition1_banner_md_src, id: 'exhibition1_banner_md', width: '1280', height: '220'},
	lg: {src: exhibition1_banner_lg_src, id: 'exhibition1_banner_lg', width: '1440', height: '250'},
};
import exhibition3_banner_xs_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=600&height=180&format=webp';
import exhibition3_banner_sm_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=960&height=220&format=webp';
import exhibition3_banner_md_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=1280&height=220&format=webp';
import exhibition3_banner_lg_src from 'images/banners/assortment/holzfabrik-erzgebirge.jpg?width=1440&height=250&format=webp';
export const exhibition3_banner = {
	xs: {src: exhibition3_banner_xs_src, id: 'exhibition3_banner_xs', width: '600', height: '180'},
	sm: {src: exhibition3_banner_sm_src, id: 'exhibition3_banner_sm', width: '960', height: '220'},
	md: {src: exhibition3_banner_md_src, id: 'exhibition3_banner_md', width: '1280', height: '220'},
	lg: {src: exhibition3_banner_lg_src, id: 'exhibition3_banner_lg', width: '1440', height: '250'},
};
import exhibition4_banner_xs_src from 'images/banners/abholung.jpg?width=600&height=180&format=webp';
import exhibition4_banner_sm_src from 'images/banners/abholung.jpg?width=960&height=220&format=webp';
import exhibition4_banner_md_src from 'images/banners/abholung.jpg?width=1280&height=220&format=webp';
import exhibition4_banner_lg_src from 'images/banners/abholung.jpg?width=1440&height=250&format=webp';
export const exhibition4_banner = {
	xs: {src: exhibition4_banner_xs_src, id: 'exhibition4_banner_xs', width: '600', height: '180'},
	sm: {src: exhibition4_banner_sm_src, id: 'exhibition4_banner_sm', width: '960', height: '220'},
	md: {src: exhibition4_banner_md_src, id: 'exhibition4_banner_md', width: '1280', height: '220'},
	lg: {src: exhibition4_banner_lg_src, id: 'exhibition4_banner_lg', width: '1440', height: '250'},
};
import craftsman_agency_banner_xs_src from 'images/banners/handwerkervermittlung.jpg?width=600&height=180&format=webp';
import craftsman_agency_banner_sm_src from 'images/banners/handwerkervermittlung.jpg?width=960&height=220&format=webp';
import craftsman_agency_banner_md_src from 'images/banners/handwerkervermittlung.jpg?width=1280&height=220&format=webp';
import craftsman_agency_banner_lg_src from 'images/banners/handwerkervermittlung.jpg?width=1440&height=250&format=webp';
export const craftsman_agency_banner = {
	xs: {src: craftsman_agency_banner_xs_src, id: 'craftsman_agency_banner_xs', width: '600', height: '180'},
	sm: {src: craftsman_agency_banner_sm_src, id: 'craftsman_agency_banner_sm', width: '960', height: '220'},
	md: {src: craftsman_agency_banner_md_src, id: 'craftsman_agency_banner_md', width: '1280', height: '220'},
	lg: {src: craftsman_agency_banner_lg_src, id: 'craftsman_agency_banner_lg', width: '1440', height: '250'},
};
import c_kipp_hacker_src from 'images/cards/team/charlie_kipp-hacker.jpg?width=280&height=233&format=webp';
export const c_kipp_hacker = {
	src: c_kipp_hacker_src, id: 'c_kipp_hacker', width: '280', height: '233'
};
import b_fischer_src from 'images/cards/team/bertram_fischer.jpg?width=280&height=233&format=webp';
export const b_fischer = {
	src: b_fischer_src, id: 'b_fischer', width: '280', height: '233'
};
import j_frings_src from 'images/cards/team/jana_frings.jpg?width=280&height=233&format=webp';
export const j_frings = {
	src: j_frings_src, id: 'j_frings', width: '280', height: '233'
};
import m_goldmann_src from 'images/cards/team/michael_goldmann.jpg?width=280&height=233&format=webp';
export const m_goldmann = {
	src: m_goldmann_src, id: 'm_goldmann', width: '280', height: '233'
};
import r_oelmann_src from 'images/cards/team/rene_oelmann.jpg?width=280&height=233&format=webp';
export const r_oelmann = {
	src: r_oelmann_src, id: 'r_oelmann', width: '280', height: '233'
};
import f_hofer_src from 'images/cards/team/fritz_hofer.jpg?width=280&height=233&format=webp';
export const f_hofer = {
	src: f_hofer_src, id: 'f_hofer', width: '280', height: '233'
};
import m_nejedla_src from 'images/cards/team/michaela_nejedla.jpg?width=280&height=233&format=webp';
export const m_nejedla = {
	src: m_nejedla_src, id: 'm_nejedla', width: '280', height: '233'
};
import s_doerffel_src from 'images/cards/team/sven_doerffel.jpg?width=280&height=233&format=webp';
export const s_doerffel = {
	src: s_doerffel_src, id: 's_doerffel', width: '280', height: '233'
};
import m_schmitt_src from 'images/cards/team/michael_schmitt.jpg?width=280&height=233&format=webp';
export const m_schmitt = {
	src: m_schmitt_src, id: 'm_schmitt', width: '280', height: '233'
};
import u_weidauer_src from 'images/cards/team/ulrich_weidauer.jpg?width=280&height=233&format=webp';
export const u_weidauer = {
	src: u_weidauer_src, id: 'u_weidauer', width: '280', height: '233'
};
import j_dietrich_src from 'images/cards/team/jan_dietrich.jpg?width=280&height=233&format=webp';
export const j_dietrich = {
	src: j_dietrich_src, id: 'j_dietrich', width: '280', height: '233'
};
import n_weickert_src from 'images/cards/team/nick_weickert.jpg?width=280&height=233&format=webp';
export const n_weickert = {
	src: n_weickert_src, id: 'n_weickert', width: '280', height: '233'
};
import d_mueller_src from 'images/cards/team/diana_mueller.jpg?width=280&height=233&format=webp';
export const d_mueller = {
	src: d_mueller_src, id: 'd_mueller', width: '280', height: '233'
};
import m_waschitzka_src from 'images/cards/team/marvin_waschitzka.jpg?width=280&height=233&format=webp';
export const m_waschitzka = {
	src: m_waschitzka_src, id: 'm_waschitzka', width: '280', height: '233'
};
import a_schallau_src from 'images/cards/team/annett_schallau.jpg?width=280&height=233&format=webp';
export const a_schallau = {
	src: a_schallau_src, id: 'a_schallau', width: '280', height: '233'
};
import m_bauer_src from 'images/cards/team/mike_bauer.jpg?width=280&height=233&format=webp';
export const m_bauer = {
	src: m_bauer_src, id: 'm_bauer', width: '280', height: '233'
};
import s_seidel_src from 'images/cards/team/sarah_seidel.jpg?width=280&height=233&format=webp';
export const s_seidel = {
	src: s_seidel_src, id: 's_seidel', width: '280', height: '233'
};
import a_ehrlich_src from 'images/cards/team/amelie_ehrlich.jpg?width=280&height=233&format=webp';
export const a_ehrlich = {
	src: a_ehrlich_src, id: 'a_ehrlich', width: '280', height: '233'
};
import u_ziller_src from 'images/cards/team/uwe_ziller.jpg?width=280&height=233&format=webp';
export const u_ziller = {
	src: u_ziller_src, id: 'u_ziller', width: '280', height: '233'
};
import s_weidauer_src from 'images/cards/team/steffen_weidauer.jpg?width=280&height=233&format=webp';
export const s_weidauer = {
	src: s_weidauer_src, id: 's_weidauer', width: '280', height: '233'
};
import sponsor_tier1_bronze_1_card_src from 'images/cards/treeplanting/Logo_SBV.jpg?format=webp';
export const sponsor_tier1_bronze_1_card = {
	src: sponsor_tier1_bronze_1_card_src, id: 'sponsor_tier1_bronze_1_card'
};
import sponsor_treeplanting_logo_card_src from 'images/logos/logo-baumpflanzaktion.svg?format=webp';
export const sponsor_treeplanting_logo_card = {
	src: sponsor_treeplanting_logo_card_src, id: 'sponsor_treeplanting_logo_card'
};
import sponsor_tier2_gold_1_card_src from 'images/cards/treeplanting/AHL_Logo_gross.png?format=webp';
export const sponsor_tier2_gold_1_card = {
	src: sponsor_tier2_gold_1_card_src, id: 'sponsor_tier2_gold_1_card'
};
import sponsor_tier2_gold_2_card_src from 'images/cards/treeplanting/Logo-HEINRICH-SCHMID.jpg?format=webp';
export const sponsor_tier2_gold_2_card = {
	src: sponsor_tier2_gold_2_card_src, id: 'sponsor_tier2_gold_2_card'
};
import sponsor_tier2_gold_3_card_src from 'images/cards/treeplanting/Logo_tischlerei_koehler_wiesa_werkzeug.jpg?format=webp';
export const sponsor_tier2_gold_3_card = {
	src: sponsor_tier2_gold_3_card_src, id: 'sponsor_tier2_gold_3_card'
};
import sponsor_tier2_gold_4_card_src from 'images/cards/treeplanting/gemeinhardt-service.png?format=webp';
export const sponsor_tier2_gold_4_card = {
	src: sponsor_tier2_gold_4_card_src, id: 'sponsor_tier2_gold_4_card'
};
import sponsor_tier2_silver_1_card_src from 'images/cards/treeplanting/Logo-HENKA.png?format=webp';
export const sponsor_tier2_silver_1_card = {
	src: sponsor_tier2_silver_1_card_src, id: 'sponsor_tier2_silver_1_card'
};
import sponsor_tier2_silver_2_card_src from 'images/cards/treeplanting/Logo-Zimmerei-Nitzsche.jpg?format=webp';
export const sponsor_tier2_silver_2_card = {
	src: sponsor_tier2_silver_2_card_src, id: 'sponsor_tier2_silver_2_card'
};
import sponsor_tier2_silver_3_card_src from 'images/cards/treeplanting/Logo-Zimmerei-Knauth.jpg?format=webp';
export const sponsor_tier2_silver_3_card = {
	src: sponsor_tier2_silver_3_card_src, id: 'sponsor_tier2_silver_3_card'
};
import sponsor_tier2_silver_4_card_src from 'images/cards/treeplanting/Logo-KÖHLER-Bautischlerei.jpg?format=webp';
export const sponsor_tier2_silver_4_card = {
	src: sponsor_tier2_silver_4_card_src, id: 'sponsor_tier2_silver_4_card'
};
import sponsor_tier2_silver_5_card_src from 'images/cards/treeplanting/Logo-MHR.jpg?format=webp';
export const sponsor_tier2_silver_5_card = {
	src: sponsor_tier2_silver_5_card_src, id: 'sponsor_tier2_silver_5_card'
};
import sponsor_tier2_silver_6_card_src from 'images/cards/treeplanting/Logo-HIRSACK.jpg?format=webp';
export const sponsor_tier2_silver_6_card = {
	src: sponsor_tier2_silver_6_card_src, id: 'sponsor_tier2_silver_6_card'
};
import sponsor_tier2_silver_7_card_src from 'images/cards/treeplanting/Logo-GRZANNA.jpg?format=webp';
export const sponsor_tier2_silver_7_card = {
	src: sponsor_tier2_silver_7_card_src, id: 'sponsor_tier2_silver_7_card'
};
import sponsor_tier2_silver_8_card_src from 'images/cards/treeplanting/Logo-Gebr-DONNER.jpg?format=webp';
export const sponsor_tier2_silver_8_card = {
	src: sponsor_tier2_silver_8_card_src, id: 'sponsor_tier2_silver_8_card'
};
import sponsor_tier2_silver_9_card_src from 'images/cards/treeplanting/Logo-ALBERT.jpg?format=webp';
export const sponsor_tier2_silver_9_card = {
	src: sponsor_tier2_silver_9_card_src, id: 'sponsor_tier2_silver_9_card'
};
import sponsor_tier2_silver_10_card_src from 'images/cards/treeplanting/Logo-Bauservice-Doehler.png?format=webp';
export const sponsor_tier2_silver_10_card = {
	src: sponsor_tier2_silver_10_card_src, id: 'sponsor_tier2_silver_10_card'
};
import sponsor_tier2_silver_11_card_src from 'images/cards/treeplanting/Logo-Allianzvertretung-Katrin-Heyer.jpg?format=webp';
export const sponsor_tier2_silver_11_card = {
	src: sponsor_tier2_silver_11_card_src, id: 'sponsor_tier2_silver_11_card'
};
import sponsor_tier2_silver_12_card_src from 'images/cards/treeplanting/Logo-FensterVision.jpg?format=webp';
export const sponsor_tier2_silver_12_card = {
	src: sponsor_tier2_silver_12_card_src, id: 'sponsor_tier2_silver_12_card'
};
import sponsor_tier2_silver_13_card_src from 'images/cards/treeplanting/Logo-Tischlerei-Stietzel.jpg?format=webp';
export const sponsor_tier2_silver_13_card = {
	src: sponsor_tier2_silver_13_card_src, id: 'sponsor_tier2_silver_13_card'
};
import sponsor_tier2_silver_14_card_src from 'images/cards/treeplanting/Logo-Zimmerei-Vogel.jpg?format=webp';
export const sponsor_tier2_silver_14_card = {
	src: sponsor_tier2_silver_14_card_src, id: 'sponsor_tier2_silver_14_card'
};
import sponsor_tier2_silver_15_card_src from 'images/cards/treeplanting/Logo-GESA.jpg?format=webp';
export const sponsor_tier2_silver_15_card = {
	src: sponsor_tier2_silver_15_card_src, id: 'sponsor_tier2_silver_15_card'
};
import sponsor_tier2_silver_16_card_src from 'images/cards/treeplanting/Logo-Zimmerei-Wiedrich.jpg?format=webp';
export const sponsor_tier2_silver_16_card = {
	src: sponsor_tier2_silver_16_card_src, id: 'sponsor_tier2_silver_16_card'
};
import sponsor_tier2_silver_17_card_src from 'images/cards/treeplanting/Logo-KGW-Bausanierung.jpg?format=webp';
export const sponsor_tier2_silver_17_card = {
	src: sponsor_tier2_silver_17_card_src, id: 'sponsor_tier2_silver_17_card'
};
import sponsor_tier2_silver_18_card_src from 'images/logos/tib-logo.svg?format=webp';
export const sponsor_tier2_silver_18_card = {
	src: sponsor_tier2_silver_18_card_src, id: 'sponsor_tier2_silver_18_card'
};
import sponsor_tier2_bronze_1_card_src from 'images/cards/treeplanting/Logo-Thomas-Gehart.jpg?format=webp';
export const sponsor_tier2_bronze_1_card = {
	src: sponsor_tier2_bronze_1_card_src, id: 'sponsor_tier2_bronze_1_card'
};
import sponsor_tier2_bronze_2_card_src from 'images/cards/treeplanting/Logo-MÜLLER+RICHTER.png?format=webp';
export const sponsor_tier2_bronze_2_card = {
	src: sponsor_tier2_bronze_2_card_src, id: 'sponsor_tier2_bronze_2_card'
};
import sponsor_tier2_bronze_3_card_src from 'images/cards/treeplanting/Logo-Wuestenrot-Bausparkasse.png?format=webp';
export const sponsor_tier2_bronze_3_card = {
	src: sponsor_tier2_bronze_3_card_src, id: 'sponsor_tier2_bronze_3_card'
};
import sponsor_tier2_bronze_4_card_src from 'images/cards/treeplanting/Logo-Massivholz-Design.jpg?format=webp';
export const sponsor_tier2_bronze_4_card = {
	src: sponsor_tier2_bronze_4_card_src, id: 'sponsor_tier2_bronze_4_card'
};
import sponsor_tier2_bronze_5_card_src from 'images/cards/treeplanting/Logo-Omeras.jpg?format=webp';
export const sponsor_tier2_bronze_5_card = {
	src: sponsor_tier2_bronze_5_card_src, id: 'sponsor_tier2_bronze_5_card'
};
import sponsor_tier2_bronze_6_card_src from 'images/cards/treeplanting/Logo-WGC.jpg?format=webp';
export const sponsor_tier2_bronze_6_card = {
	src: sponsor_tier2_bronze_6_card_src, id: 'sponsor_tier2_bronze_6_card'
};
import sponsor_tier2_bronze_7_card_src from 'images/cards/treeplanting/Logo_Metallbau_Bernt.jpg?format=webp';
export const sponsor_tier2_bronze_7_card = {
	src: sponsor_tier2_bronze_7_card_src, id: 'sponsor_tier2_bronze_7_card'
};
import sponsor_tier2_bronze_8_card_src from 'images/cards/treeplanting/dietrich_grund_visite.png?format=webp';
export const sponsor_tier2_bronze_8_card = {
	src: sponsor_tier2_bronze_8_card_src, id: 'sponsor_tier2_bronze_8_card'
};
import partner_partnerRetail_5_card_src from 'images/cards/partner/logo_admeso.png?format=webp';
export const partner_partnerRetail_5_card = {
	src: partner_partnerRetail_5_card_src, id: 'partner_partnerRetail_5_card'
};
import partner_partnerRetail_1_card_src from 'images/cards/partner/logo_AHL.png?format=webp';
export const partner_partnerRetail_1_card = {
	src: partner_partnerRetail_1_card_src, id: 'partner_partnerRetail_1_card'
};
import partner_partnerRetail_4_card_src from 'images/cards/partner/logo_Braumanufaktur_Munzner.png?format=webp';
export const partner_partnerRetail_4_card = {
	src: partner_partnerRetail_4_card_src, id: 'partner_partnerRetail_4_card'
};
import partner_partnerRetail_6_card_src from 'images/cards/partner/logo_Ferienanlage_Rabenstein.png?format=webp';
export const partner_partnerRetail_6_card = {
	src: partner_partnerRetail_6_card_src, id: 'partner_partnerRetail_6_card'
};
import partner_partnerRetail_3_card_src from 'images/cards/partner/logo_Fleischerei-Körner.png?format=webp';
export const partner_partnerRetail_3_card = {
	src: partner_partnerRetail_3_card_src, id: 'partner_partnerRetail_3_card'
};
import partner_partnerRetail_2_card_src from 'images/cards/partner/logo_GRAENITZ.png?format=webp';
export const partner_partnerRetail_2_card = {
	src: partner_partnerRetail_2_card_src, id: 'partner_partnerRetail_2_card'
};
import partner_partnerRetail_7_card_src from 'images/cards/partner/logo_SV_Handwerk Rabenstein.png?format=webp';
export const partner_partnerRetail_7_card = {
	src: partner_partnerRetail_7_card_src, id: 'partner_partnerRetail_7_card'
};
import gallery_dji_0278_xs_src from 'images/galleries/treeplanting/02042022/dji_0278.jpg?width=480&height=360&format=webp';
import gallery_dji_0278_xl_src from 'images/galleries/treeplanting/02042022/dji_0278.jpg?width=1920&height=1438&format=webp';
export const gallery_dji_0278 = {
	xs: {src: gallery_dji_0278_xs_src, id: 'gallery_dji_0278_xs', width: '480', height: '360'},
	xl: {src: gallery_dji_0278_xl_src, id: 'gallery_dji_0278_xl', width: '1920', height: '1438'},
};
import gallery_800a8094_xs_src from 'images/galleries/treeplanting/02042022/800a8094.jpg?width=480&height=320&format=webp';
import gallery_800a8094_xl_src from 'images/galleries/treeplanting/02042022/800a8094.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8094 = {
	xs: {src: gallery_800a8094_xs_src, id: 'gallery_800a8094_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8094_xl_src, id: 'gallery_800a8094_xl', width: '1920', height: '1280'},
};
import gallery_800a8067_xs_src from 'images/galleries/treeplanting/02042022/800a8067.jpg?width=480&height=320&format=webp';
import gallery_800a8067_xl_src from 'images/galleries/treeplanting/02042022/800a8067.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8067 = {
	xs: {src: gallery_800a8067_xs_src, id: 'gallery_800a8067_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8067_xl_src, id: 'gallery_800a8067_xl', width: '1920', height: '1280'},
};
import gallery_800a8046_xs_src from 'images/galleries/treeplanting/02042022/800a8046.jpg?width=480&height=320&format=webp';
import gallery_800a8046_xl_src from 'images/galleries/treeplanting/02042022/800a8046.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8046 = {
	xs: {src: gallery_800a8046_xs_src, id: 'gallery_800a8046_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8046_xl_src, id: 'gallery_800a8046_xl', width: '1920', height: '1280'},
};
import gallery_800a8030_xs_src from 'images/galleries/treeplanting/02042022/800a8030.jpg?width=480&height=720&format=webp';
import gallery_800a8030_xl_src from 'images/galleries/treeplanting/02042022/800a8030.jpg?width=1920&height=2880&format=webp';
export const gallery_800a8030 = {
	xs: {src: gallery_800a8030_xs_src, id: 'gallery_800a8030_xs', width: '480', height: '720'},
	xl: {src: gallery_800a8030_xl_src, id: 'gallery_800a8030_xl', width: '1920', height: '2880'},
};
import gallery_800a8005_xs_src from 'images/galleries/treeplanting/02042022/800a8005.jpg?width=480&height=320&format=webp';
import gallery_800a8005_xl_src from 'images/galleries/treeplanting/02042022/800a8005.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8005 = {
	xs: {src: gallery_800a8005_xs_src, id: 'gallery_800a8005_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8005_xl_src, id: 'gallery_800a8005_xl', width: '1920', height: '1280'},
};
import gallery_800a8000_xs_src from 'images/galleries/treeplanting/02042022/800a8000.jpg?width=480&height=320&format=webp';
import gallery_800a8000_xl_src from 'images/galleries/treeplanting/02042022/800a8000.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8000 = {
	xs: {src: gallery_800a8000_xs_src, id: 'gallery_800a8000_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8000_xl_src, id: 'gallery_800a8000_xl', width: '1920', height: '1280'},
};
import gallery_800a7826_xs_src from 'images/galleries/treeplanting/02042022/800a7826.jpg?width=480&height=320&format=webp';
import gallery_800a7826_xl_src from 'images/galleries/treeplanting/02042022/800a7826.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7826 = {
	xs: {src: gallery_800a7826_xs_src, id: 'gallery_800a7826_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7826_xl_src, id: 'gallery_800a7826_xl', width: '1920', height: '1280'},
};
import gallery_800a7769_xs_src from 'images/galleries/treeplanting/02042022/800a7769.jpg?width=480&height=320&format=webp';
import gallery_800a7769_xl_src from 'images/galleries/treeplanting/02042022/800a7769.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7769 = {
	xs: {src: gallery_800a7769_xs_src, id: 'gallery_800a7769_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7769_xl_src, id: 'gallery_800a7769_xl', width: '1920', height: '1280'},
};
import gallery_800a7678_xs_src from 'images/galleries/treeplanting/02042022/800a7678.jpg?width=480&height=320&format=webp';
import gallery_800a7678_xl_src from 'images/galleries/treeplanting/02042022/800a7678.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7678 = {
	xs: {src: gallery_800a7678_xs_src, id: 'gallery_800a7678_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7678_xl_src, id: 'gallery_800a7678_xl', width: '1920', height: '1280'},
};
import gallery_800a7655_xs_src from 'images/galleries/treeplanting/02042022/800a7655.jpg?width=480&height=320&format=webp';
import gallery_800a7655_xl_src from 'images/galleries/treeplanting/02042022/800a7655.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7655 = {
	xs: {src: gallery_800a7655_xs_src, id: 'gallery_800a7655_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7655_xl_src, id: 'gallery_800a7655_xl', width: '1920', height: '1280'},
};
import gallery_800a7587_xs_src from 'images/galleries/treeplanting/02042022/800a7587.jpg?width=480&height=320&format=webp';
import gallery_800a7587_xl_src from 'images/galleries/treeplanting/02042022/800a7587.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7587 = {
	xs: {src: gallery_800a7587_xs_src, id: 'gallery_800a7587_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7587_xl_src, id: 'gallery_800a7587_xl', width: '1920', height: '1280'},
};
import gallery_800a7579_xs_src from 'images/galleries/treeplanting/02042022/800a7579.jpg?width=480&height=320&format=webp';
import gallery_800a7579_xl_src from 'images/galleries/treeplanting/02042022/800a7579.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7579 = {
	xs: {src: gallery_800a7579_xs_src, id: 'gallery_800a7579_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7579_xl_src, id: 'gallery_800a7579_xl', width: '1920', height: '1280'},
};
import gallery_800a7444_xs_src from 'images/galleries/treeplanting/02042022/800a7444.jpg?width=480&height=320&format=webp';
import gallery_800a7444_xl_src from 'images/galleries/treeplanting/02042022/800a7444.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7444 = {
	xs: {src: gallery_800a7444_xs_src, id: 'gallery_800a7444_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7444_xl_src, id: 'gallery_800a7444_xl', width: '1920', height: '1280'},
};
import gallery_800a7424_xs_src from 'images/galleries/treeplanting/02042022/800a7424.jpg?width=480&height=320&format=webp';
import gallery_800a7424_xl_src from 'images/galleries/treeplanting/02042022/800a7424.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7424 = {
	xs: {src: gallery_800a7424_xs_src, id: 'gallery_800a7424_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7424_xl_src, id: 'gallery_800a7424_xl', width: '1920', height: '1280'},
};
import gallery_800a7390_xs_src from 'images/galleries/treeplanting/02042022/800a7390.jpg?width=480&height=320&format=webp';
import gallery_800a7390_xl_src from 'images/galleries/treeplanting/02042022/800a7390.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7390 = {
	xs: {src: gallery_800a7390_xs_src, id: 'gallery_800a7390_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7390_xl_src, id: 'gallery_800a7390_xl', width: '1920', height: '1280'},
};
import gallery_800a7378_xs_src from 'images/galleries/treeplanting/02042022/800a7378.jpg?width=480&height=320&format=webp';
import gallery_800a7378_xl_src from 'images/galleries/treeplanting/02042022/800a7378.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7378 = {
	xs: {src: gallery_800a7378_xs_src, id: 'gallery_800a7378_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7378_xl_src, id: 'gallery_800a7378_xl', width: '1920', height: '1280'},
};
import gallery_800a7357_xs_src from 'images/galleries/treeplanting/02042022/800a7357.jpg?width=480&height=320&format=webp';
import gallery_800a7357_xl_src from 'images/galleries/treeplanting/02042022/800a7357.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7357 = {
	xs: {src: gallery_800a7357_xs_src, id: 'gallery_800a7357_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7357_xl_src, id: 'gallery_800a7357_xl', width: '1920', height: '1280'},
};
import gallery_800a7359_xs_src from 'images/galleries/treeplanting/02042022/800a7359.jpg?width=480&height=320&format=webp';
import gallery_800a7359_xl_src from 'images/galleries/treeplanting/02042022/800a7359.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7359 = {
	xs: {src: gallery_800a7359_xs_src, id: 'gallery_800a7359_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7359_xl_src, id: 'gallery_800a7359_xl', width: '1920', height: '1280'},
};
import gallery_800a7343_xs_src from 'images/galleries/treeplanting/02042022/800a7343.jpg?width=480&height=320&format=webp';
import gallery_800a7343_xl_src from 'images/galleries/treeplanting/02042022/800a7343.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7343 = {
	xs: {src: gallery_800a7343_xs_src, id: 'gallery_800a7343_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7343_xl_src, id: 'gallery_800a7343_xl', width: '1920', height: '1280'},
};
import gallery_800a7346_xs_src from 'images/galleries/treeplanting/02042022/800a7346.jpg?width=480&height=320&format=webp';
import gallery_800a7346_xl_src from 'images/galleries/treeplanting/02042022/800a7346.jpg?width=1920&height=1280&format=webp';
export const gallery_800a7346 = {
	xs: {src: gallery_800a7346_xs_src, id: 'gallery_800a7346_xs', width: '480', height: '320'},
	xl: {src: gallery_800a7346_xl_src, id: 'gallery_800a7346_xl', width: '1920', height: '1280'},
};
import gallery_800a9444_xs_src from 'images/galleries/treeplanting/23042022/800a9444.jpg?width=480&height=320&format=webp';
import gallery_800a9444_xl_src from 'images/galleries/treeplanting/23042022/800a9444.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9444 = {
	xs: {src: gallery_800a9444_xs_src, id: 'gallery_800a9444_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9444_xl_src, id: 'gallery_800a9444_xl', width: '1920', height: '1280'},
};
import gallery_800a9425_xs_src from 'images/galleries/treeplanting/23042022/800a9425.jpg?width=480&height=320&format=webp';
import gallery_800a9425_xl_src from 'images/galleries/treeplanting/23042022/800a9425.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9425 = {
	xs: {src: gallery_800a9425_xs_src, id: 'gallery_800a9425_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9425_xl_src, id: 'gallery_800a9425_xl', width: '1920', height: '1280'},
};
import gallery_800a9393_xs_src from 'images/galleries/treeplanting/23042022/800a9393.jpg?width=480&height=320&format=webp';
import gallery_800a9393_xl_src from 'images/galleries/treeplanting/23042022/800a9393.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9393 = {
	xs: {src: gallery_800a9393_xs_src, id: 'gallery_800a9393_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9393_xl_src, id: 'gallery_800a9393_xl', width: '1920', height: '1280'},
};
import gallery_800a9361_xs_src from 'images/galleries/treeplanting/23042022/800a9361.jpg?width=480&height=320&format=webp';
import gallery_800a9361_xl_src from 'images/galleries/treeplanting/23042022/800a9361.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9361 = {
	xs: {src: gallery_800a9361_xs_src, id: 'gallery_800a9361_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9361_xl_src, id: 'gallery_800a9361_xl', width: '1920', height: '1280'},
};
import gallery_800a9355_xs_src from 'images/galleries/treeplanting/23042022/800a9355.jpg?width=480&height=320&format=webp';
import gallery_800a9355_xl_src from 'images/galleries/treeplanting/23042022/800a9355.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9355 = {
	xs: {src: gallery_800a9355_xs_src, id: 'gallery_800a9355_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9355_xl_src, id: 'gallery_800a9355_xl', width: '1920', height: '1280'},
};
import gallery_800a9325_xs_src from 'images/galleries/treeplanting/23042022/800a9325.jpg?width=480&height=320&format=webp';
import gallery_800a9325_xl_src from 'images/galleries/treeplanting/23042022/800a9325.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9325 = {
	xs: {src: gallery_800a9325_xs_src, id: 'gallery_800a9325_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9325_xl_src, id: 'gallery_800a9325_xl', width: '1920', height: '1280'},
};
import gallery_800a9127_xs_src from 'images/galleries/treeplanting/23042022/800a9127.jpg?width=480&height=320&format=webp';
import gallery_800a9127_xl_src from 'images/galleries/treeplanting/23042022/800a9127.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9127 = {
	xs: {src: gallery_800a9127_xs_src, id: 'gallery_800a9127_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9127_xl_src, id: 'gallery_800a9127_xl', width: '1920', height: '1280'},
};
import gallery_800a9273_xs_src from 'images/galleries/treeplanting/23042022/800a9273.jpg?width=480&height=320&format=webp';
import gallery_800a9273_xl_src from 'images/galleries/treeplanting/23042022/800a9273.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9273 = {
	xs: {src: gallery_800a9273_xs_src, id: 'gallery_800a9273_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9273_xl_src, id: 'gallery_800a9273_xl', width: '1920', height: '1280'},
};
import gallery_800a9255_xs_src from 'images/galleries/treeplanting/23042022/800a9255.jpg?width=480&height=320&format=webp';
import gallery_800a9255_xl_src from 'images/galleries/treeplanting/23042022/800a9255.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9255 = {
	xs: {src: gallery_800a9255_xs_src, id: 'gallery_800a9255_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9255_xl_src, id: 'gallery_800a9255_xl', width: '1920', height: '1280'},
};
import gallery_800a9231_xs_src from 'images/galleries/treeplanting/23042022/800a9231.jpg?width=480&height=320&format=webp';
import gallery_800a9231_xl_src from 'images/galleries/treeplanting/23042022/800a9231.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9231 = {
	xs: {src: gallery_800a9231_xs_src, id: 'gallery_800a9231_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9231_xl_src, id: 'gallery_800a9231_xl', width: '1920', height: '1280'},
};
import gallery_800a9217_xs_src from 'images/galleries/treeplanting/23042022/800a9217.jpg?width=480&height=320&format=webp';
import gallery_800a9217_xl_src from 'images/galleries/treeplanting/23042022/800a9217.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9217 = {
	xs: {src: gallery_800a9217_xs_src, id: 'gallery_800a9217_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9217_xl_src, id: 'gallery_800a9217_xl', width: '1920', height: '1280'},
};
import gallery_800a9190_xs_src from 'images/galleries/treeplanting/23042022/800a9190.jpg?width=480&height=320&format=webp';
import gallery_800a9190_xl_src from 'images/galleries/treeplanting/23042022/800a9190.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9190 = {
	xs: {src: gallery_800a9190_xs_src, id: 'gallery_800a9190_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9190_xl_src, id: 'gallery_800a9190_xl', width: '1920', height: '1280'},
};
import gallery_800a9180_xs_src from 'images/galleries/treeplanting/23042022/800a9180.jpg?width=480&height=320&format=webp';
import gallery_800a9180_xl_src from 'images/galleries/treeplanting/23042022/800a9180.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9180 = {
	xs: {src: gallery_800a9180_xs_src, id: 'gallery_800a9180_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9180_xl_src, id: 'gallery_800a9180_xl', width: '1920', height: '1280'},
};
import gallery_800a9110_xs_src from 'images/galleries/treeplanting/23042022/800a9110.jpg?width=480&height=320&format=webp';
import gallery_800a9110_xl_src from 'images/galleries/treeplanting/23042022/800a9110.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9110 = {
	xs: {src: gallery_800a9110_xs_src, id: 'gallery_800a9110_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9110_xl_src, id: 'gallery_800a9110_xl', width: '1920', height: '1280'},
};
import gallery_800a9095_xs_src from 'images/galleries/treeplanting/23042022/800a9095.jpg?width=480&height=320&format=webp';
import gallery_800a9095_xl_src from 'images/galleries/treeplanting/23042022/800a9095.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9095 = {
	xs: {src: gallery_800a9095_xs_src, id: 'gallery_800a9095_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9095_xl_src, id: 'gallery_800a9095_xl', width: '1920', height: '1280'},
};
import gallery_800a9097_xs_src from 'images/galleries/treeplanting/23042022/800a9097.jpg?width=480&height=320&format=webp';
import gallery_800a9097_xl_src from 'images/galleries/treeplanting/23042022/800a9097.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9097 = {
	xs: {src: gallery_800a9097_xs_src, id: 'gallery_800a9097_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9097_xl_src, id: 'gallery_800a9097_xl', width: '1920', height: '1280'},
};
import gallery_800a9037_xs_src from 'images/galleries/treeplanting/23042022/800a9037.jpg?width=480&height=320&format=webp';
import gallery_800a9037_xl_src from 'images/galleries/treeplanting/23042022/800a9037.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9037 = {
	xs: {src: gallery_800a9037_xs_src, id: 'gallery_800a9037_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9037_xl_src, id: 'gallery_800a9037_xl', width: '1920', height: '1280'},
};
import gallery_800a9031_xs_src from 'images/galleries/treeplanting/23042022/800a9031.jpg?width=480&height=320&format=webp';
import gallery_800a9031_xl_src from 'images/galleries/treeplanting/23042022/800a9031.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9031 = {
	xs: {src: gallery_800a9031_xs_src, id: 'gallery_800a9031_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9031_xl_src, id: 'gallery_800a9031_xl', width: '1920', height: '1280'},
};
import gallery_800a9025_xs_src from 'images/galleries/treeplanting/23042022/800a9025.jpg?width=480&height=320&format=webp';
import gallery_800a9025_xl_src from 'images/galleries/treeplanting/23042022/800a9025.jpg?width=1920&height=1280&format=webp';
export const gallery_800a9025 = {
	xs: {src: gallery_800a9025_xs_src, id: 'gallery_800a9025_xs', width: '480', height: '320'},
	xl: {src: gallery_800a9025_xl_src, id: 'gallery_800a9025_xl', width: '1920', height: '1280'},
};
import gallery_800a8981_xs_src from 'images/galleries/treeplanting/23042022/800a8981.jpg?width=480&height=320&format=webp';
import gallery_800a8981_xl_src from 'images/galleries/treeplanting/23042022/800a8981.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8981 = {
	xs: {src: gallery_800a8981_xs_src, id: 'gallery_800a8981_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8981_xl_src, id: 'gallery_800a8981_xl', width: '1920', height: '1280'},
};
import gallery_800a8995_xs_src from 'images/galleries/treeplanting/23042022/800a8995.jpg?width=480&height=320&format=webp';
import gallery_800a8995_xl_src from 'images/galleries/treeplanting/23042022/800a8995.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8995 = {
	xs: {src: gallery_800a8995_xs_src, id: 'gallery_800a8995_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8995_xl_src, id: 'gallery_800a8995_xl', width: '1920', height: '1280'},
};
import gallery_20221112_101018_xs_src from 'images/galleries/treeplanting/12112022/20221112_101018.jpg?width=480&height=360&format=webp';
import gallery_20221112_101018_xl_src from 'images/galleries/treeplanting/12112022/20221112_101018.jpg?width=1920&height=1440&format=webp';
export const gallery_20221112_101018 = {
	xs: {src: gallery_20221112_101018_xs_src, id: 'gallery_20221112_101018_xs', width: '480', height: '360'},
	xl: {src: gallery_20221112_101018_xl_src, id: 'gallery_20221112_101018_xl', width: '1920', height: '1440'},
};
import gallery_20221112_101958_xs_src from 'images/galleries/treeplanting/12112022/20221112_101958.jpg?width=480&height=360&format=webp';
import gallery_20221112_101958_xl_src from 'images/galleries/treeplanting/12112022/20221112_101958.jpg?width=1920&height=1440&format=webp';
export const gallery_20221112_101958 = {
	xs: {src: gallery_20221112_101958_xs_src, id: 'gallery_20221112_101958_xs', width: '480', height: '360'},
	xl: {src: gallery_20221112_101958_xl_src, id: 'gallery_20221112_101958_xl', width: '1920', height: '1440'},
};
import gallery_20221112_102001_xs_src from 'images/galleries/treeplanting/12112022/20221112_102001.jpg?width=480&height=360&format=webp';
import gallery_20221112_102001_xl_src from 'images/galleries/treeplanting/12112022/20221112_102001.jpg?width=1920&height=1440&format=webp';
export const gallery_20221112_102001 = {
	xs: {src: gallery_20221112_102001_xs_src, id: 'gallery_20221112_102001_xs', width: '480', height: '360'},
	xl: {src: gallery_20221112_102001_xl_src, id: 'gallery_20221112_102001_xl', width: '1920', height: '1440'},
};
import gallery_20221112_102010_xs_src from 'images/galleries/treeplanting/12112022/20221112_102010.jpg?width=480&height=640&format=webp';
import gallery_20221112_102010_xl_src from 'images/galleries/treeplanting/12112022/20221112_102010.jpg?width=1920&height=2560&format=webp';
export const gallery_20221112_102010 = {
	xs: {src: gallery_20221112_102010_xs_src, id: 'gallery_20221112_102010_xs', width: '480', height: '640'},
	xl: {src: gallery_20221112_102010_xl_src, id: 'gallery_20221112_102010_xl', width: '1920', height: '2560'},
};
import gallery_20221112_104445_xs_src from 'images/galleries/treeplanting/12112022/20221112_104445.jpg?width=480&height=360&format=webp';
import gallery_20221112_104445_xl_src from 'images/galleries/treeplanting/12112022/20221112_104445.jpg?width=1920&height=1440&format=webp';
export const gallery_20221112_104445 = {
	xs: {src: gallery_20221112_104445_xs_src, id: 'gallery_20221112_104445_xs', width: '480', height: '360'},
	xl: {src: gallery_20221112_104445_xl_src, id: 'gallery_20221112_104445_xl', width: '1920', height: '1440'},
};
import gallery_20221112_105531_xs_src from 'images/galleries/treeplanting/12112022/20221112_105531.jpg?width=480&height=360&format=webp';
import gallery_20221112_105531_xl_src from 'images/galleries/treeplanting/12112022/20221112_105531.jpg?width=1920&height=1440&format=webp';
export const gallery_20221112_105531 = {
	xs: {src: gallery_20221112_105531_xs_src, id: 'gallery_20221112_105531_xs', width: '480', height: '360'},
	xl: {src: gallery_20221112_105531_xl_src, id: 'gallery_20221112_105531_xl', width: '1920', height: '1440'},
};
import gallery_20221112_105928_xs_src from 'images/galleries/treeplanting/12112022/20221112_105928.jpg?width=480&height=360&format=webp';
import gallery_20221112_105928_xl_src from 'images/galleries/treeplanting/12112022/20221112_105928.jpg?width=1920&height=1440&format=webp';
export const gallery_20221112_105928 = {
	xs: {src: gallery_20221112_105928_xs_src, id: 'gallery_20221112_105928_xs', width: '480', height: '360'},
	xl: {src: gallery_20221112_105928_xl_src, id: 'gallery_20221112_105928_xl', width: '1920', height: '1440'},
};
import gallery_20230422_091311_xs_src from 'images/galleries/treeplanting/22042023/20230422_091311.jpg?width=480&height=480&format=webp';
import gallery_20230422_091311_xl_src from 'images/galleries/treeplanting/22042023/20230422_091311.jpg?width=1920&height=1920&format=webp';
export const gallery_20230422_091311 = {
	xs: {src: gallery_20230422_091311_xs_src, id: 'gallery_20230422_091311_xs', width: '480', height: '480'},
	xl: {src: gallery_20230422_091311_xl_src, id: 'gallery_20230422_091311_xl', width: '1920', height: '1920'},
};
import gallery_20230422_101359_xs_src from 'images/galleries/treeplanting/22042023/20230422_101359.jpg?width=480&height=360&format=webp';
import gallery_20230422_101359_xl_src from 'images/galleries/treeplanting/22042023/20230422_101359.jpg?width=1920&height=1440&format=webp';
export const gallery_20230422_101359 = {
	xs: {src: gallery_20230422_101359_xs_src, id: 'gallery_20230422_101359_xs', width: '480', height: '360'},
	xl: {src: gallery_20230422_101359_xl_src, id: 'gallery_20230422_101359_xl', width: '1920', height: '1440'},
};
import gallery_20230422_102357_xs_src from 'images/galleries/treeplanting/22042023/20230422_102357.jpg?width=480&height=640&format=webp';
import gallery_20230422_102357_xl_src from 'images/galleries/treeplanting/22042023/20230422_102357.jpg?width=1920&height=2560&format=webp';
export const gallery_20230422_102357 = {
	xs: {src: gallery_20230422_102357_xs_src, id: 'gallery_20230422_102357_xs', width: '480', height: '640'},
	xl: {src: gallery_20230422_102357_xl_src, id: 'gallery_20230422_102357_xl', width: '1920', height: '2560'},
};
import gallery_20230422_103622_xs_src from 'images/galleries/treeplanting/22042023/20230422_103622.jpg?width=480&height=640&format=webp';
import gallery_20230422_103622_xl_src from 'images/galleries/treeplanting/22042023/20230422_103622.jpg?width=1920&height=2560&format=webp';
export const gallery_20230422_103622 = {
	xs: {src: gallery_20230422_103622_xs_src, id: 'gallery_20230422_103622_xs', width: '480', height: '640'},
	xl: {src: gallery_20230422_103622_xl_src, id: 'gallery_20230422_103622_xl', width: '1920', height: '2560'},
};
import gallery_20230422_104303_xs_src from 'images/galleries/treeplanting/22042023/20230422_104303.jpg?width=480&height=640&format=webp';
import gallery_20230422_104303_xl_src from 'images/galleries/treeplanting/22042023/20230422_104303.jpg?width=1920&height=2560&format=webp';
export const gallery_20230422_104303 = {
	xs: {src: gallery_20230422_104303_xs_src, id: 'gallery_20230422_104303_xs', width: '480', height: '640'},
	xl: {src: gallery_20230422_104303_xl_src, id: 'gallery_20230422_104303_xl', width: '1920', height: '2560'},
};
import gallery_20230422_104358_xs_src from 'images/galleries/treeplanting/22042023/20230422_104358.jpg?width=480&height=640&format=webp';
import gallery_20230422_104358_xl_src from 'images/galleries/treeplanting/22042023/20230422_104358.jpg?width=1920&height=2560&format=webp';
export const gallery_20230422_104358 = {
	xs: {src: gallery_20230422_104358_xs_src, id: 'gallery_20230422_104358_xs', width: '480', height: '640'},
	xl: {src: gallery_20230422_104358_xl_src, id: 'gallery_20230422_104358_xl', width: '1920', height: '2560'},
};
import gallery_20230422_104456_xs_src from 'images/galleries/treeplanting/22042023/20230422_104456.jpg?width=480&height=360&format=webp';
import gallery_20230422_104456_xl_src from 'images/galleries/treeplanting/22042023/20230422_104456.jpg?width=1920&height=1440&format=webp';
export const gallery_20230422_104456 = {
	xs: {src: gallery_20230422_104456_xs_src, id: 'gallery_20230422_104456_xs', width: '480', height: '360'},
	xl: {src: gallery_20230422_104456_xl_src, id: 'gallery_20230422_104456_xl', width: '1920', height: '1440'},
};
import gallery_20230422_104621_xs_src from 'images/galleries/treeplanting/22042023/20230422_104621.jpg?width=480&height=360&format=webp';
import gallery_20230422_104621_xl_src from 'images/galleries/treeplanting/22042023/20230422_104621.jpg?width=1920&height=1440&format=webp';
export const gallery_20230422_104621 = {
	xs: {src: gallery_20230422_104621_xs_src, id: 'gallery_20230422_104621_xs', width: '480', height: '360'},
	xl: {src: gallery_20230422_104621_xl_src, id: 'gallery_20230422_104621_xl', width: '1920', height: '1440'},
};
import gallery_20230422_104627_xs_src from 'images/galleries/treeplanting/22042023/20230422_104627.jpg?width=480&height=360&format=webp';
import gallery_20230422_104627_xl_src from 'images/galleries/treeplanting/22042023/20230422_104627.jpg?width=1920&height=1440&format=webp';
export const gallery_20230422_104627 = {
	xs: {src: gallery_20230422_104627_xs_src, id: 'gallery_20230422_104627_xs', width: '480', height: '360'},
	xl: {src: gallery_20230422_104627_xl_src, id: 'gallery_20230422_104627_xl', width: '1920', height: '1440'},
};
import gallery_20230422_110407_xs_src from 'images/galleries/treeplanting/22042023/20230422_110407.jpg?width=480&height=640&format=webp';
import gallery_20230422_110407_xl_src from 'images/galleries/treeplanting/22042023/20230422_110407.jpg?width=1920&height=2560&format=webp';
export const gallery_20230422_110407 = {
	xs: {src: gallery_20230422_110407_xs_src, id: 'gallery_20230422_110407_xs', width: '480', height: '640'},
	xl: {src: gallery_20230422_110407_xl_src, id: 'gallery_20230422_110407_xl', width: '1920', height: '2560'},
};
import gallery_20230422_110416_xs_src from 'images/galleries/treeplanting/22042023/20230422_110416.jpg?width=480&height=360&format=webp';
import gallery_20230422_110416_xl_src from 'images/galleries/treeplanting/22042023/20230422_110416.jpg?width=1920&height=1440&format=webp';
export const gallery_20230422_110416 = {
	xs: {src: gallery_20230422_110416_xs_src, id: 'gallery_20230422_110416_xs', width: '480', height: '360'},
	xl: {src: gallery_20230422_110416_xl_src, id: 'gallery_20230422_110416_xl', width: '1920', height: '1440'},
};
import gallery_20230422_111725_xs_src from 'images/galleries/treeplanting/22042023/20230422_111725.jpg?width=480&height=640&format=webp';
import gallery_20230422_111725_xl_src from 'images/galleries/treeplanting/22042023/20230422_111725.jpg?width=1920&height=2560&format=webp';
export const gallery_20230422_111725 = {
	xs: {src: gallery_20230422_111725_xs_src, id: 'gallery_20230422_111725_xs', width: '480', height: '640'},
	xl: {src: gallery_20230422_111725_xl_src, id: 'gallery_20230422_111725_xl', width: '1920', height: '2560'},
};
import gallery_20240326_145011_xs_src from 'images/galleries/treeplanting/13042024/20240326_145011.jpg?width=480&height=480&format=webp';
import gallery_20240326_145011_xl_src from 'images/galleries/treeplanting/13042024/20240326_145011.jpg?width=1920&height=1920&format=webp';
export const gallery_20240326_145011 = {
	xs: {src: gallery_20240326_145011_xs_src, id: 'gallery_20240326_145011_xs', width: '480', height: '480'},
	xl: {src: gallery_20240326_145011_xl_src, id: 'gallery_20240326_145011_xl', width: '1920', height: '1920'},
};
import gallery_20240326_145051_xs_src from 'images/galleries/treeplanting/13042024/20240326_145051.jpg?width=480&height=480&format=webp';
import gallery_20240326_145051_xl_src from 'images/galleries/treeplanting/13042024/20240326_145051.jpg?width=1920&height=1920&format=webp';
export const gallery_20240326_145051 = {
	xs: {src: gallery_20240326_145051_xs_src, id: 'gallery_20240326_145051_xs', width: '480', height: '480'},
	xl: {src: gallery_20240326_145051_xl_src, id: 'gallery_20240326_145051_xl', width: '1920', height: '1920'},
};
import gallery_20240326_145153_xs_src from 'images/galleries/treeplanting/13042024/20240326_145153.jpg?width=480&height=360&format=webp';
import gallery_20240326_145153_xl_src from 'images/galleries/treeplanting/13042024/20240326_145153.jpg?width=1920&height=1440&format=webp';
export const gallery_20240326_145153 = {
	xs: {src: gallery_20240326_145153_xs_src, id: 'gallery_20240326_145153_xs', width: '480', height: '360'},
	xl: {src: gallery_20240326_145153_xl_src, id: 'gallery_20240326_145153_xl', width: '1920', height: '1440'},
};
import gallery_20240326_145256_xs_src from 'images/galleries/treeplanting/13042024/20240326_145256.jpg?width=480&height=640&format=webp';
import gallery_20240326_145256_xl_src from 'images/galleries/treeplanting/13042024/20240326_145256.jpg?width=1920&height=2560&format=webp';
export const gallery_20240326_145256 = {
	xs: {src: gallery_20240326_145256_xs_src, id: 'gallery_20240326_145256_xs', width: '480', height: '640'},
	xl: {src: gallery_20240326_145256_xl_src, id: 'gallery_20240326_145256_xl', width: '1920', height: '2560'},
};
import gallery_20240326_145259_xs_src from 'images/galleries/treeplanting/13042024/20240326_145259.jpg?width=480&height=360&format=webp';
import gallery_20240326_145259_xl_src from 'images/galleries/treeplanting/13042024/20240326_145259.jpg?width=1920&height=1440&format=webp';
export const gallery_20240326_145259 = {
	xs: {src: gallery_20240326_145259_xs_src, id: 'gallery_20240326_145259_xs', width: '480', height: '360'},
	xl: {src: gallery_20240326_145259_xl_src, id: 'gallery_20240326_145259_xl', width: '1920', height: '1440'},
};
import gallery_20240326_145619_xs_src from 'images/galleries/treeplanting/13042024/20240326_145619.jpg?width=480&height=360&format=webp';
import gallery_20240326_145619_xl_src from 'images/galleries/treeplanting/13042024/20240326_145619.jpg?width=1920&height=1440&format=webp';
export const gallery_20240326_145619 = {
	xs: {src: gallery_20240326_145619_xs_src, id: 'gallery_20240326_145619_xs', width: '480', height: '360'},
	xl: {src: gallery_20240326_145619_xl_src, id: 'gallery_20240326_145619_xl', width: '1920', height: '1440'},
};
import gallery_20240326_150244_xs_src from 'images/galleries/treeplanting/13042024/20240326_150244.jpg?width=480&height=640&format=webp';
import gallery_20240326_150244_xl_src from 'images/galleries/treeplanting/13042024/20240326_150244.jpg?width=1920&height=2560&format=webp';
export const gallery_20240326_150244 = {
	xs: {src: gallery_20240326_150244_xs_src, id: 'gallery_20240326_150244_xs', width: '480', height: '640'},
	xl: {src: gallery_20240326_150244_xl_src, id: 'gallery_20240326_150244_xl', width: '1920', height: '2560'},
};
import gallery_20240413_090952_xs_src from 'images/galleries/treeplanting/13042024/20240413_090952.jpg?width=480&height=640&format=webp';
import gallery_20240413_090952_xl_src from 'images/galleries/treeplanting/13042024/20240413_090952.jpg?width=1920&height=2560&format=webp';
export const gallery_20240413_090952 = {
	xs: {src: gallery_20240413_090952_xs_src, id: 'gallery_20240413_090952_xs', width: '480', height: '640'},
	xl: {src: gallery_20240413_090952_xl_src, id: 'gallery_20240413_090952_xl', width: '1920', height: '2560'},
};
import gallery_20240413_094003_xs_src from 'images/galleries/treeplanting/13042024/20240413_094003.jpg?width=480&height=360&format=webp';
import gallery_20240413_094003_xl_src from 'images/galleries/treeplanting/13042024/20240413_094003.jpg?width=1920&height=1440&format=webp';
export const gallery_20240413_094003 = {
	xs: {src: gallery_20240413_094003_xs_src, id: 'gallery_20240413_094003_xs', width: '480', height: '360'},
	xl: {src: gallery_20240413_094003_xl_src, id: 'gallery_20240413_094003_xl', width: '1920', height: '1440'},
};
import gallery_20240413_120232_xs_src from 'images/galleries/treeplanting/13042024/20240413_120232.jpg?width=480&height=360&format=webp';
import gallery_20240413_120232_xl_src from 'images/galleries/treeplanting/13042024/20240413_120232.jpg?width=1920&height=1440&format=webp';
export const gallery_20240413_120232 = {
	xs: {src: gallery_20240413_120232_xs_src, id: 'gallery_20240413_120232_xs', width: '480', height: '360'},
	xl: {src: gallery_20240413_120232_xl_src, id: 'gallery_20240413_120232_xl', width: '1920', height: '1440'},
};
import gallery_img_20240405_wa0001_xs_src from 'images/galleries/treeplanting/13042024/img_20240405_wa0001.jpg?width=480&height=360&format=webp';
import gallery_img_20240405_wa0001_xl_src from 'images/galleries/treeplanting/13042024/img_20240405_wa0001.jpg?width=1920&height=1440&format=webp';
export const gallery_img_20240405_wa0001 = {
	xs: {src: gallery_img_20240405_wa0001_xs_src, id: 'gallery_img_20240405_wa0001_xs', width: '480', height: '360'},
	xl: {src: gallery_img_20240405_wa0001_xl_src, id: 'gallery_img_20240405_wa0001_xl', width: '1920', height: '1440'},
};
import gallery_img_20240415_wa0001_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0001.jpg?width=480&height=360&format=webp';
import gallery_img_20240415_wa0001_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0001.jpg?width=1920&height=1440&format=webp';
export const gallery_img_20240415_wa0001 = {
	xs: {src: gallery_img_20240415_wa0001_xs_src, id: 'gallery_img_20240415_wa0001_xs', width: '480', height: '360'},
	xl: {src: gallery_img_20240415_wa0001_xl_src, id: 'gallery_img_20240415_wa0001_xl', width: '1920', height: '1440'},
};
import gallery_img_20240415_wa0002_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0002.jpg?width=480&height=360&format=webp';
import gallery_img_20240415_wa0002_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0002.jpg?width=1920&height=1440&format=webp';
export const gallery_img_20240415_wa0002 = {
	xs: {src: gallery_img_20240415_wa0002_xs_src, id: 'gallery_img_20240415_wa0002_xs', width: '480', height: '360'},
	xl: {src: gallery_img_20240415_wa0002_xl_src, id: 'gallery_img_20240415_wa0002_xl', width: '1920', height: '1440'},
};
import gallery_img_20240415_wa0003_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0003.jpg?width=480&height=360&format=webp';
import gallery_img_20240415_wa0003_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0003.jpg?width=1920&height=1440&format=webp';
export const gallery_img_20240415_wa0003 = {
	xs: {src: gallery_img_20240415_wa0003_xs_src, id: 'gallery_img_20240415_wa0003_xs', width: '480', height: '360'},
	xl: {src: gallery_img_20240415_wa0003_xl_src, id: 'gallery_img_20240415_wa0003_xl', width: '1920', height: '1440'},
};
import gallery_img_20240415_wa0004_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0004.jpg?width=480&height=640&format=webp';
import gallery_img_20240415_wa0004_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0004.jpg?width=1920&height=2560&format=webp';
export const gallery_img_20240415_wa0004 = {
	xs: {src: gallery_img_20240415_wa0004_xs_src, id: 'gallery_img_20240415_wa0004_xs', width: '480', height: '640'},
	xl: {src: gallery_img_20240415_wa0004_xl_src, id: 'gallery_img_20240415_wa0004_xl', width: '1920', height: '2560'},
};
import gallery_img_20240415_wa0005_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0005.jpg?width=480&height=640&format=webp';
import gallery_img_20240415_wa0005_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0005.jpg?width=1920&height=2560&format=webp';
export const gallery_img_20240415_wa0005 = {
	xs: {src: gallery_img_20240415_wa0005_xs_src, id: 'gallery_img_20240415_wa0005_xs', width: '480', height: '640'},
	xl: {src: gallery_img_20240415_wa0005_xl_src, id: 'gallery_img_20240415_wa0005_xl', width: '1920', height: '2560'},
};
import gallery_img_20240415_wa0006_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0006.jpg?width=480&height=640&format=webp';
import gallery_img_20240415_wa0006_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0006.jpg?width=1920&height=2560&format=webp';
export const gallery_img_20240415_wa0006 = {
	xs: {src: gallery_img_20240415_wa0006_xs_src, id: 'gallery_img_20240415_wa0006_xs', width: '480', height: '640'},
	xl: {src: gallery_img_20240415_wa0006_xl_src, id: 'gallery_img_20240415_wa0006_xl', width: '1920', height: '2560'},
};
import gallery_img_20240415_wa0007_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0007.jpg?width=480&height=360&format=webp';
import gallery_img_20240415_wa0007_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0007.jpg?width=1920&height=1440&format=webp';
export const gallery_img_20240415_wa0007 = {
	xs: {src: gallery_img_20240415_wa0007_xs_src, id: 'gallery_img_20240415_wa0007_xs', width: '480', height: '360'},
	xl: {src: gallery_img_20240415_wa0007_xl_src, id: 'gallery_img_20240415_wa0007_xl', width: '1920', height: '1440'},
};
import gallery_img_20240415_wa0008_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0008.jpg?width=480&height=640&format=webp';
import gallery_img_20240415_wa0008_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0008.jpg?width=1920&height=2560&format=webp';
export const gallery_img_20240415_wa0008 = {
	xs: {src: gallery_img_20240415_wa0008_xs_src, id: 'gallery_img_20240415_wa0008_xs', width: '480', height: '640'},
	xl: {src: gallery_img_20240415_wa0008_xl_src, id: 'gallery_img_20240415_wa0008_xl', width: '1920', height: '2560'},
};
import gallery_img_20240415_wa0009_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0009.jpg?width=480&height=360&format=webp';
import gallery_img_20240415_wa0009_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0009.jpg?width=1920&height=1440&format=webp';
export const gallery_img_20240415_wa0009 = {
	xs: {src: gallery_img_20240415_wa0009_xs_src, id: 'gallery_img_20240415_wa0009_xs', width: '480', height: '360'},
	xl: {src: gallery_img_20240415_wa0009_xl_src, id: 'gallery_img_20240415_wa0009_xl', width: '1920', height: '1440'},
};
import gallery_img_20240415_wa0010_xs_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0010.jpg?width=480&height=640&format=webp';
import gallery_img_20240415_wa0010_xl_src from 'images/galleries/treeplanting/13042024/img_20240415_wa0010.jpg?width=1920&height=2560&format=webp';
export const gallery_img_20240415_wa0010 = {
	xs: {src: gallery_img_20240415_wa0010_xs_src, id: 'gallery_img_20240415_wa0010_xs', width: '480', height: '640'},
	xl: {src: gallery_img_20240415_wa0010_xl_src, id: 'gallery_img_20240415_wa0010_xl', width: '1920', height: '2560'},
};
import gallery_20240611_191540_xs_src from 'images/galleries/grillAcademy/20240611_191540.jpg?width=480&height=360&format=webp';
import gallery_20240611_191540_xl_src from 'images/galleries/grillAcademy/20240611_191540.jpg?width=1920&height=1440&format=webp';
export const gallery_20240611_191540 = {
	xs: {src: gallery_20240611_191540_xs_src, id: 'gallery_20240611_191540_xs', width: '480', height: '360'},
	xl: {src: gallery_20240611_191540_xl_src, id: 'gallery_20240611_191540_xl', width: '1920', height: '1440'},
};
import gallery_20240611_192030_xs_src from 'images/galleries/grillAcademy/20240611_192030.jpg?width=480&height=360&format=webp';
import gallery_20240611_192030_xl_src from 'images/galleries/grillAcademy/20240611_192030.jpg?width=1920&height=1440&format=webp';
export const gallery_20240611_192030 = {
	xs: {src: gallery_20240611_192030_xs_src, id: 'gallery_20240611_192030_xs', width: '480', height: '360'},
	xl: {src: gallery_20240611_192030_xl_src, id: 'gallery_20240611_192030_xl', width: '1920', height: '1440'},
};
import gallery_20240611_192332_xs_src from 'images/galleries/grillAcademy/20240611_192332.jpg?width=480&height=360&format=webp';
import gallery_20240611_192332_xl_src from 'images/galleries/grillAcademy/20240611_192332.jpg?width=1920&height=1440&format=webp';
export const gallery_20240611_192332 = {
	xs: {src: gallery_20240611_192332_xs_src, id: 'gallery_20240611_192332_xs', width: '480', height: '360'},
	xl: {src: gallery_20240611_192332_xl_src, id: 'gallery_20240611_192332_xl', width: '1920', height: '1440'},
};
import gallery_20240611_192346_xs_src from 'images/galleries/grillAcademy/20240611_192346.jpg?width=480&height=640&format=webp';
import gallery_20240611_192346_xl_src from 'images/galleries/grillAcademy/20240611_192346.jpg?width=1920&height=2560&format=webp';
export const gallery_20240611_192346 = {
	xs: {src: gallery_20240611_192346_xs_src, id: 'gallery_20240611_192346_xs', width: '480', height: '640'},
	xl: {src: gallery_20240611_192346_xl_src, id: 'gallery_20240611_192346_xl', width: '1920', height: '2560'},
};
import gallery_20240611_192359_xs_src from 'images/galleries/grillAcademy/20240611_192359.jpg?width=480&height=360&format=webp';
import gallery_20240611_192359_xl_src from 'images/galleries/grillAcademy/20240611_192359.jpg?width=1920&height=1440&format=webp';
export const gallery_20240611_192359 = {
	xs: {src: gallery_20240611_192359_xs_src, id: 'gallery_20240611_192359_xs', width: '480', height: '360'},
	xl: {src: gallery_20240611_192359_xl_src, id: 'gallery_20240611_192359_xl', width: '1920', height: '1440'},
};
import gallery_20240611_193618_xs_src from 'images/galleries/grillAcademy/20240611_193618.jpg?width=480&height=640&format=webp';
import gallery_20240611_193618_xl_src from 'images/galleries/grillAcademy/20240611_193618.jpg?width=1920&height=2560&format=webp';
export const gallery_20240611_193618 = {
	xs: {src: gallery_20240611_193618_xs_src, id: 'gallery_20240611_193618_xs', width: '480', height: '640'},
	xl: {src: gallery_20240611_193618_xl_src, id: 'gallery_20240611_193618_xl', width: '1920', height: '2560'},
};
import gallery_20240611_194453_xs_src from 'images/galleries/grillAcademy/20240611_194453.jpg?width=480&height=360&format=webp';
import gallery_20240611_194453_xl_src from 'images/galleries/grillAcademy/20240611_194453.jpg?width=1920&height=1440&format=webp';
export const gallery_20240611_194453 = {
	xs: {src: gallery_20240611_194453_xs_src, id: 'gallery_20240611_194453_xs', width: '480', height: '360'},
	xl: {src: gallery_20240611_194453_xl_src, id: 'gallery_20240611_194453_xl', width: '1920', height: '1440'},
};
import gallery_20240611_212406_xs_src from 'images/galleries/grillAcademy/20240611_212406.jpg?width=480&height=640&format=webp';
import gallery_20240611_212406_xl_src from 'images/galleries/grillAcademy/20240611_212406.jpg?width=1920&height=2560&format=webp';
export const gallery_20240611_212406 = {
	xs: {src: gallery_20240611_212406_xs_src, id: 'gallery_20240611_212406_xs', width: '480', height: '640'},
	xl: {src: gallery_20240611_212406_xl_src, id: 'gallery_20240611_212406_xl', width: '1920', height: '2560'},
};
import gallery_800a5884_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a5884.jpg?width=480&height=358&format=webp';
import gallery_800a5884_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a5884.jpg?width=1920&height=1430&format=webp';
export const gallery_800a5884 = {
	xs: {src: gallery_800a5884_xs_src, id: 'gallery_800a5884_xs', width: '480', height: '358'},
	xl: {src: gallery_800a5884_xl_src, id: 'gallery_800a5884_xl', width: '1920', height: '1430'},
};
import gallery_800a5907_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/800a5907.jpg?width=480&height=320&format=webp';
import gallery_800a5907_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/800a5907.jpg?width=1920&height=1280&format=webp';
export const gallery_800a5907 = {
	xs: {src: gallery_800a5907_xs_src, id: 'gallery_800a5907_xs', width: '480', height: '320'},
	xl: {src: gallery_800a5907_xl_src, id: 'gallery_800a5907_xl', width: '1920', height: '1280'},
};
import gallery_800a5934_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a5934.jpg?width=480&height=320&format=webp';
import gallery_800a5934_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a5934.jpg?width=1920&height=1280&format=webp';
export const gallery_800a5934 = {
	xs: {src: gallery_800a5934_xs_src, id: 'gallery_800a5934_xs', width: '480', height: '320'},
	xl: {src: gallery_800a5934_xl_src, id: 'gallery_800a5934_xl', width: '1920', height: '1280'},
};
import gallery_800a6019_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6019.jpg?width=480&height=320&format=webp';
import gallery_800a6019_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6019.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6019 = {
	xs: {src: gallery_800a6019_xs_src, id: 'gallery_800a6019_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6019_xl_src, id: 'gallery_800a6019_xl', width: '1920', height: '1280'},
};
import gallery_800a6024_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6024.jpg?width=480&height=358&format=webp';
import gallery_800a6024_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6024.jpg?width=1920&height=1430&format=webp';
export const gallery_800a6024 = {
	xs: {src: gallery_800a6024_xs_src, id: 'gallery_800a6024_xs', width: '480', height: '358'},
	xl: {src: gallery_800a6024_xl_src, id: 'gallery_800a6024_xl', width: '1920', height: '1430'},
};
import gallery_800a6035_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6035.jpg?width=480&height=320&format=webp';
import gallery_800a6035_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6035.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6035 = {
	xs: {src: gallery_800a6035_xs_src, id: 'gallery_800a6035_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6035_xl_src, id: 'gallery_800a6035_xl', width: '1920', height: '1280'},
};
import gallery_800a6036_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6036.jpg?width=480&height=320&format=webp';
import gallery_800a6036_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6036.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6036 = {
	xs: {src: gallery_800a6036_xs_src, id: 'gallery_800a6036_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6036_xl_src, id: 'gallery_800a6036_xl', width: '1920', height: '1280'},
};
import gallery_800a6050_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6050.jpg?width=480&height=320&format=webp';
import gallery_800a6050_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6050.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6050 = {
	xs: {src: gallery_800a6050_xs_src, id: 'gallery_800a6050_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6050_xl_src, id: 'gallery_800a6050_xl', width: '1920', height: '1280'},
};
import gallery_800a6061_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6061.jpg?width=480&height=320&format=webp';
import gallery_800a6061_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6061.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6061 = {
	xs: {src: gallery_800a6061_xs_src, id: 'gallery_800a6061_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6061_xl_src, id: 'gallery_800a6061_xl', width: '1920', height: '1280'},
};
import gallery_800a6080_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6080.jpg?width=480&height=320&format=webp';
import gallery_800a6080_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_1/800a6080.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6080 = {
	xs: {src: gallery_800a6080_xs_src, id: 'gallery_800a6080_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6080_xl_src, id: 'gallery_800a6080_xl', width: '1920', height: '1280'},
};
import gallery_5de_3378_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3378.jpg?width=480&height=320&format=webp';
import gallery_5de_3378_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3378.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3378 = {
	xs: {src: gallery_5de_3378_xs_src, id: 'gallery_5de_3378_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3378_xl_src, id: 'gallery_5de_3378_xl', width: '1920', height: '1280'},
};
import gallery_5de_3382_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3382.jpg?width=480&height=320&format=webp';
import gallery_5de_3382_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3382.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3382 = {
	xs: {src: gallery_5de_3382_xs_src, id: 'gallery_5de_3382_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3382_xl_src, id: 'gallery_5de_3382_xl', width: '1920', height: '1280'},
};
import gallery_5de_3394_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3394.jpg?width=480&height=320&format=webp';
import gallery_5de_3394_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3394.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3394 = {
	xs: {src: gallery_5de_3394_xs_src, id: 'gallery_5de_3394_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3394_xl_src, id: 'gallery_5de_3394_xl', width: '1920', height: '1280'},
};
import gallery_5de_3415_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3415.jpg?width=480&height=320&format=webp';
import gallery_5de_3415_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3415.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3415 = {
	xs: {src: gallery_5de_3415_xs_src, id: 'gallery_5de_3415_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3415_xl_src, id: 'gallery_5de_3415_xl', width: '1920', height: '1280'},
};
import gallery_5de_3446_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3446.jpg?width=480&height=320&format=webp';
import gallery_5de_3446_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3446.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3446 = {
	xs: {src: gallery_5de_3446_xs_src, id: 'gallery_5de_3446_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3446_xl_src, id: 'gallery_5de_3446_xl', width: '1920', height: '1280'},
};
import gallery_5de_3452_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3452.jpg?width=480&height=320&format=webp';
import gallery_5de_3452_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3452.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3452 = {
	xs: {src: gallery_5de_3452_xs_src, id: 'gallery_5de_3452_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3452_xl_src, id: 'gallery_5de_3452_xl', width: '1920', height: '1280'},
};
import gallery_5de_3484_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3484.jpg?width=480&height=320&format=webp';
import gallery_5de_3484_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3484.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3484 = {
	xs: {src: gallery_5de_3484_xs_src, id: 'gallery_5de_3484_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3484_xl_src, id: 'gallery_5de_3484_xl', width: '1920', height: '1280'},
};
import gallery_5de_3503_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3503.jpg?width=480&height=320&format=webp';
import gallery_5de_3503_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3503.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3503 = {
	xs: {src: gallery_5de_3503_xs_src, id: 'gallery_5de_3503_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3503_xl_src, id: 'gallery_5de_3503_xl', width: '1920', height: '1280'},
};
import gallery_5de_3561_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3561.jpg?width=480&height=320&format=webp';
import gallery_5de_3561_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3561.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3561 = {
	xs: {src: gallery_5de_3561_xs_src, id: 'gallery_5de_3561_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3561_xl_src, id: 'gallery_5de_3561_xl', width: '1920', height: '1280'},
};
import gallery_5de_3625_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3625.jpg?width=480&height=320&format=webp';
import gallery_5de_3625_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_2/5de_3625.jpg?width=1920&height=1280&format=webp';
export const gallery_5de_3625 = {
	xs: {src: gallery_5de_3625_xs_src, id: 'gallery_5de_3625_xs', width: '480', height: '320'},
	xl: {src: gallery_5de_3625_xl_src, id: 'gallery_5de_3625_xl', width: '1920', height: '1280'},
};
import gallery_800a5818_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/800a5818.jpg?width=480&height=320&format=webp';
import gallery_800a5818_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/800a5818.jpg?width=1920&height=1280&format=webp';
export const gallery_800a5818 = {
	xs: {src: gallery_800a5818_xs_src, id: 'gallery_800a5818_xs', width: '480', height: '320'},
	xl: {src: gallery_800a5818_xl_src, id: 'gallery_800a5818_xl', width: '1920', height: '1280'},
};
import gallery_800a5942_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/800a5942.jpg?width=480&height=320&format=webp';
import gallery_800a5942_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/800a5942.jpg?width=1920&height=1280&format=webp';
export const gallery_800a5942 = {
	xs: {src: gallery_800a5942_xs_src, id: 'gallery_800a5942_xs', width: '480', height: '320'},
	xl: {src: gallery_800a5942_xl_src, id: 'gallery_800a5942_xl', width: '1920', height: '1280'},
};
import gallery_img_8532_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8532.jpg?width=480&height=330&format=webp';
import gallery_img_8532_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8532.jpg?width=1920&height=1320&format=webp';
export const gallery_img_8532 = {
	xs: {src: gallery_img_8532_xs_src, id: 'gallery_img_8532_xs', width: '480', height: '330'},
	xl: {src: gallery_img_8532_xl_src, id: 'gallery_img_8532_xl', width: '1920', height: '1320'},
};
import gallery_img_8544_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8544.jpg?width=480&height=330&format=webp';
import gallery_img_8544_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8544.jpg?width=1920&height=1320&format=webp';
export const gallery_img_8544 = {
	xs: {src: gallery_img_8544_xs_src, id: 'gallery_img_8544_xs', width: '480', height: '330'},
	xl: {src: gallery_img_8544_xl_src, id: 'gallery_img_8544_xl', width: '1920', height: '1320'},
};
import gallery_img_8556_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8556.jpg?width=480&height=330&format=webp';
import gallery_img_8556_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8556.jpg?width=1920&height=1320&format=webp';
export const gallery_img_8556 = {
	xs: {src: gallery_img_8556_xs_src, id: 'gallery_img_8556_xs', width: '480', height: '330'},
	xl: {src: gallery_img_8556_xl_src, id: 'gallery_img_8556_xl', width: '1920', height: '1320'},
};
import gallery_img_8586_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8586.jpg?width=480&height=330&format=webp';
import gallery_img_8586_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_3/img_8586.jpg?width=1920&height=1320&format=webp';
export const gallery_img_8586 = {
	xs: {src: gallery_img_8586_xs_src, id: 'gallery_img_8586_xs', width: '480', height: '330'},
	xl: {src: gallery_img_8586_xl_src, id: 'gallery_img_8586_xl', width: '1920', height: '1320'},
};
import gallery_800a6111_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6111.jpg?width=480&height=320&format=webp';
import gallery_800a6111_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6111.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6111 = {
	xs: {src: gallery_800a6111_xs_src, id: 'gallery_800a6111_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6111_xl_src, id: 'gallery_800a6111_xl', width: '1920', height: '1280'},
};
import gallery_800a6169_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6169.jpg?width=480&height=320&format=webp';
import gallery_800a6169_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6169.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6169 = {
	xs: {src: gallery_800a6169_xs_src, id: 'gallery_800a6169_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6169_xl_src, id: 'gallery_800a6169_xl', width: '1920', height: '1280'},
};
import gallery_800a6185_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6185.jpg?width=480&height=320&format=webp';
import gallery_800a6185_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6185.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6185 = {
	xs: {src: gallery_800a6185_xs_src, id: 'gallery_800a6185_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6185_xl_src, id: 'gallery_800a6185_xl', width: '1920', height: '1280'},
};
import gallery_800a6194_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6194.jpg?width=480&height=320&format=webp';
import gallery_800a6194_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6194.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6194 = {
	xs: {src: gallery_800a6194_xs_src, id: 'gallery_800a6194_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6194_xl_src, id: 'gallery_800a6194_xl', width: '1920', height: '1280'},
};
import gallery_800a6250_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6250.jpg?width=480&height=320&format=webp';
import gallery_800a6250_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6250.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6250 = {
	xs: {src: gallery_800a6250_xs_src, id: 'gallery_800a6250_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6250_xl_src, id: 'gallery_800a6250_xl', width: '1920', height: '1280'},
};
import gallery_800a6251_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6251.jpg?width=480&height=320&format=webp';
import gallery_800a6251_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6251.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6251 = {
	xs: {src: gallery_800a6251_xs_src, id: 'gallery_800a6251_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6251_xl_src, id: 'gallery_800a6251_xl', width: '1920', height: '1280'},
};
import gallery_800a6272_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6272.jpg?width=480&height=320&format=webp';
import gallery_800a6272_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6272.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6272 = {
	xs: {src: gallery_800a6272_xs_src, id: 'gallery_800a6272_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6272_xl_src, id: 'gallery_800a6272_xl', width: '1920', height: '1280'},
};
import gallery_800a6302_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6302.jpg?width=480&height=320&format=webp';
import gallery_800a6302_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6302.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6302 = {
	xs: {src: gallery_800a6302_xs_src, id: 'gallery_800a6302_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6302_xl_src, id: 'gallery_800a6302_xl', width: '1920', height: '1280'},
};
import gallery_800a6359_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6359.jpg?width=480&height=320&format=webp';
import gallery_800a6359_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/800a6359.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6359 = {
	xs: {src: gallery_800a6359_xs_src, id: 'gallery_800a6359_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6359_xl_src, id: 'gallery_800a6359_xl', width: '1920', height: '1280'},
};
import gallery_img_7924_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/img_7924.jpg?width=480&height=330&format=webp';
import gallery_img_7924_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_4/img_7924.jpg?width=1920&height=1320&format=webp';
export const gallery_img_7924 = {
	xs: {src: gallery_img_7924_xs_src, id: 'gallery_img_7924_xs', width: '480', height: '330'},
	xl: {src: gallery_img_7924_xl_src, id: 'gallery_img_7924_xl', width: '1920', height: '1320'},
};
import gallery_800a6112_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6112.jpg?width=480&height=320&format=webp';
import gallery_800a6112_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6112.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6112 = {
	xs: {src: gallery_800a6112_xs_src, id: 'gallery_800a6112_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6112_xl_src, id: 'gallery_800a6112_xl', width: '1920', height: '1280'},
};
import gallery_800a6173_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6173.jpg?width=480&height=320&format=webp';
import gallery_800a6173_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6173.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6173 = {
	xs: {src: gallery_800a6173_xs_src, id: 'gallery_800a6173_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6173_xl_src, id: 'gallery_800a6173_xl', width: '1920', height: '1280'},
};
import gallery_800a6191_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6191.jpg?width=480&height=320&format=webp';
import gallery_800a6191_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6191.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6191 = {
	xs: {src: gallery_800a6191_xs_src, id: 'gallery_800a6191_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6191_xl_src, id: 'gallery_800a6191_xl', width: '1920', height: '1280'},
};
import gallery_800a6240_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6240.jpg?width=480&height=320&format=webp';
import gallery_800a6240_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6240.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6240 = {
	xs: {src: gallery_800a6240_xs_src, id: 'gallery_800a6240_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6240_xl_src, id: 'gallery_800a6240_xl', width: '1920', height: '1280'},
};
import gallery_800a6287_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6287.jpg?width=480&height=320&format=webp';
import gallery_800a6287_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6287.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6287 = {
	xs: {src: gallery_800a6287_xs_src, id: 'gallery_800a6287_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6287_xl_src, id: 'gallery_800a6287_xl', width: '1920', height: '1280'},
};
import gallery_800a6310_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6310.jpg?width=480&height=320&format=webp';
import gallery_800a6310_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6310.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6310 = {
	xs: {src: gallery_800a6310_xs_src, id: 'gallery_800a6310_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6310_xl_src, id: 'gallery_800a6310_xl', width: '1920', height: '1280'},
};
import gallery_800a6338_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6338.jpg?width=480&height=320&format=webp';
import gallery_800a6338_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/800a6338.jpg?width=1920&height=1280&format=webp';
export const gallery_800a6338 = {
	xs: {src: gallery_800a6338_xs_src, id: 'gallery_800a6338_xs', width: '480', height: '320'},
	xl: {src: gallery_800a6338_xl_src, id: 'gallery_800a6338_xl', width: '1920', height: '1280'},
};
import gallery_img_8379_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/img_8379.jpg?width=480&height=450&format=webp';
import gallery_img_8379_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/img_8379.jpg?width=1920&height=1800&format=webp';
export const gallery_img_8379 = {
	xs: {src: gallery_img_8379_xs_src, id: 'gallery_img_8379_xs', width: '480', height: '450'},
	xl: {src: gallery_img_8379_xl_src, id: 'gallery_img_8379_xl', width: '1920', height: '1800'},
};
import gallery_img_8397_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/img_8397.jpg?width=480&height=450&format=webp';
import gallery_img_8397_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_5/img_8397.jpg?width=1920&height=1800&format=webp';
export const gallery_img_8397 = {
	xs: {src: gallery_img_8397_xs_src, id: 'gallery_img_8397_xs', width: '480', height: '450'},
	xl: {src: gallery_img_8397_xl_src, id: 'gallery_img_8397_xl', width: '1920', height: '1800'},
};
import gallery_800a5810_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a5810.jpg?width=480&height=320&format=webp';
import gallery_800a5810_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a5810.jpg?width=1920&height=1280&format=webp';
export const gallery_800a5810 = {
	xs: {src: gallery_800a5810_xs_src, id: 'gallery_800a5810_xs', width: '480', height: '320'},
	xl: {src: gallery_800a5810_xl_src, id: 'gallery_800a5810_xl', width: '1920', height: '1280'},
};
import gallery_800a5821_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a5821.jpg?width=480&height=320&format=webp';
import gallery_800a5821_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a5821.jpg?width=1920&height=1280&format=webp';
export const gallery_800a5821 = {
	xs: {src: gallery_800a5821_xs_src, id: 'gallery_800a5821_xs', width: '480', height: '320'},
	xl: {src: gallery_800a5821_xl_src, id: 'gallery_800a5821_xl', width: '1920', height: '1280'},
};
import gallery_800a5861_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a5861.jpg?width=480&height=320&format=webp';
import gallery_800a5861_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a5861.jpg?width=1920&height=1280&format=webp';
export const gallery_800a5861 = {
	xs: {src: gallery_800a5861_xs_src, id: 'gallery_800a5861_xs', width: '480', height: '320'},
	xl: {src: gallery_800a5861_xl_src, id: 'gallery_800a5861_xl', width: '1920', height: '1280'},
};
import gallery_800a8373_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8373.jpg?width=480&height=320&format=webp';
import gallery_800a8373_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8373.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8373 = {
	xs: {src: gallery_800a8373_xs_src, id: 'gallery_800a8373_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8373_xl_src, id: 'gallery_800a8373_xl', width: '1920', height: '1280'},
};
import gallery_800a8385_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8385.jpg?width=480&height=320&format=webp';
import gallery_800a8385_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8385.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8385 = {
	xs: {src: gallery_800a8385_xs_src, id: 'gallery_800a8385_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8385_xl_src, id: 'gallery_800a8385_xl', width: '1920', height: '1280'},
};
import gallery_800a8415_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8415.jpg?width=480&height=320&format=webp';
import gallery_800a8415_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8415.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8415 = {
	xs: {src: gallery_800a8415_xs_src, id: 'gallery_800a8415_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8415_xl_src, id: 'gallery_800a8415_xl', width: '1920', height: '1280'},
};
import gallery_800a8557_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8557.jpg?width=480&height=320&format=webp';
import gallery_800a8557_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8557.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8557 = {
	xs: {src: gallery_800a8557_xs_src, id: 'gallery_800a8557_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8557_xl_src, id: 'gallery_800a8557_xl', width: '1920', height: '1280'},
};
import gallery_800a8602_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8602.jpg?width=480&height=320&format=webp';
import gallery_800a8602_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8602.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8602 = {
	xs: {src: gallery_800a8602_xs_src, id: 'gallery_800a8602_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8602_xl_src, id: 'gallery_800a8602_xl', width: '1920', height: '1280'},
};
import gallery_800a8623_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8623.jpg?width=480&height=320&format=webp';
import gallery_800a8623_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8623.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8623 = {
	xs: {src: gallery_800a8623_xs_src, id: 'gallery_800a8623_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8623_xl_src, id: 'gallery_800a8623_xl', width: '1920', height: '1280'},
};
import gallery_800a8681_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8681.jpg?width=480&height=320&format=webp';
import gallery_800a8681_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_6/800a8681.jpg?width=1920&height=1280&format=webp';
export const gallery_800a8681 = {
	xs: {src: gallery_800a8681_xs_src, id: 'gallery_800a8681_xs', width: '480', height: '320'},
	xl: {src: gallery_800a8681_xl_src, id: 'gallery_800a8681_xl', width: '1920', height: '1280'},
};
import gallery_img_9676_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9676.jpg?width=480&height=330&format=webp';
import gallery_img_9676_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9676.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9676 = {
	xs: {src: gallery_img_9676_xs_src, id: 'gallery_img_9676_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9676_xl_src, id: 'gallery_img_9676_xl', width: '1920', height: '1320'},
};
import gallery_img_9694_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9694.jpg?width=480&height=330&format=webp';
import gallery_img_9694_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9694.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9694 = {
	xs: {src: gallery_img_9694_xs_src, id: 'gallery_img_9694_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9694_xl_src, id: 'gallery_img_9694_xl', width: '1920', height: '1320'},
};
import gallery_img_9655_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9655.jpg?width=480&height=698&format=webp';
import gallery_img_9655_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9655.jpg?width=1920&height=2793&format=webp';
export const gallery_img_9655 = {
	xs: {src: gallery_img_9655_xs_src, id: 'gallery_img_9655_xs', width: '480', height: '698'},
	xl: {src: gallery_img_9655_xl_src, id: 'gallery_img_9655_xl', width: '1920', height: '2793'},
};
import gallery_img_9715_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9715.jpg?width=480&height=330&format=webp';
import gallery_img_9715_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9715.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9715 = {
	xs: {src: gallery_img_9715_xs_src, id: 'gallery_img_9715_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9715_xl_src, id: 'gallery_img_9715_xl', width: '1920', height: '1320'},
};
import gallery_img_9787_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9787.jpg?width=480&height=330&format=webp';
import gallery_img_9787_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_7/img_9787.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9787 = {
	xs: {src: gallery_img_9787_xs_src, id: 'gallery_img_9787_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9787_xl_src, id: 'gallery_img_9787_xl', width: '1920', height: '1320'},
};
import gallery_img_9736_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9736.jpg?width=480&height=330&format=webp';
import gallery_img_9736_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9736.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9736 = {
	xs: {src: gallery_img_9736_xs_src, id: 'gallery_img_9736_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9736_xl_src, id: 'gallery_img_9736_xl', width: '1920', height: '1320'},
};
import gallery_img_9739_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9739.jpg?width=480&height=330&format=webp';
import gallery_img_9739_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9739.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9739 = {
	xs: {src: gallery_img_9739_xs_src, id: 'gallery_img_9739_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9739_xl_src, id: 'gallery_img_9739_xl', width: '1920', height: '1320'},
};
import gallery_img_9733_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9733.jpg?width=480&height=330&format=webp';
import gallery_img_9733_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9733.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9733 = {
	xs: {src: gallery_img_9733_xs_src, id: 'gallery_img_9733_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9733_xl_src, id: 'gallery_img_9733_xl', width: '1920', height: '1320'},
};
import gallery_img_9775_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9775.jpg?width=480&height=330&format=webp';
import gallery_img_9775_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9775.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9775 = {
	xs: {src: gallery_img_9775_xs_src, id: 'gallery_img_9775_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9775_xl_src, id: 'gallery_img_9775_xl', width: '1920', height: '1320'},
};
import gallery_img_9730_xs_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9730.jpg?width=480&height=330&format=webp';
import gallery_img_9730_xl_src from 'images/galleries/exhibitionRetail/exhibition_retail_8/img_9730.jpg?width=1920&height=1320&format=webp';
export const gallery_img_9730 = {
	xs: {src: gallery_img_9730_xs_src, id: 'gallery_img_9730_xs', width: '480', height: '330'},
	xl: {src: gallery_img_9730_xl_src, id: 'gallery_img_9730_xl', width: '1920', height: '1320'},
};
import gallery_holzbau_1_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_1.jpg?width=480&height=384&format=webp';
import gallery_holzbau_1_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_1.jpg?width=1920&height=1536&format=webp';
export const gallery_holzbau_1 = {
	xs: {src: gallery_holzbau_1_xs_src, id: 'gallery_holzbau_1_xs', width: '480', height: '384'},
	xl: {src: gallery_holzbau_1_xl_src, id: 'gallery_holzbau_1_xl', width: '1920', height: '1536'},
};
import gallery_holzbau_2_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_2.jpg?width=480&height=384&format=webp';
import gallery_holzbau_2_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_2.jpg?width=1920&height=1536&format=webp';
export const gallery_holzbau_2 = {
	xs: {src: gallery_holzbau_2_xs_src, id: 'gallery_holzbau_2_xs', width: '480', height: '384'},
	xl: {src: gallery_holzbau_2_xl_src, id: 'gallery_holzbau_2_xl', width: '1920', height: '1536'},
};
import gallery_holzbau_3_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_3.jpg?width=480&height=384&format=webp';
import gallery_holzbau_3_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_3.jpg?width=1920&height=1536&format=webp';
export const gallery_holzbau_3 = {
	xs: {src: gallery_holzbau_3_xs_src, id: 'gallery_holzbau_3_xs', width: '480', height: '384'},
	xl: {src: gallery_holzbau_3_xl_src, id: 'gallery_holzbau_3_xl', width: '1920', height: '1536'},
};
import gallery_holzbau_4_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_4.jpg?width=480&height=384&format=webp';
import gallery_holzbau_4_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_4.jpg?width=1920&height=1536&format=webp';
export const gallery_holzbau_4 = {
	xs: {src: gallery_holzbau_4_xs_src, id: 'gallery_holzbau_4_xs', width: '480', height: '384'},
	xl: {src: gallery_holzbau_4_xl_src, id: 'gallery_holzbau_4_xl', width: '1920', height: '1536'},
};
import gallery_holzbau_5_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_5.jpg?width=480&height=384&format=webp';
import gallery_holzbau_5_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_1/holzbau_5.jpg?width=1920&height=1536&format=webp';
export const gallery_holzbau_5 = {
	xs: {src: gallery_holzbau_5_xs_src, id: 'gallery_holzbau_5_xs', width: '480', height: '384'},
	xl: {src: gallery_holzbau_5_xl_src, id: 'gallery_holzbau_5_xl', width: '1920', height: '1536'},
};
import gallery_platten_1_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_1.jpg?width=480&height=384&format=webp';
import gallery_platten_1_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_1.jpg?width=1920&height=1536&format=webp';
export const gallery_platten_1 = {
	xs: {src: gallery_platten_1_xs_src, id: 'gallery_platten_1_xs', width: '480', height: '384'},
	xl: {src: gallery_platten_1_xl_src, id: 'gallery_platten_1_xl', width: '1920', height: '1536'},
};
import gallery_platten_2_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_2.jpg?width=480&height=384&format=webp';
import gallery_platten_2_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_2.jpg?width=1920&height=1536&format=webp';
export const gallery_platten_2 = {
	xs: {src: gallery_platten_2_xs_src, id: 'gallery_platten_2_xs', width: '480', height: '384'},
	xl: {src: gallery_platten_2_xl_src, id: 'gallery_platten_2_xl', width: '1920', height: '1536'},
};
import gallery_platten_3_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_3.jpg?width=480&height=384&format=webp';
import gallery_platten_3_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_3.jpg?width=1920&height=1536&format=webp';
export const gallery_platten_3 = {
	xs: {src: gallery_platten_3_xs_src, id: 'gallery_platten_3_xs', width: '480', height: '384'},
	xl: {src: gallery_platten_3_xl_src, id: 'gallery_platten_3_xl', width: '1920', height: '1536'},
};
import gallery_platten_4_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_4.jpg?width=480&height=384&format=webp';
import gallery_platten_4_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_4.jpg?width=1920&height=1536&format=webp';
export const gallery_platten_4 = {
	xs: {src: gallery_platten_4_xs_src, id: 'gallery_platten_4_xs', width: '480', height: '384'},
	xl: {src: gallery_platten_4_xl_src, id: 'gallery_platten_4_xl', width: '1920', height: '1536'},
};
import gallery_platten_5_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_5.jpg?width=480&height=384&format=webp';
import gallery_platten_5_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_2/platten_5.jpg?width=1920&height=1536&format=webp';
export const gallery_platten_5 = {
	xs: {src: gallery_platten_5_xs_src, id: 'gallery_platten_5_xs', width: '480', height: '384'},
	xl: {src: gallery_platten_5_xl_src, id: 'gallery_platten_5_xl', width: '1920', height: '1536'},
};
import gallery_daemmung_1_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_1.jpg?width=480&height=384&format=webp';
import gallery_daemmung_1_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_1.jpg?width=1920&height=1536&format=webp';
export const gallery_daemmung_1 = {
	xs: {src: gallery_daemmung_1_xs_src, id: 'gallery_daemmung_1_xs', width: '480', height: '384'},
	xl: {src: gallery_daemmung_1_xl_src, id: 'gallery_daemmung_1_xl', width: '1920', height: '1536'},
};
import gallery_daemmung_2_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_2.jpg?width=480&height=384&format=webp';
import gallery_daemmung_2_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_2.jpg?width=1920&height=1536&format=webp';
export const gallery_daemmung_2 = {
	xs: {src: gallery_daemmung_2_xs_src, id: 'gallery_daemmung_2_xs', width: '480', height: '384'},
	xl: {src: gallery_daemmung_2_xl_src, id: 'gallery_daemmung_2_xl', width: '1920', height: '1536'},
};
import gallery_daemmung_3_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_3.jpg?width=480&height=384&format=webp';
import gallery_daemmung_3_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_3.jpg?width=1920&height=1536&format=webp';
export const gallery_daemmung_3 = {
	xs: {src: gallery_daemmung_3_xs_src, id: 'gallery_daemmung_3_xs', width: '480', height: '384'},
	xl: {src: gallery_daemmung_3_xl_src, id: 'gallery_daemmung_3_xl', width: '1920', height: '1536'},
};
import gallery_daemmung_4_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_4.jpg?width=480&height=384&format=webp';
import gallery_daemmung_4_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_4.jpg?width=1920&height=1536&format=webp';
export const gallery_daemmung_4 = {
	xs: {src: gallery_daemmung_4_xs_src, id: 'gallery_daemmung_4_xs', width: '480', height: '384'},
	xl: {src: gallery_daemmung_4_xl_src, id: 'gallery_daemmung_4_xl', width: '1920', height: '1536'},
};
import gallery_daemmung_5_xs_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_5.jpg?width=480&height=384&format=webp';
import gallery_daemmung_5_xl_src from 'images/galleries/exhibitionWholesale/exhibition_wholesale_3/daemmung_5.jpg?width=1920&height=1536&format=webp';
export const gallery_daemmung_5 = {
	xs: {src: gallery_daemmung_5_xs_src, id: 'gallery_daemmung_5_xs', width: '480', height: '384'},
	xl: {src: gallery_daemmung_5_xl_src, id: 'gallery_daemmung_5_xl', width: '1920', height: '1536'},
};
import gallery_holzfabrik_ausstellung_scheune_tische_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_scheune_tische.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_ausstellung_scheune_tische_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_scheune_tische.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_ausstellung_scheune_tische = {
	xs: {src: gallery_holzfabrik_ausstellung_scheune_tische_xs_src, id: 'gallery_holzfabrik_ausstellung_scheune_tische_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_ausstellung_scheune_tische_xl_src, id: 'gallery_holzfabrik_ausstellung_scheune_tische_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_ausstellung_tische_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_tische.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_ausstellung_tische_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_tische.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_ausstellung_tische = {
	xs: {src: gallery_holzfabrik_ausstellung_tische_xs_src, id: 'gallery_holzfabrik_ausstellung_tische_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_ausstellung_tische_xl_src, id: 'gallery_holzfabrik_ausstellung_tische_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_untere_ausstellung_tische_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_untere_ausstellung_tische.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_untere_ausstellung_tische_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_untere_ausstellung_tische.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_untere_ausstellung_tische = {
	xs: {src: gallery_holzfabrik_untere_ausstellung_tische_xs_src, id: 'gallery_holzfabrik_untere_ausstellung_tische_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_untere_ausstellung_tische_xl_src, id: 'gallery_holzfabrik_untere_ausstellung_tische_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_ausstellung_scheune_tische_und_stuehle_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_scheune_tische_und_stuehle.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_ausstellung_scheune_tische_und_stuehle_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_scheune_tische_und_stuehle.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_ausstellung_scheune_tische_und_stuehle = {
	xs: {src: gallery_holzfabrik_ausstellung_scheune_tische_und_stuehle_xs_src, id: 'gallery_holzfabrik_ausstellung_scheune_tische_und_stuehle_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_ausstellung_scheune_tische_und_stuehle_xl_src, id: 'gallery_holzfabrik_ausstellung_scheune_tische_und_stuehle_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_ausstellung_scheune_tischplatten_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_scheune_tischplatten.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_ausstellung_scheune_tischplatten_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_ausstellung_scheune_tischplatten.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_ausstellung_scheune_tischplatten = {
	xs: {src: gallery_holzfabrik_ausstellung_scheune_tischplatten_xs_src, id: 'gallery_holzfabrik_ausstellung_scheune_tischplatten_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_ausstellung_scheune_tischplatten_xl_src, id: 'gallery_holzfabrik_ausstellung_scheune_tischplatten_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_hocker_beistelltische_oele_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_hocker_beistelltische_oele.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_hocker_beistelltische_oele_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_hocker_beistelltische_oele.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_hocker_beistelltische_oele = {
	xs: {src: gallery_holzfabrik_hocker_beistelltische_oele_xs_src, id: 'gallery_holzfabrik_hocker_beistelltische_oele_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_hocker_beistelltische_oele_xl_src, id: 'gallery_holzfabrik_hocker_beistelltische_oele_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_outdoor_outdoorbaenke_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_outdoor_outdoorbaenke.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_outdoor_outdoorbaenke_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_outdoor_outdoorbaenke.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_outdoor_outdoorbaenke = {
	xs: {src: gallery_holzfabrik_outdoor_outdoorbaenke_xs_src, id: 'gallery_holzfabrik_outdoor_outdoorbaenke_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_outdoor_outdoorbaenke_xl_src, id: 'gallery_holzfabrik_outdoor_outdoorbaenke_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_stuehle_leimholzprofie_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_stuehle_leimholzprofie.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_stuehle_leimholzprofie_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_stuehle_leimholzprofie.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_stuehle_leimholzprofie = {
	xs: {src: gallery_holzfabrik_stuehle_leimholzprofie_xs_src, id: 'gallery_holzfabrik_stuehle_leimholzprofie_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_stuehle_leimholzprofie_xl_src, id: 'gallery_holzfabrik_stuehle_leimholzprofie_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_upcycling_und_deko_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_upcycling_und_deko.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_upcycling_und_deko_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_upcycling_und_deko.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_upcycling_und_deko = {
	xs: {src: gallery_holzfabrik_upcycling_und_deko_xs_src, id: 'gallery_holzfabrik_upcycling_und_deko_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_upcycling_und_deko_xl_src, id: 'gallery_holzfabrik_upcycling_und_deko_xl', width: '1920', height: '1140'},
};
import gallery_holzfabrik_vintage_alte_moebel_xs_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_vintage_alte_moebel.jpg?width=480&height=285&format=webp';
import gallery_holzfabrik_vintage_alte_moebel_xl_src from 'images/galleries/exhibitionWoodFactory/holzfabrik_vintage_alte_moebel.jpg?width=1920&height=1140&format=webp';
export const gallery_holzfabrik_vintage_alte_moebel = {
	xs: {src: gallery_holzfabrik_vintage_alte_moebel_xs_src, id: 'gallery_holzfabrik_vintage_alte_moebel_xs', width: '480', height: '285'},
	xl: {src: gallery_holzfabrik_vintage_alte_moebel_xl_src, id: 'gallery_holzfabrik_vintage_alte_moebel_xl', width: '1920', height: '1140'},
};
import gallery_Esstisch_HFCT0063_2_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFCT0063_2.jpg?width=480&height=320&format=webp';
import gallery_Esstisch_HFCT0063_2_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFCT0063_2.jpg?width=1920&height=1280&format=webp';
export const gallery_Esstisch_HFCT0063_2 = {
	xs: {src: gallery_Esstisch_HFCT0063_2_xs_src, id: 'gallery_Esstisch_HFCT0063_2_xs', width: '480', height: '320'},
	xl: {src: gallery_Esstisch_HFCT0063_2_xl_src, id: 'gallery_Esstisch_HFCT0063_2_xl', width: '1920', height: '1280'},
};
import gallery_Esstisch_HFCT0063_5_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFCT0063_5.jpg?width=480&height=320&format=webp';
import gallery_Esstisch_HFCT0063_5_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFCT0063_5.jpg?width=1920&height=1280&format=webp';
export const gallery_Esstisch_HFCT0063_5 = {
	xs: {src: gallery_Esstisch_HFCT0063_5_xs_src, id: 'gallery_Esstisch_HFCT0063_5_xs', width: '480', height: '320'},
	xl: {src: gallery_Esstisch_HFCT0063_5_xl_src, id: 'gallery_Esstisch_HFCT0063_5_xl', width: '1920', height: '1280'},
};
import gallery_Esstisch_HFCT0064_10_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFCT0064_10.jpg?width=480&height=320&format=webp';
import gallery_Esstisch_HFCT0064_10_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFCT0064_10.jpg?width=1920&height=1280&format=webp';
export const gallery_Esstisch_HFCT0064_10 = {
	xs: {src: gallery_Esstisch_HFCT0064_10_xs_src, id: 'gallery_Esstisch_HFCT0064_10_xs', width: '480', height: '320'},
	xl: {src: gallery_Esstisch_HFCT0064_10_xl_src, id: 'gallery_Esstisch_HFCT0064_10_xl', width: '1920', height: '1280'},
};
import gallery_Esstisch_HFET0005_5_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFET0005_5.jpg?width=480&height=320&format=webp';
import gallery_Esstisch_HFET0005_5_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFET0005_5.jpg?width=1920&height=1280&format=webp';
export const gallery_Esstisch_HFET0005_5 = {
	xs: {src: gallery_Esstisch_HFET0005_5_xs_src, id: 'gallery_Esstisch_HFET0005_5_xs', width: '480', height: '320'},
	xl: {src: gallery_Esstisch_HFET0005_5_xl_src, id: 'gallery_Esstisch_HFET0005_5_xl', width: '1920', height: '1280'},
};
import gallery_Esstisch_HFET0004_8_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFET0004_8.jpg?width=480&height=320&format=webp';
import gallery_Esstisch_HFET0004_8_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Esstisch_HFET0004_8.jpg?width=1920&height=1280&format=webp';
export const gallery_Esstisch_HFET0004_8 = {
	xs: {src: gallery_Esstisch_HFET0004_8_xs_src, id: 'gallery_Esstisch_HFET0004_8_xs', width: '480', height: '320'},
	xl: {src: gallery_Esstisch_HFET0004_8_xl_src, id: 'gallery_Esstisch_HFET0004_8_xl', width: '1920', height: '1280'},
};
import gallery_Couchtisch_HFCT0072_5_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0072_5.jpg?width=480&height=320&format=webp';
import gallery_Couchtisch_HFCT0072_5_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0072_5.jpg?width=1920&height=1280&format=webp';
export const gallery_Couchtisch_HFCT0072_5 = {
	xs: {src: gallery_Couchtisch_HFCT0072_5_xs_src, id: 'gallery_Couchtisch_HFCT0072_5_xs', width: '480', height: '320'},
	xl: {src: gallery_Couchtisch_HFCT0072_5_xl_src, id: 'gallery_Couchtisch_HFCT0072_5_xl', width: '1920', height: '1280'},
};
import gallery_Couchtisch_HFCT0074_3_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0074_3.jpg?width=480&height=320&format=webp';
import gallery_Couchtisch_HFCT0074_3_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0074_3.jpg?width=1920&height=1280&format=webp';
export const gallery_Couchtisch_HFCT0074_3 = {
	xs: {src: gallery_Couchtisch_HFCT0074_3_xs_src, id: 'gallery_Couchtisch_HFCT0074_3_xs', width: '480', height: '320'},
	xl: {src: gallery_Couchtisch_HFCT0074_3_xl_src, id: 'gallery_Couchtisch_HFCT0074_3_xl', width: '1920', height: '1280'},
};
import gallery_Couchtisch_HFCT0083_1_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0083_1.jpg?width=480&height=320&format=webp';
import gallery_Couchtisch_HFCT0083_1_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0083_1.jpg?width=1920&height=1280&format=webp';
export const gallery_Couchtisch_HFCT0083_1 = {
	xs: {src: gallery_Couchtisch_HFCT0083_1_xs_src, id: 'gallery_Couchtisch_HFCT0083_1_xs', width: '480', height: '320'},
	xl: {src: gallery_Couchtisch_HFCT0083_1_xl_src, id: 'gallery_Couchtisch_HFCT0083_1_xl', width: '1920', height: '1280'},
};
import gallery_Couchtisch_HFCT0081_4_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0081_4.jpg?width=480&height=320&format=webp';
import gallery_Couchtisch_HFCT0081_4_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0081_4.jpg?width=1920&height=1280&format=webp';
export const gallery_Couchtisch_HFCT0081_4 = {
	xs: {src: gallery_Couchtisch_HFCT0081_4_xs_src, id: 'gallery_Couchtisch_HFCT0081_4_xs', width: '480', height: '320'},
	xl: {src: gallery_Couchtisch_HFCT0081_4_xl_src, id: 'gallery_Couchtisch_HFCT0081_4_xl', width: '1920', height: '1280'},
};
import gallery_Couchtisch_HFCT0067_1_xs_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0067_1.jpg?width=480&height=320&format=webp';
import gallery_Couchtisch_HFCT0067_1_xl_src from 'images/galleries/woodFactory/indoor/indoor1/Couchtisch_HFCT0067_1.jpg?width=1920&height=1280&format=webp';
export const gallery_Couchtisch_HFCT0067_1 = {
	xs: {src: gallery_Couchtisch_HFCT0067_1_xs_src, id: 'gallery_Couchtisch_HFCT0067_1_xs', width: '480', height: '320'},
	xl: {src: gallery_Couchtisch_HFCT0067_1_xl_src, id: 'gallery_Couchtisch_HFCT0067_1_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0016_2_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0016_2.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0016_2_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0016_2.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0016_2 = {
	xs: {src: gallery_Untergestell_HFUG0016_2_xs_src, id: 'gallery_Untergestell_HFUG0016_2_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0016_2_xl_src, id: 'gallery_Untergestell_HFUG0016_2_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0016_4_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0016_4.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0016_4_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0016_4.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0016_4 = {
	xs: {src: gallery_Untergestell_HFUG0016_4_xs_src, id: 'gallery_Untergestell_HFUG0016_4_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0016_4_xl_src, id: 'gallery_Untergestell_HFUG0016_4_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0019_5_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0019_5.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0019_5_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0019_5.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0019_5 = {
	xs: {src: gallery_Untergestell_HFUG0019_5_xs_src, id: 'gallery_Untergestell_HFUG0019_5_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0019_5_xl_src, id: 'gallery_Untergestell_HFUG0019_5_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0020_6_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0020_6.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0020_6_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0020_6.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0020_6 = {
	xs: {src: gallery_Untergestell_HFUG0020_6_xs_src, id: 'gallery_Untergestell_HFUG0020_6_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0020_6_xl_src, id: 'gallery_Untergestell_HFUG0020_6_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0021_4_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0021_4.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0021_4_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0021_4.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0021_4 = {
	xs: {src: gallery_Untergestell_HFUG0021_4_xs_src, id: 'gallery_Untergestell_HFUG0021_4_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0021_4_xl_src, id: 'gallery_Untergestell_HFUG0021_4_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0022_2_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0022_2.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0022_2_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0022_2.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0022_2 = {
	xs: {src: gallery_Untergestell_HFUG0022_2_xs_src, id: 'gallery_Untergestell_HFUG0022_2_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0022_2_xl_src, id: 'gallery_Untergestell_HFUG0022_2_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0024_3_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0024_3.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0024_3_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0024_3.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0024_3 = {
	xs: {src: gallery_Untergestell_HFUG0024_3_xs_src, id: 'gallery_Untergestell_HFUG0024_3_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0024_3_xl_src, id: 'gallery_Untergestell_HFUG0024_3_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0026_1_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0026_1.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0026_1_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0026_1.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0026_1 = {
	xs: {src: gallery_Untergestell_HFUG0026_1_xs_src, id: 'gallery_Untergestell_HFUG0026_1_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0026_1_xl_src, id: 'gallery_Untergestell_HFUG0026_1_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0027_1_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0027_1.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0027_1_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0027_1.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0027_1 = {
	xs: {src: gallery_Untergestell_HFUG0027_1_xs_src, id: 'gallery_Untergestell_HFUG0027_1_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0027_1_xl_src, id: 'gallery_Untergestell_HFUG0027_1_xl', width: '1920', height: '1280'},
};
import gallery_Untergestell_HFUG0027_6_xs_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0027_6.jpg?width=480&height=320&format=webp';
import gallery_Untergestell_HFUG0027_6_xl_src from 'images/galleries/woodFactory/indoor/indoor2/Untergestell_HFUG0027_6.jpg?width=1920&height=1280&format=webp';
export const gallery_Untergestell_HFUG0027_6 = {
	xs: {src: gallery_Untergestell_HFUG0027_6_xs_src, id: 'gallery_Untergestell_HFUG0027_6_xs', width: '480', height: '320'},
	xl: {src: gallery_Untergestell_HFUG0027_6_xl_src, id: 'gallery_Untergestell_HFUG0027_6_xl', width: '1920', height: '1280'},
};
import flyer_stock1_1_src from 'images/cards/flyer/Lager_KVH_BSH_062024.jpg?width=360&height=512&format=webp';
export const flyer_stock1_1 = {
	src: flyer_stock1_1_src, id: 'flyer_stock1_1', width: '360', height: '512'
};
import flyer_stock1_2_src from 'images/cards/flyer/Lager_Fassade.jpg?width=360&height=512&format=webp';
export const flyer_stock1_2 = {
	src: flyer_stock1_2_src, id: 'flyer_stock1_2', width: '360', height: '512'
};
import flyer_stock1_3_src from 'images/cards/flyer/Lager_James_Hardie.jpg?width=360&height=512&format=webp';
export const flyer_stock1_3 = {
	src: flyer_stock1_3_src, id: 'flyer_stock1_3', width: '360', height: '512'
};
import flyer_stock1_4_src from 'images/cards/flyer/Lager_ProCompact.jpg?width=360&height=512&format=webp';
export const flyer_stock1_4 = {
	src: flyer_stock1_4_src, id: 'flyer_stock1_4', width: '360', height: '512'
};
import flyer_stock3_1_src from 'images/cards/flyer/Lager_Massivholzplatten.jpg?width=360&height=512&format=webp';
export const flyer_stock3_1 = {
	src: flyer_stock3_1_src, id: 'flyer_stock3_1', width: '360', height: '512'
};
import flyer_stock3_2_src from 'images/cards/flyer/Lager_Arbeitsplatten.jpg?width=360&height=512&format=webp';
export const flyer_stock3_2 = {
	src: flyer_stock3_2_src, id: 'flyer_stock3_2', width: '360', height: '512'
};
import flyer_stock3_3_src from 'images/cards/flyer/STEICO Einblasmaschine.jpg?width=360&height=512&format=webp';
export const flyer_stock3_3 = {
	src: flyer_stock3_3_src, id: 'flyer_stock3_3', width: '360', height: '512'
};
import flyer_stock4_1_src from 'images/cards/flyer/Lager_Boden.jpg?width=360&height=512&format=webp';
export const flyer_stock4_1 = {
	src: flyer_stock4_1_src, id: 'flyer_stock4_1', width: '360', height: '512'
};
import flyer_stock4_2_src from 'images/cards/flyer/Lager_Wand_Decke.jpg?width=360&height=512&format=webp';
export const flyer_stock4_2 = {
	src: flyer_stock4_2_src, id: 'flyer_stock4_2', width: '360', height: '512'
};
import flyer_stock4_3_src from 'images/cards/flyer/Lager_Rocko_Wand.jpg?width=360&height=512&format=webp';
export const flyer_stock4_3 = {
	src: flyer_stock4_3_src, id: 'flyer_stock4_3', width: '360', height: '512'
};
import flyer_stock5_1_src from 'images/cards/flyer/Lager_Terrasse.jpg?width=360&height=512&format=webp';
export const flyer_stock5_1 = {
	src: flyer_stock5_1_src, id: 'flyer_stock5_1', width: '360', height: '512'
};
import flyer_stock6_1_src from 'images/cards/flyer/HW_Dextuera_Weisslack_Tueren.jpg?width=360&height=512&format=webp';
export const flyer_stock6_1 = {
	src: flyer_stock6_1_src, id: 'flyer_stock6_1', width: '360', height: '512'
};
import flyer_stock10_1_src from 'images/cards/flyer/Holzfabrik-Erzgebirge-Info-Flyer.jpg?width=360&height=512&format=webp';
export const flyer_stock10_1 = {
	src: flyer_stock10_1_src, id: 'flyer_stock10_1', width: '360', height: '512'
};
import flyer_stock11_1_src from 'images/cards/flyer/Leimholzprofi_Tischplatten.jpg?width=360&height=512&format=webp';
export const flyer_stock11_1 = {
	src: flyer_stock11_1_src, id: 'flyer_stock11_1', width: '360', height: '512'
};
import flyer_stock11_2_src from 'images/cards/flyer/Leimholzprofi_Untergestelle.jpg?width=360&height=512&format=webp';
export const flyer_stock11_2 = {
	src: flyer_stock11_2_src, id: 'flyer_stock11_2', width: '360', height: '512'
};
import flyer_stock11_3_src from 'images/cards/flyer/Leimholzprofi_Stuehle.jpg?width=360&height=512&format=webp';
export const flyer_stock11_3 = {
	src: flyer_stock11_3_src, id: 'flyer_stock11_3', width: '360', height: '512'
};
